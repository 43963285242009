import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { SummaryStepData } from "../models/summary-step-data";
import { Claim } from "../../init-step/models/claims-and-legal-relationship/claim";
import { Fee } from "../models/fee";
import axios, { AxiosError } from "axios";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SummaryStepService {
  summaryStepData: SummaryStepData;

  private caseUuid = '';

  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
  ) { }

  setCaseUuid(caseUuid: string) {
    this.caseUuid = caseUuid;
  }

  async setSummaryStepData(): Promise<{ redirecting: boolean; }> {
    try {
      const url = `${environment.baseUrl}/api/payee-case/${this.caseUuid}/summary`;
      const { data } = await axios.get(url);
      this.setData(data.summary_form_data);
      return {
        redirecting: false,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 403) {
          if (error.response.data.code === 'init-data-invalid') {
            this.snackbar.open('Néhány adat nincs kitöltve!', 'OK');
            await this.router.navigateByUrl(`case/${this.caseUuid}/data-entry`);
            return {
              redirecting: true,
            };
          } else if ('payee_case_reference_id' in error.response.data) {
            await this.router.navigate(['/user/cases'], {
              queryParams: {
                filterType: 'payeeId',
                filter: error.response.data.payee_case_reference_id,
              },
            });
            return {
              redirecting: true,
            };
          }
        }
      }
      console.error('Error while loading summary step data', error);
      throw error;
    }
  }

  async saveSummaryStepData(): Promise<void> {
    const url = `${environment.baseUrl}/api/payee-case/${this.caseUuid}/summary`;
    const params = {
      is_added_flat_rate_cost_claim: this.summaryStepData.is_added_flat_rate_cost_claim,
      is_added_interest_claim: this.summaryStepData.is_added_interest_claim,
    };
    const response = await axios.patch<{ summary_form_data: SummaryStepData; }>(url, params);
    this.setData(response.data.summary_form_data);
  }

  setData(summaryStepData: SummaryStepData) {
    this.summaryStepData = {
      type: summaryStepData.type,
      payee_id: summaryStepData.payee_id,
      client_name: summaryStepData.client_name,
      debtor_name: summaryStepData.debtor_name,
      claims: this.getClaimsData(summaryStepData),
      displayed_claims_amount: summaryStepData.displayed_claims_amount,
      total_claims_amount: summaryStepData.total_claims_amount,
      start_case_price: summaryStepData.start_case_price,
      fees: this.getFeesData(summaryStepData),
      is_added_flat_rate_cost_claim: summaryStepData.is_added_flat_rate_cost_claim,
      is_added_interest_claim: summaryStepData.is_added_interest_claim,
      is_added_start_case_price_claim: summaryStepData.is_added_start_case_price_claim,
      has_claims_eligible_for_flat_rate_cost: summaryStepData.has_claims_eligible_for_flat_rate_cost,
    };
  }

  getClaimsData(summaryStepData: SummaryStepData): Claim[] {
    const claims = [];

    for (const claim of summaryStepData.claims) {
      claims.push({
        uuid: claim.uuid,
        amount: claim.amount,
        due_date_at: claim.due_date_at
      } as Claim);
    }

    return claims;
  }

  getFeesData(summaryStepData: SummaryStepData): Fee[] {
    const fees = [];

    for (const fee of summaryStepData.fees) {
      fees.push({
        label: fee.label,
        details_text: fee.details_text,
        amount: fee.amount
      } as Fee);
    }

    return fees;
  }
}
