import {Component} from '@angular/core';
import {FormTypeEnum} from "../../../init-step/models/common/form-type-enum";
import {SummaryStepService} from "../../services/summary-step.service";

@Component({
  selector: 'app-summary-optional-claims-box',
  templateUrl: './summary-optional-claims-box.component.html',
  styleUrls: ['./summary-optional-claims-box.component.scss']
})
export class SummaryOptionalClaimsBoxComponent {
  readonly FormTypeEnum = FormTypeEnum;

  constructor(
      public summaryStepService: SummaryStepService
  ) { }

  async onInterestClick(b: boolean) {
    this.summaryStepService.summaryStepData.is_added_interest_claim = b;
    await this.summaryStepService.saveSummaryStepData();
  }

  async onFortyEurosClick(b: boolean) {
    this.summaryStepService.summaryStepData.is_added_flat_rate_cost_claim = b;
    await this.summaryStepService.saveSummaryStepData();
  }
}
