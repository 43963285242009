import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ClientsService } from '../clients/clients.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddMissingTaxidDialogComponent } from 'src/app/dialogs/client/add-missing-taxid-dialog/add-missing-taxid-dialog.component';
import { first } from 'rxjs/operators';

export async function checkClientTaxId(
  clientsService: ClientsService,
  router: Router,
  dialog: MatDialog,
): Promise<boolean | UrlTree> {
  // TODO: fix any
  const client = await clientsService.getSelectedClient() as any;
  if (client.tax_number) {
    return true;
  }
  if (!ClientTaxidGuardService.dialogRef) {
    ClientTaxidGuardService.dialogRef = dialog.open(AddMissingTaxidDialogComponent);
    ClientTaxidGuardService.dialogRef.afterClosed().pipe(first()).subscribe({
      next: () => ClientTaxidGuardService.dialogRef = null,
    });
  }
  return router.navigateByUrl('/user/cases');
}

@Injectable({
  providedIn: 'root'
})
export class ClientTaxidGuardService implements CanActivate {
  static dialogRef?: MatDialogRef<AddMissingTaxidDialogComponent>;

  constructor(
    private clientsService: ClientsService,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const isGuest = ['soft-guest', 'demand-letter-guest', 'hard-one-guest'].includes(route.params.trackingId);
    if (isGuest) {
      return true;
    }

    return checkClientTaxId(
      this.clientsService,
      this.router,
      this.dialog,
    );
  }
}
