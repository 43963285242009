import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { HelpersService } from '../../../../../services/helpers/helpers.service';
import {
  FormDataStepExitDialogComponent
} from '../../../../dialogs/form-data-step-exit-dialog/form-data-step-exit-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public title: string;
  public description: string;
  public dropdownTitle: string = '';
  public dropdownItems: string[] = [];
  public areErrorMessagesShown = false;
  public isActive = false;
  public isEditable = true;
  public showSzamlazzhuIcon = false;
  public valueChanged = false;
  public saveLoading = false;
  public urlName = '';

  onDestroySubject = new Subject<void>();

  public form: AbstractControl;

  constructor(
      public helpersService: HelpersService,
      public dialog: MatDialog,
  ) { }

  setText(title: string, description: string, ) {
    this.title = title;
    this.description = description;
  }

  setIsDirty(isDirty: boolean) {
    if (isDirty) {
      this.form.markAsDirty();
      this.helpersService.markAllChildrenAsDirty(this.form);
    } else {
      this.helpersService.markAllChildrenAsPristine(this.form);
    }
  }

  setData() {
    this.onDestroySubject.next();
    this.onDestroySubject.complete();

    this.buildForm();
    this.setMenuTexts();
    this.valueChanged = false;

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe(() => {
        this.onValueChange();
      });
  }

  onValueChange() {
    this.valueChanged = true;
  }

  buildForm() { }

  isActiveStep(): boolean {
    return this.isActive;
  }

  openDialog(): MatDialogRef<any> {
    return null;
  }

  openExitDialog() {
    if (this.saveLoading) {
      return;
    }

    if (this.valueChanged) {
      const dialogRef = this.dialog.open(FormDataStepExitDialogComponent, {
        position: {
          top: '50px',
        },
        autoFocus: false,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.buildForm();
      })
    } else {
      this.dialog.closeAll();
    }
  }

  setMenuTexts() { }

  disable() {
    this.form.disable();
  }

  enable() {
    this.form.enable();
  }

  async saveData() {
    if (this.form.valid && this.valueChanged) {
      this.disable();
      this.saveLoading = true;

      await this.persistData();
      this.dialog.closeAll();

      this.saveLoading = false;
      this.enable();
    } else if (this.form.valid && !this.valueChanged) {
      this.dialog.closeAll();
    } else {
      this.areErrorMessagesShown = true;
      this.setIsDirty(true);
    }
  }

  async persistData() { }
}
