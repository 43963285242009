import { Component } from '@angular/core';
import { CommunicationFormService } from '../../../services/form-services/communication-form-service';
import { HelpersService } from '../../../../../../services/helpers/helpers.service';
import { ToneOfVoiceEnum } from "../../../models/communication/tone-of-voice-enum";

@Component({
  selector: 'app-communication-data-box',
  templateUrl: './communication-data-box.component.html',
  styleUrls: ['./communication-data-box.component.scss']
})
export class CommunicationDataBoxComponent {
  readonly tovOptions: ToneOfVoiceEnum[] = [ToneOfVoiceEnum.FORMAL, ToneOfVoiceEnum.INFORMAL];
  readonly tovOptionLabels: Record<ToneOfVoiceEnum, string> = {
    formal: 'Hivatalos hangnem',
    informal: 'Barátságos hangnem',
  };
  readonly tovOptionDisplayWith = (option: ToneOfVoiceEnum) => this.tovOptionLabels[option];

  constructor(
    public communicationFormService: CommunicationFormService,
    private helpersService: HelpersService,
  ) {
  }

  formatPhoneNumber() {
    this.helpersService.formatNumbers(this.communicationFormService.phoneNumberFormControl);
  }

  onToggleClick(b: boolean) {
    this.communicationFormService.isPaymentPlanAllowedFormControl.patchValue(b);
  }
}
