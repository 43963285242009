import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import axios from 'axios';

export interface CompanySearchAutocompleteItem {
  name: string,
  party_type_id: number,
  type: TypeEnum,
  tax_number: string,
  country: string,
  settlement: string,
  postcode: number,
  street: string,
}

enum TypeEnum {
  ORG = 'org',
  IND = 'ind'
}

@Injectable({
  providedIn: 'root'
})
export class CompanySearchService {
  // tslint:disable-next-line
  private _autocompleteSearchLoading = 0;
  get autocompleteSearchLoading() { return !!this._autocompleteSearchLoading; }

  // tslint:disable-next-line
  private _detailedSearchLoading = 0;
  get detailedSearchLoading() { return !!this._detailedSearchLoading; }

  constructor(
    private http: HttpClient,
  ) {
  }

  async getCompanySearchAutocompleteItems(searchString: string): Promise<CompanySearchAutocompleteItem[]> {
    if (!searchString || searchString.length < 3) {
      return [];
    }

    try {
      ++this._autocompleteSearchLoading;
      const url = `${environment.baseUrl}/api/company-search/autocomplete?searchString=${encodeURIComponent(searchString)}`;
      const result = await axios.get<{ items: CompanySearchAutocompleteItem[]; }>(url);
      return result.data.items;
    } finally {
      --this._autocompleteSearchLoading;
    }
  }
}
