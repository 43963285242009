import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, takeUntil } from 'rxjs/operators';
import { UploadContactExcelDialogComponent } from 'src/app/dialogs/pwc/upload-contact-excel-dialog/upload-contact-excel-dialog.component';
import { UploadPaymentExcelDialogComponent } from 'src/app/dialogs/pwc/upload-payment-excel-dialog/upload-payment-excel-dialog.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { PwcInvoicesTableData, PwcService } from 'src/app/services/pwc/pwc.service';

@Component({
  selector: 'app-pwc-invoices',
  templateUrl: './pwc-invoices.component.html',
  styleUrls: ['./pwc-invoices.component.scss'],
})
export class PwcInvoicesComponent implements OnInit, OnDestroy {
  readonly filter: FormControl;
  readonly showPaid: FormControl;
  private sort_by?: 'invoice_number' | 'due_date_at' | 'customer_name' | 'remaining_amount' = 'due_date_at';
  private sort_direction?: 'asc' | 'desc' | '' = 'asc';

  loading = false;

  data: PwcInvoicesTableData[] = [];

  page = 0;
  page_size = 10;
  length?: number;

  get showTable(): boolean { return this.data.length > 0; }
  get showTableSpinner(): boolean { return this.loading; }
  get showNoInvoices(): boolean { return this.data.length === 0 && !this.loading; }

  private readonly destroy = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private clientsService: ClientsService,
    private pwcService: PwcService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private title: Title,
  ) {
    this.title.setTitle('Számláim - Real-time Debt Management Tool');

    this.filter = this.fb.control('');
    this.showPaid = this.fb.control(false);
  }

  async ngOnInit(): Promise<void> {
    await this.clientsService.getSelectedClient();

    if (this.route.snapshot.queryParams.filter) {
      this.filter.patchValue(this.route.snapshot.queryParams.filter);
    }
    if (this.route.snapshot.queryParams.show_paid) {
      this.showPaid.patchValue(this.route.snapshot.queryParams.show_paid === 'true');
    }
    if (this.route.snapshot.queryParams.sort_by) {
      this.sort_by = this.route.snapshot.queryParams.sort_by;
    }
    if (this.route.snapshot.queryParams.sort_direction) {
      this.sort_direction = this.route.snapshot.queryParams.sort_direction;
    }

    this.filter.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(777), distinctUntilChanged())
      .subscribe({
        next: async (filter: string) => {
          await this.router.navigate([], {
            queryParams: {
              filter,
            },
            queryParamsHandling: 'merge',
          });
          this.page = 0;

          await this.setData();
        }
      });

    this.showPaid.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(777), distinctUntilChanged())
      .subscribe({
        next: async (show: boolean) => {
          await this.router.navigate([], {
            queryParams: {
              show_paid: show ? 'true' : null,
            },
            queryParamsHandling: 'merge',
          });

          await this.setData();
        }
      });

    this.clientsService.selectedClientSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: async () => await this.setData(),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async pageChange(event: PageEvent): Promise<void> {
    this.page = event.pageIndex;
    this.page_size = event.pageSize;

    await this.setData();
  }

  async sortData(event: Sort): Promise<void> {
    if (!event.active || !event.direction) {
      this.sort_by = null;
      this.sort_direction = null;
    } else {
      this.sort_by = event.active as any;
      this.sort_direction = event.direction;
    }

    await this.router.navigate([], {
      queryParams: {
        sort_by: this.sort_by,
        sort_direction: this.sort_direction,
      },
      queryParamsHandling: 'merge',
    });

    await this.setData();
  }

  private async setData(): Promise<void> {
    try {
      this.loading = true;

      const response = await this.pwcService.getInvoices({
        page: this.page + 1,
        per_page: this.page_size,
        search: this.filter.value,
        show_paid: this.showPaid.value ? 1 : 0,
        sort_by: this.sort_by,
        sort_direction: this.sort_direction || undefined,
      });

      this.length = response.pagination.total;
      this.data = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  uploadPaymentExcel(): void {
    const ref = this.dialog.open(UploadPaymentExcelDialogComponent);
    ref.afterClosed()
      .pipe(first())
      .subscribe({
        next: async result => {
          if (result) {
            await this.setData();
          }
        }
      });
  }

  uploadContactExcel(): void {
    const ref = this.dialog.open(UploadContactExcelDialogComponent);
    ref.afterClosed()
      .pipe(first())
      .subscribe({
        next: async result => {
          if (result) {
            await this.setData();
          }
        }
      });
  }

  async getContactExcel() {
    const invoiceNumbers = this.data.map(inv => inv.invoice_number);
    await this.pwcService.downloadContactExcel(invoiceNumbers);
  }

  async getPaymentExcel() {
    const invoiceNumbers = this.data.map(inv => inv.invoice_number);
    await this.pwcService.downloadPaymentExcel(invoiceNumbers);
  }
}
