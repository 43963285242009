<app-header-empty></app-header-empty>
<app-form-template title="Hozd létre ingyenes Payee fiókodat" [formGroup]="registerForm" [submit]="register">
  <div class="form-content flex column">
    <payee-form-field width="wide">
      <span payee-label>Először is add meg az email címedet</span>
      <input payee-input formControlName="email">
      <ng-container payee-error *ngIf="email.dirty">
        <mat-error *ngIf="email.hasError('email')">Létező email címet adj meg</mat-error>
        <mat-error *ngIf="email.hasError('required')">A mező kitöltése kötelező</mat-error>
        <mat-error *ngIf="email.hasError('used')">
          Ezzel az email címmel már regisztráltak. Válassz másikat.
        </mat-error>
      </ng-container>
    </payee-form-field>

    <app-custom-checkbox formControlName="ppAndAszf">
      <div class="input-label-small">
        <span color="black">Elolvastam és elfogadom az </span>
        <a href="https://uploads-ssl.webflow.com/5f4458d3a3bf592c468e1d8e/643d5902de4011410176887a_Payee-Altalanos-szerzodesi-feltetelek-20230401.pdf" target="_blank" color="black">
          <strong><u>ÁSZF</u></strong>
        </a>
        <span color="black">-et és az </span>
        <a href="https://uploads-ssl.webflow.com/5f4458d3a3bf592c468e1d8e/62b97c5fd9624b9d84e158c4_Payee-Adatkezelesi-Tajekoztato-20220615.pdf" target="_blank" color="black">
          <strong><u>Adatkezelési tájékoztatót.</u></strong>&nbsp;
        </a>
      </div>
    </app-custom-checkbox>

    <button
      payee-button
      width="wide"
      height="tall"
      [disabled]="loading"
    >
      <div *ngIf="!loading" class="flex vcenter hcenter">
        <span>Tovább</span>
        <payee-icon name="arrow-right-white"></payee-icon>
      </div>
      <div class="bw-theme flex hcenter" *ngIf="loading">
        <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
        </mat-progress-spinner>
      </div>
    </button>

    <div class="flex vcenter signup-separator">
      <payee-horizontal-line></payee-horizontal-line>
      <div class="input-label" color="grey-medium" style="align-self: auto; font-size: 14px;">vagy</div>
      <payee-horizontal-line></payee-horizontal-line>
    </div>

    <a routerLink="/sso/szamlazzhu">
      <button type="button" payee-button width="wide" color="white" [isBorderButton]="true">Regisztrálj Számlázz.hu fiókoddal</button>
    </a>

    <payee-horizontal-line></payee-horizontal-line>

    <div class="flex hcenter">
      <span class="input-label" color="grey-dark">Már regisztráltál?</span>&nbsp;
      <a color="blue-payee" routerLink="/login">Jelentkezz be</a>
    </div>
  </div>
</app-form-template>
