import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import axios from 'axios';
import { CasesService } from 'src/app/services/cases/cases.service';
import { environment } from 'src/environments/environment';

export type HistoricCase = {
  state_label: string;
  stage_label: string;
  payee_case_reference_id: string;
  started_at: string | null;
};

export type ExpandedElementDetails = {
  payee_case: {
    id: string;
    payee_case_reference_id: string;
    description: string;
    legal_relationship_files: {
      file_name: string;
      storage_url: string;
    }[];
    country_iso: 'HU' | 'EN';
    legal_relationship_specification: string;
    is_interest_added: boolean;
    interest_amount: number;
    is_fourty_eur_added: boolean;
    fourty_eur_amount: number;
    is_product_price_added: boolean;
    product_price_amount: number | null;
    is_procedural_fee_added: boolean;
    procedural_fee_amount: number;
    remaining_amount: number;
    currency: string;
    history: HistoricCase[];
  };
  debtor: {
    name: string;
    address: string | null;
    email: string | null;
    phone: string | null;
    representative: {
      name: string | null;
      type: string | null;
      address: string | null;
    };
  };
  claims: {
    due_date_at: string;
    remaining_amount: number;
    currency: string;
    files: {
      file_name: string;
      storage_url: string;
    }[];
  }[];
};

@Component({
  selector: 'app-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss']
})
export class CaseDetailsComponent implements OnInit {
  @Input() caseId: string;
  @Output() filterCase = new EventEmitter<string>();

  copyTooltipDisabled = true;
  expandedElementDetails: ExpandedElementDetails;
  isSpinnerEnabled = false;

  readonly language = {
    HU: 'Magyar',
    EN: 'Angol',
  };

  get showOptionalClaims(): boolean {
    return this.expandedElementDetails.payee_case.is_interest_added
      || this.expandedElementDetails.payee_case.is_fourty_eur_added
      || this.expandedElementDetails.payee_case.is_product_price_added
      || this.expandedElementDetails.payee_case.is_procedural_fee_added;
  }

  get showParent(): boolean {
    return this.expandedElementDetails.payee_case.history.length > 1;
  }

  constructor(
    private casesService: CasesService,
    private snackbar: MatSnackBar,
  ) { }

  async ngOnInit() {
    setTimeout(() => this.isSpinnerEnabled = true, 250);

    try {
      const url = environment.baseUrl + `/api/case-details/${this.caseId}`;
      const result = await axios.get<ExpandedElementDetails>(url);

      this.expandedElementDetails = result.data;
    } catch (error) {
      this.snackbar.open('Valami hiba történt a részletek betöltésekor!', 'OK', {
        duration: 5000,
      });
      console.error(error);
    }
  }

  async copyText(text: string) {
    await navigator.clipboard.writeText(text);
  }

  filterToHistoricCase(element: HistoricCase): void {
    this.filterCase.emit(element.payee_case_reference_id);
  }

  readonly environment = environment;
}
