import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren, ViewContainerRef } from '@angular/core';
import moment from 'moment-timezone';
import { CasesService } from 'src/app/services/cases/cases.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { WorkflowService } from 'src/app/shared/services/workflow/workflow.service';
import { takeUntil } from 'rxjs/operators';
import axios from 'axios';

export type EventFile = {
  id: string;
  file_name: string;
};

export type EventData = {
  happenedAt: string;
  label: string;
  message: string | null;
  files: EventFile[];
  status: 'failed' | 'success' | 'pending';
};

@Component({
  selector: 'app-case-events',
  templateUrl: './case-events.component.html',
  styleUrls: ['./case-events.component.scss']
})
export class CaseEventsComponent implements OnInit, OnDestroy {
  @Input() caseId: string;
  caseLogs: EventData[];

  @ViewChildren('eventDate') set list(list: QueryList<ElementRef<HTMLElement>>) {
    for (let i = 1; i < list.length; ++i) {
      const prev = list.get(i - 1) as ElementRef<HTMLElement>;
      const current = list.get(i) as ElementRef<HTMLElement>;

      let eventDot = current.nativeElement;
      while (eventDot && !eventDot.classList.contains('event-dot')) {
        eventDot = eventDot.nextElementSibling as HTMLElement;
      }

      const prevBottom = prev.nativeElement.getBoundingClientRect().bottom;
      const currentTop = current.nativeElement.getBoundingClientRect().top;
      const distance = currentTop - prevBottom;

      if (eventDot) {
        eventDot.style.setProperty('--prev-height', distance + 'px');
      }
    }
  }

  isSpinnerEnabled = false;
  private spinnerTimeout?: NodeJS.Timeout;
  showAllLogs = false;

  private readonly destroy = new Subject<void>();
  readonly environment = environment;

  get logsOfExpandedElement(): EventData[] {
    switch (this.showAllLogs) {
      case false: return this.reducedLogsOfExpandedElement;
      case true: return this.caseLogs;
    }
  }

  get reducedLogsOfExpandedElement(): EventData[] {
    if (!this.caseLogs) {
      return [];
    }

    const logs = this.caseLogs;
    const done = logs.filter(log => this.isEventDone(log)).slice(-3);
    const future = logs.filter(log => !this.isEventDone(log)).slice(0, 3);
    return done.concat(future);
  }

  constructor(
    private casesService: CasesService,
    private snackbar: MatSnackBar,
    private workflowService: WorkflowService,
  ) { }

  async ngOnInit() {
    await this.setEvents();
    this.workflowService.afterWorkflow
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: async () => await this.setEvents(),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  readonly isEventDone = (event: EventData): boolean => {
    const eventHappenedAt = moment(event.happenedAt, 'YYYY-MM-DD HH:mm:ss');

    return moment().tz('Europe/Budapest').isAfter(eventHappenedAt);
  };

  readonly isEventFirstDone = (event: EventData): boolean => {
    const events = this.logsOfExpandedElement;
    const first = events.find(event => this.isEventDone(event));
    return first === event;
  };

  readonly isEventFirstFuture = (event: EventData): boolean => {
    const events = this.logsOfExpandedElement;
    const first = events.find(event => !this.isEventDone(event));
    return first === event;
  };

  readonly isEventLastDone = (event: EventData): boolean => {
    const events = this.logsOfExpandedElement;
    for (let i = events.length - 1; i >= 0; --i) {
      if (this.isEventDone(events[i])) {
        return event === events[i];
      }
    }
    return false;
  };

  downloadUrlOfFile(file: EventFile): string {
    return `${environment.baseUrl}/api/file/${file.id}`;
  }

  private async setEvents(): Promise<void> {
    setTimeout(() => {
      this.isSpinnerEnabled = true;
    }, 250);

    try {
      const url = environment.baseUrl + `/api/case-events/${this.caseId}`;
      const result = await axios.get<{ events: EventData[]; }>(url);

      this.caseLogs = result.data.events;
    } catch (error) {
      this.snackbar.open('Valami hiba történt az események betöltésekor!', 'OK', {
        duration: 5000,
      });
      console.error(error);
    }
  }
}
