<div class="top-margin-20 information-container-blue">
  <!--payable amount-->
  <div class="summary-item top-margin-10">
    <div class="summary-item-title">Fizetendő ügyindítási díj</div>
    <div class="summary-item-title">
      {{
        summaryStepService.summaryStepData.start_case_price
          | number : "1.0" : "hu"
      }}
      HUF
    </div>
  </div>

  <!--fees-->
  <ng-container *ngFor="let fee of summaryStepService.summaryStepData.fees">
    <div class="summary-item top-margin-10">
      <div class="grey-text">
        {{ fee.label }}
      </div>
      <div>
        <span class="payee-fee-light">{{ fee.details_text }}</span>
        <span class="grey-text">
          {{ fee.amount | number : "1.0" : "hu" }} HUF</span
        >
      </div>
    </div>
  </ng-container>

  <!--start case fee-->
  <div class="top-margin-10 start-case-fee" *ngIf="isHardForm">
    <payee-toggle
      matTooltip="Fizetési meghagyás esetén automatikusan az ügyindítási díj
                is hozzáadódik a követeléshez, amit az adósnak kell megfizetnie."
      matTooltipPosition="above"
      [selected]="true"
      [text]="'Hozzáadom az ügyindítási díjat a követelésekhez'"
      [disabled]="true"
    >
    </payee-toggle>
  </div>
</div>
