<ap-table #table [(originalData)]="tableData">
  <table class="tables">
    <thead>
    <tr>
      <th *ngFor="let header of tableHeaders" ap-sort [sortKey]="header">
        <span>{{ header }}</span>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of tableData">
      <td *ngFor="let header of tableHeaders">
        <span>{{ data[header] }}</span>
      </td>
    </tr>
    </tbody>
  </table>
</ap-table>
