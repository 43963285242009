<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="money"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>Részletfizetés - Befizetés rögzítése</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      <b>Adja meg az összeget,</b> és láthatja, hogy melyik részletet fizeti ki
      ezzel az adós.
    </div>
  </ng-template>

  <form [formGroup]="installmentForm" *ngIf="dialogData">
    <payee-form-field width="wider" height="taller">
      <input class="full-payment" payee-input formControlName="paymentAmount" />
      <span payee-suffix>
        <img
          class="flag"
          src="assets/shared/global-images/flags/HU-rectangle.svg"
        />
      </span>
      <span class="currency" payee-suffix>HUF</span>

      <ng-container payee-error *ngIf="paymentAmount.dirty">
        <mat-error *ngIf="paymentAmount.hasError('min')"
          >Az összegnek legalább 1 HUF-nak kell lennie</mat-error
        >
        <mat-error *ngIf="paymentAmount.hasError('max')">
          Az összeg legfeljebb
          {{ dialogData.payee_case.remaining_amount | number : "1.0" : "hu" }}
          {{ dialogData.payee_case.currency }} lehet
        </mat-error>
        <mat-error *ngIf="paymentAmount.hasError('required')"
          >A mezőt kötelező kitölteni</mat-error
        >
      </ng-container>
    </payee-form-field>

    <div class="installment-details-container">
      <div
        class="installment-details"
        *ngFor="let installment of dialogData.installments; let i = index"
      >
        <span class="installment-number">
          {{ i + 1 }}. részlet - Határidő:
          {{ installment.dueDate | date : "yyyy.MM.dd." }}
        </span>
        <span
          class="paid-installment"
          *ngIf="installmentPaymentAmounts.get(installment) ?? 0 > 0"
        >
          -{{
            installmentPaymentAmounts.get(installment) | number : "1.0" : "hu"
          }}
          {{ dialogData.payee_case.currency }}
        </span>
        <span class="installment-value">
          {{ installment.remaining_amount | number : "1.0" : "hu" }}
          {{ dialogData.payee_case.currency }}
        </span>
      </div>
    </div>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      <ng-container>Rögzítem a befizetést</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
