<div class="wrapper flex column" [class.close]="!open">
  <!-- <div class="logo-box flex hcenter vcenter">
    <img
      *ngIf="open"
      class="logo"
      [src]="
        environment.PWC
          ? 'assets/shared/global-images/partner-logos/pwc-white-outlined.png'
          : 'assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg'
      "
      width="100px"
      routerLink="cases"
    />
    <img
      *ngIf="!open"
      class="logo"
      [src]="
        environment.PWC
          ? 'assets/shared/global-images/partner-logos/pwc-white-outlined.png'
          : 'assets/shared/global-images/payee-logos/v1/payee-webclip.svg'
      "
      width="56.83px"
      routerLink="cases"
    />
  </div> -->
  <mat-menu
    #menu
    xPosition="before"
    overlapTrigger
    class="start-case-menu-panel"
  >
    <button mat-menu-item routerLink="/cases/wizard">Ügyindítás</button>
    <!-- <button mat-menu-item routerLink="campaign">Tömeges ügyindítás</button> -->
    <button disabled mat-menu-item>Tömeges ügyindítás</button>
  </mat-menu>

  <div
    *ngIf="!environment.PWC"
    class="create-case-button-container flex vcenter"
  >
    <button
      payee-button
      [isRoundButton]="false"
      class="create-case-button vcenter"
      routerLink="/cases/wizard"
    >
      <img
        class="create-case-button-text"
        src="/assets/shared/global-images/icons/plus-white.svg"
        width="14px"
        height="14px"
      />
      <span *ngIf="open">&nbsp;Ügyindítás</span>
    </button>
    <div class="create-case-dropdown-button vcenter flex">
      <button
        class="arrow-down"
        mat-button
        [matMenuTriggerFor]="menu"
        [disabled]="loading"
        [matTooltip]="open ? '' : 'Ügyindítás'"
        matTooltipPosition="right"
        matTooltipClass="sidebar-tooltip"
      >
        <div class="bw-theme flex hcenter" *ngIf="loading">
          <mat-progress-spinner
            color="accent"
            mode="indeterminate"
            diameter="20"
          >
          </mat-progress-spinner>
        </div>
        <ng-container *ngIf="!loading">
          <mat-icon class="arrow-down-icon">keyboard_arrow_down</mat-icon>
        </ng-container>
      </button>
    </div>
  </div>

  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="pwc-invoices"
    [matTooltip]="open ? '' : 'Számlák'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <img src="assets/shared/global-images/icons/list.svg" />
    <span [class.close]="!open">Számlák</span>
  </div>
  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="cases"
    [matTooltip]="open ? '' : 'Ügyek'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <img src="assets/shared/global-images/icons/list.svg" />
    <span [class.close]="!open">Ügyek</span>
  </div>

  <div class="grow3"></div>

  <!--<div [class.close]="!open" class="payee-email">hello&#64;payee.tech</div>-->
  <!-- <div [class.close]="!open" class="version">
    Verziószám: <strong #appVersion>v{{ versionNumber }}</strong>
  </div> -->
  <button
    [class.close]="!open"
    mat-button
    class="toggle-button"
    (click)="toggleOpen()"
  >
    <img
      *ngIf="open"
      width="12px"
      src="assets/shared/global-images/icons/arrow-double-left-grey.svg"
    />
    <img
      *ngIf="!open"
      width="12px"
      src="assets/shared/global-images/icons/arrow-double-right-grey.svg"
    />
  </button>
</div>
