import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Injectable} from '@angular/core';

import {AuthService} from 'src/app/shared/services/auth/auth.service';
import { UsersService } from '../users/users.service';
import { ClientsService } from '../clients/clients.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private clientsService: ClientsService,
  ) {}

  async canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    if (this.authService.isAuthenticated) {
      // await this.clientsService.getSelectedClient();
      return true;
    }
    return this.router.navigateByUrl('login');
    // await this.waitForAuthStateInit();
    // const userRecord = this.authService.getUser();

    // if (!userRecord) {
    //   this.authService.navigateAndSetNext('login');
    //   return;
    // }

    // const tokenResult = await firebase.auth().currentUser.getIdTokenResult();
    // const loginMethod = tokenResult?.claims?.firebase?.sign_in_provider || null;

    // if (loginMethod === 'custom') {
    //   return true;
    // } else if (!userRecord.emailVerified) {
    //   const verifyEmailRegex = /^\/verify-email/g;
    //   if (verifyEmailRegex.test(state.url)) {
    //     return true;
    //   } else {
    //     return this.router.createUrlTree(['/verify-email'], { queryParams: { uid: userRecord.uid }});
    //   }
    // } else {
    //   const hasPassword = !!this.authService.getUser().providerData.find(data => data.providerId === 'password');
    //   if (hasPassword) {
    //     const userData = await this.usersService.getUser();
    //     if (userData.clientIds.length > 0) {
    //       return true;
    //     }
    //     const newClientLinks = [
    //       '/user/add-new-client-onboarding',
    //       '/user/add-new-client',
    //       '/user/add-new-client-success',
    //     ];
    //     if (newClientLinks.includes(state.url)) {
    //       return true;
    //     }
    //     return this.router.createUrlTree(['/user/add-new-client-onboarding']);
    //   } else {
    //     // If user has no password, then we force him to create one.
    //     const createPasswordRegex = /^\/create-password/g;
    //     if (createPasswordRegex.test(state.url)) {
    //       return true;
    //     }
    //     return this.router.createUrlTree(['/create-password']);
    //   }
    // }
  }
}
