<table
  mat-table
  matSort
  (matSortChange)="sortData($event)"
  [dataSource]="dataSource"
  class="mat-elevation-z1"
  multiTemplateDataRows
>
  <ng-container matColumnDef="details_toggle">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <payee-icon name="arrow-down-grey-no-shaft"></payee-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="invoice_number">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Számlaszám</th>
    <td mat-cell *matCellDef="let element">
      {{ element.invoice_number }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Státusz</th>
    <td mat-cell *matCellDef="let element">
      <div
        class="flex vcenter invoice-status"
        [attr.color]="statusMap[element.status]?.color"
      >
        <span
          [class]="'Appkit4-icon ' + statusMap[element.status]?.icon"
        ></span>
        <span>{{ statusMap[element.status]?.label }}</span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="supplier_tax_number">
    <th mat-header-cell *matHeaderCellDef>Szállító adószáma</th>
    <td mat-cell *matCellDef="let element">
      {{ clientTaxNumber }}
    </td>
  </ng-container>

  <ng-container matColumnDef="due_date_at">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Fizetési határidő</th>
    <td mat-cell *matCellDef="let element">
      {{ element.due_date_at | date : "yyyy.MM.dd." }}
    </td>
  </ng-container>

  <ng-container matColumnDef="customer_name">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Vevő neve</th>
    <td mat-cell *matCellDef="let element">
      {{ element.customer.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="remaining_amount">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Fennálló tartozás</th>
    <td mat-cell *matCellDef="let element">
      <div class="claims-sum-amount-box">
        <span class="current">
          {{ element.remaining_amount | number : "1.0" : "hu" }}
          {{ element.currency_iso }}
        </span>
        <span class="original">
          {{ element.original_amount | number : "1.0" : "hu" }}
          {{ element.currency_iso }}
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">
      {{ element.email }}
    </td>
  </ng-container>

  <ng-container matColumnDef="phone">
    <th mat-header-cell *matHeaderCellDef>Telefonszám</th>
    <td mat-cell *matCellDef="let element">
      {{ element.phone }}
    </td>
  </ng-container>

  <ng-container matColumnDef="note">
    <th mat-header-cell mat-sort-header *matHeaderCellDef>Megjegyzés</th>
    <td mat-cell *matCellDef="let element">
      {{ element.note }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <!-- <button
        payee-icon-button
        [matMenuTriggerFor]="actionsMenu"
        (menuOpened)="onActionsMenuOpen(element)"
        (menuClosed)="onActionsMenuClosed()"
        class="actions-menu-button"
        color="white"
      >
        <payee-icon name="more-blue"></payee-icon>
      </button> -->

      <!-- <mat-menu #actionsMenu> -->
      <button
        [disabled]="element.can_create_payee_case !== true"
        [matTooltip]="getCreateCaseTooltip(element)"
        matTooltipClass="create-pwc-case-tooltip"
        [matTooltipPosition]="'left'"
        payee-button
        width="shorter"
        height="short"
        (click)="createCase(element)"
      >
        Ügy létrehozása
      </button>
      <!-- </mat-menu> -->
    </td>
  </ng-container>

  <!-- Expanded Content Column -->
  <ng-container matColumnDef="expandedDetail">
    <td
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <div
        class="element-detail alt-theme flex column"
        [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
      >
        Részletek works!
      </div>
    </td>
  </ng-container>

  <!-- <tr mat-header-row *matHeaderRowDef="filterHeader"></tr> -->
  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
    class="main-header"
  ></tr>
  <tr
    mat-row
    *matRowDef="let element; columns: displayedColumns"
    class="element-row mat-elevation-z2"
    [class.expanded-row]="expandedElement === element"
    (click)="updateExpandedElement(element)"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
    class="detail-row"
  ></tr>
</table>

<mat-paginator
  [pageSize]="pageSize"
  [pageIndex]="pageIndex"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [length]="length"
  (page)="pageChange($event)"
  disableUnderline
  class="mat-elevation-z1"
></mat-paginator>
