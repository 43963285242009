<div class="flex wrapper">
  <ng-container *ngIf="!expandedElementDetails && this.isSpinnerEnabled">
    <mat-progress-spinner
      diameter="100"
      mode="indeterminate"
    ></mat-progress-spinner>
  </ng-container>
  <ng-container *ngIf="expandedElementDetails">
    <div class="left flex column grow1">
      <!-- Case id -->
      <div class="flex vcenter case-id">
        <div class="space-between grow1">
          <span color="blue-payee" class="data-label-bold">
            Ügyazonosító:
          </span>
          <span color="blue-payee" class="data-value-bold">
            {{ expandedElementDetails.payee_case.payee_case_reference_id }}
          </span>
        </div>
        <button
          class="copy-button"
          payee-icon-button
          #tooltip="matTooltip"
          matTooltip="Másolva"
          [matTooltipDisabled]="copyTooltipDisabled"
          matTooltipPosition="after"
          (click)="
            copyText(expandedElementDetails.payee_case.payee_case_reference_id)
          "
        >
          <payee-icon [name]="environment.COPY_ICON"></payee-icon>
        </button>
      </div>
      <hr />
      <!-- Debtor -->
      <ng-container>
        <div class="data-title" color="grey-dark">Adós adatai</div>
        <div class="space-between">
          <div class="data-label">Adós neve</div>
          <div class="data-value">{{ expandedElementDetails.debtor.name }}</div>
        </div>
        <div class="space-between">
          <div class="data-label">Adós címe</div>
          <div class="data-value">
            {{ expandedElementDetails.debtor.address }}
          </div>
        </div>
        <div *ngIf="expandedElementDetails.debtor.email" class="space-between">
          <div class="data-label">Adós email címe</div>
          <div class="data-value">
            {{ expandedElementDetails.debtor.email }}
          </div>
        </div>
        <div *ngIf="expandedElementDetails.debtor.phone" class="space-between">
          <div class="data-label">Adós telefonszáma</div>
          <div class="data-value">
            {{ expandedElementDetails.debtor.phone }}
          </div>
        </div>
      </ng-container>
      <!-- Legal base -->
      <ng-container
        *ngIf="
          expandedElementDetails.payee_case.legal_relationship_files.length >
            0 ||
          expandedElementDetails.payee_case.legal_relationship_specification ||
          expandedElementDetails.payee_case.description
        "
      >
        <ng-container
          *ngIf="
            expandedElementDetails.payee_case.legal_relationship_files.length >
            0
          "
        >
          <div class="data-title" color="grey-dark">Jogviszony igazolása</div>
          <div class="data-label-bold" color="grey-dark">Feltöltött fájlok</div>
          <ul>
            <li
              *ngFor="
                let legalRelationshipFile of expandedElementDetails.payee_case
                  .legal_relationship_files.length
              "
              class="data-value-bold"
              color="grey-dark"
            >
              <a
                [href]="legalRelationshipFile.storage_url"
                target="_blank"
                color="grey-dark"
                style="text-decoration: underline"
              >
                {{ legalRelationshipFile.file_name }}
              </a>
            </li>
          </ul>
        </ng-container>
        <hr
          *ngIf="
            expandedElementDetails.payee_case.legal_relationship_files.length >
              0 && expandedElementDetails.payee_case.description
          "
        />
        <ng-container *ngIf="expandedElementDetails.payee_case.description">
          <div class="data-label-bold" color="grey-dark">Szöveges indoklás</div>
          <div class="data-value">
            {{ expandedElementDetails.payee_case.description }}
          </div>
        </ng-container>
      </ng-container>
      <!-- Language -->
      <div class="space-between">
        <div class="data-label-bold" color="grey-dark">Kommunikáció nyelve</div>
        <div class="data-value">
          {{ language[expandedElementDetails.payee_case.country_iso] }}
        </div>
      </div>
    </div>
    <div class="right flex column grow1">
      <!--Claims-->
      <ng-container *ngIf="expandedElementDetails.claims.length > 0">
        <div class="data-title" color="grey-dark">Követelések</div>
        <div
          *ngFor="let claim of expandedElementDetails.claims; let i = index"
          class="flex column claim"
        >
          <div class="space-between vcenter">
            <div class="flex vcenter">
              <div class="data-label-bold">{{ i + 1 }}. követelés</div>
              <div class="data-label">
                &nbsp;-&nbsp;Lejárt:
                {{ claim.due_date_at | date : "yyyy.MM.dd." }}
              </div>
            </div>
            <div class="data-value">
              {{ claim.remaining_amount | number : "1.0" : "hu" }}
              {{ claim.currency }}
            </div>
          </div>
          <ng-container *ngFor="let claimFile of claim.files">
            <div class="flex vcenter">
              <div class="data-label" color="grey-dark">Feltöltve:&nbsp;</div>
              <a
                class="data-value-bold"
                color="grey-dark"
                style="text-decoration: underline"
                [href]="claimFile.storage_url"
                target="_blank"
              >
                {{ claimFile.file_name }}
              </a>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!--Optional claims-->
      <ng-container *ngIf="showOptionalClaims">
        <hr *ngIf="expandedElementDetails.claims.length > 0" />
        <div class="data-label-bold" color="grey-dark">
          Járulékos követelések:
        </div>
        <div
          class="space-between vcenter"
          *ngIf="expandedElementDetails.payee_case.is_interest_added"
        >
          <div class="data-label-bold">Törvényes kamatok</div>
          <div class="data-value">
            {{
              expandedElementDetails.payee_case.interest_amount
                | number : "1.0" : "hu"
            }}
            {{ expandedElementDetails.payee_case.currency }}
          </div>
        </div>
        <div
          class="space-between vcenter"
          *ngIf="expandedElementDetails.payee_case.is_fourty_eur_added"
        >
          <div class="data-label-bold">40 EUR behajtási költségátalány</div>
          <div class="data-value">
            {{
              expandedElementDetails.payee_case.fourty_eur_amount
                | number : "1.0" : "hu"
            }}
            {{ expandedElementDetails.payee_case.currency }}
          </div>
        </div>
        <div
          class="space-between vcenter"
          *ngIf="expandedElementDetails.payee_case.is_procedural_fee_added"
        >
          <div class="data-label-bold">Eljárási díj</div>
          <div class="data-value">
            {{
              expandedElementDetails.payee_case.procedural_fee_amount
                | number : "1.0" : "hu"
            }}
            {{ expandedElementDetails.payee_case.currency }}
          </div>
        </div>
        <div
          class="space-between vcenter"
          *ngIf="expandedElementDetails.payee_case.is_product_price_added"
        >
          <div class="data-label-bold">Ügyindítási díj</div>
          <div class="data-value">
            {{
              expandedElementDetails.payee_case.product_price_amount
                | number : "1.0" : "hu"
            }}
            {{ expandedElementDetails.payee_case.currency }}
          </div>
        </div>
      </ng-container>
      <hr />
      <div class="space-between">
        <div class="data-title">Teljes követelés</div>
        <div class="data-title">
          {{
            expandedElementDetails.payee_case.remaining_amount
              | number : "1.0" : "hu"
          }}
          {{ expandedElementDetails.payee_case.currency }}
        </div>
      </div>
      <!--History-->
      <ng-container *ngIf="showParent">
        <div class="data-title">Ügy történet</div>
        <div
          *ngFor="
            let history of expandedElementDetails.payee_case.history;
            let i = index
          "
          class="flex vcenter parent-row"
        >
          <div class="data-label-bold">{{ i + 1 }}.</div>
          <a class="data-value" (click)="filterToHistoricCase(history)">
            {{ history.payee_case_reference_id }}
          </a>
          <div class="data-value">
            {{ history.stage_label }}
          </div>
          <div class="data-value" *ngIf="history.started_at">
            Indítva:
            {{ history.started_at | date : "yyyy.MM.dd. HH:mm" }}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
