<div class="legal-base-container">
    <app-legal-base-title [text]="'Jogviszony igazolása'"></app-legal-base-title>

    <div *ngIf="!claimFormService.isCommonCostBalanceFormControl.value" class="content">
        <!--dropzone-->
        <div>
            <payee-dropzone
                    [title]="claimFormService.legalRelationshipExplanationFileFormArray?.controls?.length > 0 ? 'Több dokumentum feltöltése' : 'Töltsd fel a szerződést'"
                    [description]="'Ha nincs szerződésed, elfogadunk megrendelési visszaigazolást, emailes megrendelést PDF-ben lementve, teljesítési igazolást, munkalapot.'"
                    (dropped)="filesSelected($event)">
            </payee-dropzone>
        </div>

        <div class="description" style="font-size: 12px">
            A fájlok mérete nem haladhatja meg a 10MB-ot.
        </div>

        <!--error message-->
        <div *ngIf="claimFormService.legalRelationshipExplanationFileFormArray.invalid
            && claimFormService.areErrorMessagesShown" class="top-margin-10"
        >
            <mat-error custom-error>
                Fájl feltöltése kötelező!
            </mat-error>
        </div>

        <!--uploaded files-->
        <div class="top-margin-20">
            <div *ngFor="let fileFormGroup of claimFormService.legalRelationshipExplanationFileFormArray.controls; let i = index">
                <div class="uploaded-file" [ngStyle]="{'margin-top.px': i !== 0 ? 10 : 0}">
                    <payee-icon [name]="'uploaded-document'"></payee-icon>
                    <div class="name">{{claimFormService.getLegalRelationshipExplanationFileNameFormControl(i).value}}</div>
                    <payee-icon class="file-trash" (click)="deleteFile(i)" [name]="environment.TRASH_ICON"></payee-icon>
                </div>
            </div>
        </div>

        <!--specification-->
        <div *ngIf="claimFormService.legalRelationshipExplanationFileFormArray.controls.length !== 0" class="top-margin-20">
            <div class="input-label">Szöveges indoklás</div>
            <div class="text-area-container">
                <payee-form-field width="widest">
                    <textarea class="text-area" payee-input
                        placeholder="Kérjük írd le röviden, hogy mi történt az ügyben..."
                        [formControl]="claimFormService.legalRelationshipExplanationFormControl">
                    </textarea>
                    <ng-container payee-error *ngIf="claimFormService.legalRelationshipExplanationFormControl.dirty">
                        <div *ngIf="claimFormService.legalRelationshipExplanationFormControl.hasError('required')">
                            A mező kitöltése kötelező!
                        </div>
                        <div *ngIf="claimFormService.legalRelationshipExplanationFormControl.hasError('maxlength')">
                            A szöveg hossza nem haladhatja meg az 5000 karatert!
                        </div>
                    </ng-container>
                </payee-form-field>
                <span
                    *ngIf="claimFormService.legalRelationshipExplanationFormControl.value?.length > 0"
                    class="character-counter"
                    [class.error]="claimFormService.legalRelationshipExplanationFormControl.hasError('maxlength')"
                >
                    {{claimFormService.legalRelationshipExplanationFormControl.value?.length}} / 5000
                </span>
            </div>
        </div>

        <app-legal-base-title [text]="'vagy'" class="top-margin-20"></app-legal-base-title>
    </div>

    <!--checkbox-->
    <div class="checkbox-wrapper" (click)="toggleCheckbox()">
        <div class="icon">
            <payee-icon *ngIf="!claimFormService.isCommonCostBalanceFormControl.value" [name]="'checkbox-empty'"></payee-icon>
            <payee-icon *ngIf="claimFormService.isCommonCostBalanceFormControl.value" [name]="environment.CHECKBOX_FILLED_ICON"></payee-icon>
        </div>
        <div class="checkbox-text-wrapper">
            <div class="text">
                A követelésekhez társasházi egyenlegközlőt töltöttem fel
            </div>
            <div class="sub-text">
                Ekkor nem kell feltöltened jogviszonyt igazoló dokumentumokat
            </div>
        </div>
    </div>
</div>
