import { Component, OnInit } from '@angular/core';
import { CommunicationFormService } from '../../services/form-services/communication-form-service';
import { Title } from '@angular/platform-browser';
import { DebtorFormService } from "../../services/form-services/debtor-form-service";

@Component({
  selector: 'app-communication-dialog',
  templateUrl: './communication-dialog.component.html',
  styleUrls: ['./communication-dialog.component.scss']
})
export class CommunicationDialogComponent implements OnInit {
  constructor(
    public communicationFormService: CommunicationFormService,
    public debtorFormService: DebtorFormService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Kommunikáció - Real-time Debt Management Tool');
  }

  onClose() {
    this.communicationFormService.openExitDialog();
  }

  async onSave() {
    await this.communicationFormService.saveData();
  }
}
