import { FormService } from './form-service';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CommunicationDialogComponent } from '../../dialogs/communication-dialog/communication-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HelpersService } from '../../../../../services/helpers/helpers.service';
import { InitStepService } from "../init-step.service";
import { CommunicationStepData } from "../../models/communication/communication-step-data";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormErrorSnackbarComponent } from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { parsePhoneNumber } from 'libphonenumber-js';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommunicationFormService extends FormService {
  communicationStepData: CommunicationStepData;
  form: FormGroup;

  get prefixFormControl() { return this.form?.get('prefixFormControl') as FormControl; }
  get phoneNumberFormControl() { return this.form?.get('phoneNumberFormControl') as FormControl; }
  get emailAddressFormControl() { return this.form?.get('emailAddressFormControl') as FormControl; }
  get descriptionFormControl() { return this.form?.get('descriptionFormControl') as FormControl; }
  get toneOfVoiceFormControl() { return this.form?.get('toneOfVoiceFormControl') as FormControl; }
  get isPaymentPlanAllowedFormControl() { return this.form?.get('isPaymentPlanAllowedFormControl') as FormControl; }

  constructor(
    public helpersService: HelpersService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private initStepService: InitStepService,
    private snackbar: MatSnackBar,
  ) {
    // set initial parent basic data
    super(helpersService, dialog);
    this.setText(
      'Kommunikáció',
      'Hogyan kommunikáljunk az adósoddal?'
    );
    this.urlName = 'communication';

    // set communication step data
    this.communicationStepData = this.initStepService.communicationStepData;
    this.initStepService.getCommunicationStepDataListener()
      .subscribe(async communicationStepData => {
        this.communicationStepData = communicationStepData;
        if (this.communicationStepData.phone_number) {
          let newPhoneNumber: string | undefined;
          try {
            newPhoneNumber = parsePhoneNumber(this.communicationStepData.phone_number).number;
          } catch (error) {
            try {
              newPhoneNumber = parsePhoneNumber('+' + this.communicationStepData.phone_number).number;
            } catch (error) {
              console.warn(error);
            }
          }
          if (newPhoneNumber && newPhoneNumber !== this.communicationStepData.phone_number) {
            this.communicationStepData.phone_number = newPhoneNumber;
            try {
              await this.initStepService.saveCommunicationStepData();
            } catch (error) {
              console.error(error);
            }
          }
        }
      });
  }

  override buildForm() {
    this.form = this.formBuilder.group({
      prefixFormControl: [
        {
          value: '+36',
          disabled: true
        },
        []
      ],
      phoneNumberFormControl: [
        this.communicationStepData.phone_number?.substring(3) ?? '',
        []
      ],
      emailAddressFormControl: [
        this.communicationStepData.email_address ?? '',
        [Validators.required, Validators.email]
      ],
      descriptionFormControl: [
        { value: this.communicationStepData.description ?? '', disabled: true },
        [Validators.required]
      ],
      toneOfVoiceFormControl: [
        this.communicationStepData.tone_of_voice ?? 'formal',
        [Validators.required]
      ],
      isPaymentPlanAllowedFormControl: [
        this.communicationStepData.is_payment_plan_allowed ?? false,
        [Validators.required]
      ],
    });

    const prefixFormControl = this.form.get('prefixFormControl');
    this.form.get('phoneNumberFormControl')
      .addValidators(this.helpersService.phoneNumberValidatorWithPrefix(prefixFormControl));

    this.phoneNumberFormControl.valueChanges
      .pipe(takeUntil(this.onDestroySubject))
      .subscribe({
        next: () => {
          this.emailAddressFormControl.updateValueAndValidity();
          this.emailAddressFormControl.markAsDirty();
        },
      });
  }

  // override
  async persistData() {
    this.updateData();

    try {
      await this.initStepService.saveCommunicationStepData();
    } catch (error) {
      console.error(error);
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
    }
  }

  updateData() {
    this.communicationStepData.email_address = this.emailAddressFormControl.value ?? '';
    this.communicationStepData.phone_number = this.phoneNumberFormControl.value
      ? (this.prefixFormControl.value + this.phoneNumberFormControl.value)
      : '';
    this.communicationStepData.description = this.descriptionFormControl.value ?? '';
    this.communicationStepData.tone_of_voice = this.toneOfVoiceFormControl.value ?? '';
    this.communicationStepData.is_payment_plan_allowed = this.isPaymentPlanAllowedFormControl.value ?? false;
  }

  // override
  setMenuTexts() {
    if (this.phoneNumberFormControl.value && this.emailAddressFormControl.value) {
      this.dropdownTitle = 'Email és SMS fog kimenni';
      this.dropdownItems = ['Email és SMS fog kimenni'];
    } else if (this.emailAddressFormControl.value) {
      this.dropdownTitle = 'Email fog kimenni';
      this.dropdownItems = ['Email fog kimenni'];
    }
  }

  // override
  openDialog() {
    return this.dialog.open(CommunicationDialogComponent, {
      position: {
        top: '50px',
      },
      autoFocus: false,
      disableClose: true,
    });
  }
}
