import { Injectable } from '@angular/core';
import { RedirectService } from "../redirect/redirect.service";

import { SzamlazzhuUserInfo } from 'src/types';

type SzamlazzhuSSOResponse = {
  success: true;
  firestoreToken: string;
} | {
  success: false;
  error: unknown;
};

@Injectable({
	providedIn: 'root'
})

export class SzamlazzhuSsoApiService {
	constructor(
		private redirectTo: RedirectService,
	) { }

	async authenticate(ssoKey: string, userInfo: SzamlazzhuUserInfo): Promise<SzamlazzhuSSOResponse> {
    throw new Error('Unimplemented');
		// const szamlazzhuSso = httpsCallable<{
    //   ssoKey: string;
    //   userInfo: SzamlazzhuUserInfo;
    // }, SzamlazzhuSSOResponse>(getFunctions(getApp(), 'europe-west1'), 'szamlazzhuSso');
		// const result = await szamlazzhuSso({ ssoKey, userInfo });
    // return result.data;
	}

	async redirectToSzamlazzhuLogin(ssotoken: string) {
    throw new Error('Unimplemented');
		// const szlhuUrl: string = await this.remoteConfigService.getStringConfig('szamlazz_base_url', '');
		// this.redirectTo.post({ ssotoken }, szlhuUrl + 'ssologin');
	}

	/**
	 * According the Szamlazzhu terminology it means: get an SSO token from szamlazzhu to start login process.
	 * SSO token is a short term key, with this token (and with APP_KEY) can you initialize the login.
	 */
	async getSsoToken(): Promise<string> {
    throw new Error('Unimplemented');
		// const getSzamlazzSSOToken = httpsCallable<never, string>(getFunctions(getApp(), 'europe-west1'), 'getSzamlazzSSOToken');
		// const result = await getSzamlazzSSOToken();
		// return result.data;
	}

	async getUserInfo(ssoKey: string): Promise<SzamlazzhuUserInfo> {
    throw new Error('Unimplemented');
		// const getUserInfo = httpsCallable<{ ssotoken: string }, SzamlazzhuUserInfo>(getFunctions(getApp(), 'europe-west1'), 'getUserInfo');
		// const result = await getUserInfo({ ssotoken: ssoKey });
		// return result.data;
	}

	async getSsoResult(token: string): Promise<string> {
    throw new Error('Unimplemented');
		// const szamlazzhuGetSsoResult = httpsCallable<{ ssotoken: string }, string>(getFunctions(getApp(), 'europe-west1'), 'szamlazzhuGetSsoResult');
		// const result = await szamlazzhuGetSsoResult({ ssotoken: token });
		// return result.data;
	}
}
