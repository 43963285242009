import { FormService } from './form-service';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceDialogComponent } from '../../dialogs/invoice-dialog/invoice-dialog.component';
import { KsziUsefulData } from 'src/types';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { HelpersService } from '../../../../../services/helpers/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceFormService extends FormService {
  form: FormArray;
  allSelected = false;

  constructor(
    public helpersService: HelpersService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {
    super(helpersService, dialog);
    this.setText(
        'Számlák kiválasztása',
        'Válaszd ki a számlákat'
    );
    this.urlName = 'invoice-wizard';


    //this.showSzamlazzhuIcon = this.formHelperService.isSzamlazzhu;
    this.form = this.formBuilder.array([], []);

    // TODO szamlazzhu
    //this.invoicesFormDataService.invoicesData.subscribe({
    //  next: () => {
    //    this.buildForm();
     // },
    //});
  }

  buildForm() {
    this.form.clear();

    /*for (const invoice of this.invoicesFormDataService.invoicesData.getValue()) {
      this.form.push(
        new FormControl(this.isInvoiceAlreadySelected(invoice), [])
      );
    }*/

    this.form.setValidators([this.selectedInvoicesValidator]);
  }

  getInvoiceFormControl(invoice: KsziUsefulData) {
   /* const index = this.getIndex(invoice);

    if (this.form.length <= index) {
      return;
    }

    return this.form.at(index);*/
  }

  selectedInvoicesValidator = () => {
    for (const invoiceFormControl of this.form.controls) {
      if (invoiceFormControl.value) {
        return null;
      }
    }

    return { noInvoiceSelected: true };
  };

  setAllInvoiceFormControlValue(selected: boolean) {
    this.allSelected = selected;

    this.form.controls.forEach((control) => {
      control.patchValue(selected);
    });

    this.form.updateValueAndValidity();
  }

  isInvoiceAlreadySelected(invoiceData: KsziUsefulData) {
    //return this.invoicesFormDataService.isInvoiceSelected(invoiceData);
  }

  toggleInvoice(invoice: KsziUsefulData) {
    /*if (this.form.disabled) {
      return;
    }

    const value = !this.getInvoiceFormControl(invoice).value;
    if (!value) {
      this.allSelected = false;
    }
    this.getInvoiceFormControl(invoice).patchValue(value);

    this.form.updateValueAndValidity();*/
  }

  addToSelectedInvoices(invoice: KsziUsefulData) {
   // this.invoicesFormDataService.addToSelectedInvoices(invoice);
  }

  setMenuTexts() {
    this.dropdownTitle = '';
    /*for (const invoice of this.invoicesFormDataService.selectedInvoices) {
      this.dropdownTitle += `${invoice.invoiceId}\n`;
    }
    this.dropdownTitle = this.dropdownTitle.replace(/\n$/, "");
    this.dropdownItems = [this.dropdownTitle];*/
  }

  openDialog() {
    return this.dialog.open(InvoiceDialogComponent, {
      position: {
        top: '50px',
      },
      disableClose: true,
      autoFocus: false,
    });
  }

  private updateInvoiceData() {
    /*this.invoicesFormDataService.selectedInvoices = [];

    for (const invoice of this.invoicesFormDataService.invoicesData.getValue()) {
      if (this.getInvoiceFormControl(invoice).value) {
        this.addToSelectedInvoices(invoice);
      }
    }*/
  }

  private getIndex(invoice: KsziUsefulData) {
    /*return this.invoicesFormDataService.invoicesData
      .getValue()
      .findIndex(inv => invoice.invoiceId === inv.invoiceId);*/
  }

  async persistData() {
    /*this.updateInvoiceData();
    await this.invoicesFormDataService.saveData();*/
  }
}
