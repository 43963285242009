import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HelpersService } from "../../../../../services/helpers/helpers.service";
import { GooglemapsService } from 'src/app/shared/services/googlemaps-service/googlemaps-service.service';

@Component({
  selector: 'app-form-address',
  templateUrl: './form-address.component.html',
  styleUrls: ['./form-address.component.scss']
})
export class FormAddressComponent implements OnInit {
  @Input() countryFormControl: FormControl;
  @Input() postcodeFormControl: FormControl;
  @Input() cityFormControl: FormControl;
  @Input() streetFormControl: FormControl;

  private _addresstext: ElementRef<HTMLInputElement>;
  get addresstext() { return this._addresstext; }
  @ViewChild('addresstext') set addresstext(element) {
    this._addresstext = element;
    this.getPlaceAutocomplete();
  }

  private Autocomplete: typeof google.maps.places.Autocomplete;

  constructor(
    private helpersService: HelpersService,
    private googlemapsService: GooglemapsService,
  ) { }

  async ngOnInit() {
    this.Autocomplete = await this.googlemapsService.getAutocomplete();
  }

  private getPlaceAutocomplete(): void {
    const autocomplete = new this.Autocomplete(this.addresstext.nativeElement, {
      componentRestrictions: { country: 'HU' },
      types: ['(regions)'],
      fields: ['address_components'],
    });

    this.addresstext.nativeElement.onkeydown = () => {
      autocomplete.setComponentRestrictions({
        country: 'HU',
      });
    };

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.updateAddress(place);
    });
  }

  updateAddress(place: any) {
    this.postcodeFormControl.patchValue(this.getPostCode(place) || '');
    this.cityFormControl.patchValue(this.getCity(place) || '');
  }

  getCity(place: any): any {
    const COMPONENT_TEMPLATE = { locality: 'long_name' };
    const city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getPostCode(place: any): any {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' };
    const postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  getAddrComponent(place: any, componentTemplate: { [type: string]: string; }): string {
    const searchedType = Object.keys(componentTemplate)[0];
    const searchedFormat = componentTemplate[searchedType];
    const component = place.address_components.find((addressComponent: any) => {
      return Object.keys(addressComponent).includes(searchedFormat) &&
        addressComponent.types.includes(searchedType);
    });
    if (component) {
      return component[searchedFormat];
    }
    return '';
  }

  formatPostcode() {
    this.helpersService.formatNumbers(this.postcodeFormControl);
  }

  formatCity() {
    this.helpersService.formatLetters(this.cityFormControl);
  }
}
