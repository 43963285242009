import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  readonly registerForm: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private titleService: Title,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) {
    this.titleService.setTitle('Regisztráció - Real-time Debt Management Tool');

    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      ppAndAszf: [false, [Validators.requiredTrue]],
    });
  }

  ngOnInit() {
    const referralId = this.route.snapshot.queryParams?.ref;
    if (referralId) {
      this.localStorageService.saveItem('referralId', referralId);
    }
  }

  get email() { return this.registerForm.get('email'); }
  get ppAndAszf() { return this.registerForm.get('ppAndAszf'); }

  readonly register = async () => {
    if (this.registerForm.invalid || this.loading) {
      return;
    }
    try {
      this.loading = true;
      await this.authService.register({
        email: this.email.value,
        is_privacy_accepted: !!this.ppAndAszf.value,
        is_terms_accepted: !!this.ppAndAszf.value,
      });
    } catch (error) {
      if (error.code === 'functions/already-exists') {
        this.email.setErrors({ used: true });
      } else {
        console.error(error);
      }
    } finally {
      this.loading = false;
    }
  };
}
