import { Component, OnInit } from '@angular/core';
import { DebtorFormService } from '../../services/form-services/debtor-form-service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-debtor-dialog',
  templateUrl: './debtor-dialog.component.html',
  styleUrls: ['./debtor-dialog.component.scss']
})
export class DebtorDialogComponent implements OnInit {
  constructor(
    public debtorFormService: DebtorFormService,
    private titleService: Title,
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Ki tartozik? (Adós) - Real-time Debt Management Tool');
  }

  onClose() {
    this.debtorFormService.openExitDialog();
  }

  async onSave() {
    await this.debtorFormService.saveData();
  }
}
