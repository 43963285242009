import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  FormExitWarningDialogComponent
} from 'src/app/aa-new-form/dialogs/form-exit-warning-dialog/form-exit-warning-dialog.component';
import {ActivatedRoute, Router} from "@angular/router";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss']
})
export class FormHeaderComponent implements OnInit {
  @Input() selectedStepIndex = 0;
  @Input() payeeId = '';

  stepWidth = '206px';
  steps = [
    'dataForms',
    'summary',
    // 'payment',
  ];
  stepNames = {
    dataForms: 'Adatmegadás',
    summary: 'Összegzés',
    payment: 'Fizetés',
  };
  logo = '';

  get isLogoClickable(): boolean { //return !this.caseFormDataService.caseData?.partnerId; TODO
    return true;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  async ngOnInit() {
    /*const logoConfigStr = await this.remoteConfigService.getStringConfig('partner_logos', JSON.stringify({
      default: 'payee-logo',
    }));
    const logoConfig = JSON.parse(logoConfigStr);

    const partnerId = this.caseFormDataService.caseData?.partnerId ?? '';
    if (partnerId && partnerId in logoConfig) {
      this.logo = logoConfig[partnerId];
    } else {
      this.logo = logoConfig.default;
    }*/ //TODO

    this.logo = environment.PWC ? 'pwc-simplified' : 'payee-logo';
  }

  async navigateToStep(index: number) {
    if (index < this.selectedStepIndex) {
      switch (index) {
        case 0: {
          const uuid = this.activatedRoute.snapshot.params['uuid'];
          await this.router.navigate([`/case/${uuid}/data-entry`]);
          break;
        }
        case 1: {
          const uuid = this.activatedRoute.snapshot.params['uuid'];
          await this.router.navigate([`/case/${uuid}/summary`]);
          break;
        }
      }
    }
  }

  onLogoClick() {
    if (!this.isLogoClickable) {
      return;
    }

    this.dialog.open(FormExitWarningDialogComponent, {
      data: {
        payeeId: this.payeeId
      }
    });
  }

  onExitButtonClick() {
    this.dialog.open(FormExitWarningDialogComponent, {
      data: {
        payeeId: this.payeeId
      }
    });
  }

  readonly environment = environment;
}
