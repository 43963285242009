import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { PaymentStepData } from "../models/payment-step-data";
import { Fee } from "../../summary-step/models/fee";
import axios from 'axios';

@Injectable({
    providedIn: 'root'
})
export class PaymentStepService {
    paymentStepData: PaymentStepData;

    private _caseUuid: string = '';

    get caseUuid(): string { return this._caseUuid; }

    constructor( ) { }

    setCaseUuid(caseUuid: string) {
        this._caseUuid = caseUuid;
    }

    async setPaymentStepData(){
        const url = `${environment.baseUrl}/api/payee-case/${this.caseUuid}/payment`
        const { data } = await axios.get(url);
        this.setData(data.payment_form_data);
    }

    setData(paymentStepData: PaymentStepData) {
      console.log(paymentStepData);
        this.paymentStepData = {
            type: paymentStepData.type,
            payee_id: paymentStepData.payee_id,
            client_name: paymentStepData.client_name,
            debtor_name: paymentStepData.debtor_name,
            start_case_price: paymentStepData.start_case_price,
            fees: this.getFeesData(paymentStepData)
        } as PaymentStepData;
    }

    getFeesData(paymentStepData: PaymentStepData): Fee[] {
        const fees = [];

        for (const fee of paymentStepData.fees) {
            fees.push({
                label: fee.label,
                details_text: fee.details_text,
                amount: fee.amount
            } as Fee);
        }

        return fees;
    }
}
