import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EhazSsoApiService } from '../../../services/ehaz/ehaz-sso-api.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Case } from 'src/types';

@Component({
  selector: 'app-ehaz-sso',
  templateUrl: './ehaz-sso.component.html',
  styleUrls: ['./ehaz-sso.component.scss']
})
export class EhazSsoComponent implements OnInit {
  private case: Case;
  private token: string = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private eHazSsoApiService: EhazSsoApiService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async params => {
      if (params.token) {
        this.token = params.token;
        await this.manageEhazSso();
      } else {
        await this.router.navigate(['sso/ehaz/error']);
      }
    });
  }

  private async manageEhazSso() {
    throw new Error('Unimplemented');
    try {
      const eHazSsoResult = await this.eHazSsoApiService.eHazSso(this.token);
      this.case = eHazSsoResult.case;

      await this.authService.loginWithCustomToken(eHazSsoResult.token, 'PLUGIN_EHAZ');

      // let pathname = new URL(this.case.appSiteUrl).pathname;
      // if (pathname.includes('online-fizetesi-meghagyas')) {
      //   const trackingId = this.case.appSiteUrl?.slice(this.case.appSiteUrl?.lastIndexOf('/') + 1);
      //   pathname = '/hard1' + `/${trackingId}` + '/debtor';
      // }

      // await this.router.navigateByUrl(pathname);
    } catch (error) {
      console.log(error);
      await this.router.navigate(['sso/ehaz/error']);
    }
  }
}
