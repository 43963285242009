<div class="wrapper flex">
    <div class="sidebar flex column hcenter">
      <div class="intro flex grow1 column hcenter">
        <a href="https://payee.tech"><img class="logo" src="assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg" alt="logo" width="100px"></a>
        <div class="text">
          <h4>Biztonságos adatkezelés!</h4>
          <p>
            Az ügyeid adatait biztonságosan, felhőalapú adatbázisban tároljuk. Nem kell aggódnod miattuk.
        </p>
        </div>
      </div>
    </div>
    <div class="content flex column">
      <div class="form-layout-box flex hcenter vcenter grow1">
        <div class="form-box flex column vcenter">
          <a href="https://payee.tech"><img class="logo" src="assets/shared/global-images/payee-logos/v1/black-payee-blue-dot.svg" alt="logo" width="100px"></a>
          <h3 class="title">Új jelszó beállítása</h3>
          <div *ngIf="passwordChanged" class="flex column vcenter">
            <img class="success-img" src="assets/shared/global-images/icons/checkmark-circle-blue.svg" *ngIf="passwordChanged">
            <p class="success-message">A jelszót sikeresen megváltoztattad</p>
          </div>

          <div *ngIf="!passwordChanged">
            <p class="description">
              A következő email címhez: {{email}}</p>
            <form>
              <app-custom-input-field [error]="passwordForm.dirty && passwordForm.errors">
                <input type="password" custom-input [formControl]="passwordForm" placeholder="Új jelszó megadása">
                <mat-error custom-error *ngIf="passwordForm.dirty && passwordForm.hasError('required')">
                  A mező kitöltése kötelező
                </mat-error>
                <mat-error custom-error *ngIf="passwordForm.dirty && passwordForm.hasError('error')">
                  Valami hiba történt.
                </mat-error>
              </app-custom-input-field>
              <button
                mat-flat-button
                class="reset-button"
                (click)="setNewPassword()"
                [disabled]="passwordForm.invalid">
                Beállítás és mentés
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>