import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { UsersService } from 'src/app/services/users/users.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-no-client-attached',
  templateUrl: './no-client-attached.component.html',
  styleUrls: ['./no-client-attached.component.scss']
})
export class NoClientAttachedComponent implements OnInit, OnDestroy {
  userName: string = '';

  private readonly destroy = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private clientsService: ClientsService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.usersService.userSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: user => {
          if (!user?.name) {
            this.userName = '';
          } else {
            const [firstName = '', lastName = ''] = user.name.split(' ', 2);
            this.userName = (firstName[0] ?? '') + (lastName[0] ?? '');
          }
        },
      });

    this.clientsService.clientsSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: clients => {
          if (clients.length > 0) {
            this.router.navigateByUrl('user');
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
