import { Component, OnInit } from '@angular/core';
import { FormTypeEnum } from "../init-step/models/common/form-type-enum";
import { SummaryStepService } from "./services/summary-step.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormErrorSnackbarComponent } from "../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from '@angular/platform-browser';
import { WorkflowService } from 'src/app/shared/services/workflow/workflow.service';

@Component({
  selector: 'app-summary-step-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss']
})
export class SummaryStepComponent implements OnInit {
  readonly FormTypeEnum = FormTypeEnum;
  loading = true;

  private caseUuid: string = '';
  private readonly startTransitionIds: Record<FormTypeEnum, string> = {
    demand_letter: 'App\\Models\\PayeeCase\\Transitions\\BDemandLetterStartRequestUser',
    hard: 'App\\Models\\PayeeCase\\Transitions\\CHard1StartRequestUser',
    soft: 'App\\Models\\PayeeCase\\Transitions\\ASoftStartRequestUser',
  };

  constructor(
    public summaryStepService: SummaryStepService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private titleService: Title,
    private workflowService: WorkflowService,
  ) {
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.caseUuid = params['uuid'];
      this.summaryStepService.setCaseUuid(this.caseUuid);

      try {
        const result = await this.summaryStepService.setSummaryStepData();
        if (!result.redirecting) {
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.snackbar.openFromComponent(FormErrorSnackbarComponent);
        await this.router.navigate([`/case/${this.caseUuid}/data-entry`]);
      }
    });

    this.titleService.setTitle('Összegzés – Real-time Debt Management Tool');
  }

  async onBackButtonClick() {
    await this.router.navigate([`/case/${this.caseUuid}/data-entry`]);
  }

  async onForwardButtonClick() {
    try {
      await this.workflowService.runTransition({
        caseId: this.caseUuid,
        transitionId: this.startTransitionIds[this.summaryStepService.summaryStepData.type],
      });
    } catch (error) {
      console.error('Error while starting case', error);
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
    }

    await this.router.navigate(['/user/cases'], {
      queryParams: {
        filterType: 'payeeId',
        filter: this.summaryStepService.summaryStepData.payee_id,
      },
    });
  }
}
