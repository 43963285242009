import { Component, OnInit } from '@angular/core';
import { InitStepService } from './services/init-step.service';
import { FormService } from "./services/form-services/form-service";
import { ClaimFormService } from "./services/form-services/claim-form-service";
import { BankAccountFormService } from "./services/form-services/bank-account-form-service";
import { CommunicationFormService } from "./services/form-services/communication-form-service";
import { FormTypeEnum } from "./models/common/form-type-enum";
import { DebtorFormService } from "./services/form-services/debtor-form-service";
import { ClientFormService } from "./services/form-services/client-form-service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormErrorSnackbarComponent } from "../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { PwcInvoiceFormService } from 'src/app/pwc/pwc-invoice-form.service';
import { Title } from '@angular/platform-browser';
import { LanguageFormService } from './services/form-services/language-form-service';

@Component({
  selector: 'app-init-step',
  templateUrl: './init-step.component.html',
  styleUrls: ['./init-step.component.scss']
})
export class InitStepComponent implements OnInit {
  readonly FormTypeEnum = FormTypeEnum;
  loading = true;
  formServices: FormService[] = [];

  private caseUuid = '';
  private isPwC = true;

  get isBasicDataValid(): boolean {
    for (const formService of this.formServices) {
      if (!formService.form.valid) {
        return false;
      }
    }

    return true;
  }

  constructor(
    public initStepService: InitStepService,
    private debtorFormService: DebtorFormService,
    private claimFormService: ClaimFormService,
    private communicationFormService: CommunicationFormService,
    private languageFormService: LanguageFormService,
    private clientFormService: ClientFormService,
    private bankAccountFormService: BankAccountFormService,
    private pwcInvoiceFormService: PwcInvoiceFormService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackbar: MatSnackBar,
    private titleService: Title,
  ) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.caseUuid = params['uuid'];
      this.initStepService.setCaseUuid(this.caseUuid);

      try {
        const result = await this.initStepService.setInitStepData();
        if (!result.redirecting) {
          this.initializeFormServices();
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
        this.snackbar.openFromComponent(FormErrorSnackbarComponent);
        await this.router.navigate(['user/cases']);
      }
    });

    this.titleService.setTitle('Adatmegadás – Real-time Debt Management Tool');
  }

  initializeFormServices() {
    // add debtor form service
    this.debtorFormService.setData();
    this.formServices.push(this.debtorFormService);

    // add invoices step if it is a szamlazzhu case
    /*if (this.isSzamlazzhu) {
        this.invoiceFormService.setData();
        this.formServices.push(this.invoiceFormService);
    }*/

    if (this.isPwC) {
      this.pwcInvoiceFormService.setData();
      this.formServices.push(this.pwcInvoiceFormService);
    }

    // add communication form service
    if (FormTypeEnum.SOFT === this.initStepService.formType) {
      this.communicationFormService.setData();
      this.formServices.push(this.communicationFormService);
    }

    // add language form service
    if ([
      FormTypeEnum.DEMAND_LETTER,
      FormTypeEnum.SOFT,
    ].includes(this.initStepService.formType)) {
      this.languageFormService.setData();
      this.formServices.push(this.languageFormService);
    }

    if (!this.isPwC) {

      // add claim form service
      this.claimFormService.setData();
      this.formServices.push(this.claimFormService);
    }

    // add client form service
    this.clientFormService.setData();
    this.formServices.push(this.clientFormService);

    // add bank account form service
    this.bankAccountFormService.setData();
    this.formServices.push(this.bankAccountFormService);
  }

  async onBackButtonClick() {
    await this.router.navigate([`/user/cases`]);
  }

  async onForwardButtonClick() {
    await this.router.navigate([`/case/${this.caseUuid}/summary`]);
  }
}
