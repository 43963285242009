export default {
  "arrow-down-grey-no-shaft": "assets/shared/global-images/icon-set/arrow-down-grey-no-shaft.svg",
  "arrow-down-blue": "assets/shared/global-images/icon-set/arrow-down-blue.svg",
  "arrow-left-grey": "assets/shared/global-images/icon-set/arrow-left-grey.svg",
  "arrow-up-grey-no-shaft": "assets/shared/global-images/icon-set/arrow-up-grey-no-shaft.svg",
  "arrow-right-blue": "assets/shared/global-images/icon-set/arrow-right-blue.svg",
  "arrow-right-grey": "assets/shared/global-images/icon-set/arrow-right-grey.svg",
  "arrow-right-white": "assets/shared/global-images/icon-set/arrow-right-white.svg",
  "arrow-up-black": "assets/shared/global-images/icon-set/arrow-up-black.svg",
  "arrow-down-black": "assets/shared/global-images/icon-set/arrow-down-black.svg",
  "attachment": "assets/shared/global-images/icon-set/attachment.svg",
  "calendar": "assets/shared/global-images/icon-set/calendar.svg",
  "checkmark-blue-rectangle": "assets/shared/global-images/icon-set/checkmark-blue-rectangle.svg",
  "checkmark-green-rectangle": "assets/shared/global-images/icon-set/checkmark-green-rectangle.svg",
  "checkmark-green-circle": "assets/shared/global-images/icon-set/checkmark-green-circle.svg",
  "checkmark-darker-green-circle": "assets/shared/global-images/icon-set/checkmark-darker-green-circle.svg",
  "checkmark-grey-circle": "assets/shared/global-images/icon-set/checkmark-grey-circle.svg",
  "checkmark-blue-circle": "assets/shared/global-images/icon-set/checkmark-blue-circle.svg",
  "copy-white": "assets/shared/global-images/icon-set/copy-white.svg",
  "copy-blue": "assets/shared/global-images/icon-set/copy-blue.svg",
  "cross-black": "assets/shared/global-images/icon-set/cross-black.svg",
  "cross-white-circle-black": "assets/shared/global-images/icon-set/cross-white-circle-black.svg",
  "edit-grey": "assets/shared/global-images/icon-set/edit-grey.svg",
  "eyes-off": "assets/shared/global-images/icon-set/eyes-off.svg",
  "eyes-on": "assets/shared/global-images/icon-set/eyes-on.svg",
  "info-grey-outline": "assets/shared/global-images/icon-set/info-grey-outline.svg",
  "logout-grey": "assets/shared/global-images/icon-set/logout-grey.svg",
  "logout-red": "assets/shared/global-images/icon-set/logout-red.svg",
  "minus-grey-rectangle-outline": "assets/shared/global-images/icon-set/minus-grey-rectangle-outline.svg",
  "more-blue": "assets/shared/global-images/icon-set/more-blue.svg",
  "plus-black-circle-white": "assets/shared/global-images/icon-set/plus-black-circle-white.svg",
  "plus-black-square-grey": "assets/shared/global-images/icon-set/plus-black-square-grey.svg",
  "questionmark-grey-circle": "assets/shared/global-images/icon-set/questionmark-grey-circle.svg",
  "thumbs-up-grey-outline": "assets/shared/global-images/icon-set/thumbs-up-grey-outline.svg",
  "trash-red": "assets/shared/global-images/icon-set/trash-red.svg",
  "trash-black": "assets/shared/global-images/icon-set/trash-black.svg",
  "upload-cloud-grey": "assets/shared/global-images/icon-set/upload-cloud-grey.svg",
  "clock": "assets/shared/global-images/icon-set/clock.svg",
  "pencil": "assets/shared/global-images/icon-set/pencil.svg",
  "house": "assets/shared/global-images/icon-set/house.svg",
  "money": "assets/shared/global-images/icon-set/money.svg",
  "simple-calendar": "assets/shared/global-images/icon-set/simple-calendar.svg",
  "plus-grey": "assets/shared/global-images/icon-set/plus-grey.svg",
  "document": "assets/shared/global-images/icon-set/document.svg",
  "plus-grey-thick": "assets/shared/global-images/icon-set/plus-grey-thick.svg",
  "plus-blue": "assets/shared/global-images/icon-set/plus-blue.svg",
  "amex": "assets/shared/global-images/icon-set/amex.svg",
  "barion": "assets/shared/global-images/icon-set/barion.svg",
  "maestro": "assets/shared/global-images/icon-set/maestro.svg",
  "mastercard": "assets/shared/global-images/icon-set/mastercard.svg",
  "external-link-white": "assets/shared/global-images/icon-set/external-link-white.svg",
  "external-link-blue": "assets/shared/global-images/icon-set/external-link-blue.svg",
  "visa": "assets/shared/global-images/icon-set/visa.svg",
  "visa-electron": "assets/shared/global-images/icon-set/visa-electron.svg",
  "szamlazz-hu": "assets/shared/global-images/partner-logos/szamlazz-hu.svg",
  "szamlazz-hu-person": "assets/shared/global-images/partner-logos/szamlazz-hu-person.svg",
  "radio-unchecked-grey": "assets/shared/global-images/icon-set/radio-unchecked-grey.svg",
  "radio-checked-blue": "assets/shared/global-images/icon-set/radio-checked-blue.svg",
  "hard-icon": "assets/shared/global-images/icon-set/hard-icon.svg",
  "add-claim": "assets/shared/global-images/icon-set/add-claim.svg",
  "plus-black": "assets/shared/global-images/icon-set/plus-black.svg",
  "demand-letter-icon": "assets/shared/global-images/icon-set/demand-letter-icon.svg",
  "gdpr": "assets/shared/global-images/icon-set/gdpr.svg",
  "ssl": "assets/shared/global-images/icon-set/ssl.svg",
  "find-black": "assets/shared/global-images/icon-set/find-black.svg",
  "toggle-on": "assets/shared/global-images/icon-set/toggle-on.svg",
  "toggle-off": "assets/shared/global-images/icon-set/toggle-off.svg",
  "hu-flag-circle": "assets/shared/global-images/icon-set/hu-flag-circle.svg",
  "attorney-icon": "assets/shared/global-images/icon-set/attorney-icon.svg",
  "soft-icon": "assets/shared/global-images/icon-set/soft-icon.svg",
  "big-checkmark-green-circle": "assets/shared/global-images/icon-set/big-checkmark-green-circle.svg",
  "payee-logo": "assets/shared/global-images/icon-set/payee-logo.svg",
  "checkbox-empty": "assets/shared/global-images/icon-set/checkbox-empty.svg",
  "arrow-down-darker-blue": "assets/shared/global-images/icon-set/arrow-down-darker-blue.svg",
  "checkbox-filled": "assets/shared/global-images/icon-set/checkbox-filled.svg",
  "toggle-on-disabled": "assets/shared/global-images/icon-set/toggle-on-disabled.svg",
  "szamlazzhu-icon": "assets/shared/global-images/icon-set/szamlazzhu-icon.svg",
  "success-icon": "assets/shared/global-images/icon-set/success-icon.svg",
  "case-success-tick": "assets/shared/global-images/icon-set/case-success-tick.svg",
  "upload-cloud": "assets/shared/global-images/icon-set/upload-cloud.svg",
  "uploaded-document": "assets/shared/global-images/icon-set/uploaded-document.svg",
  "disabled-calendar": "assets/shared/global-images/icon-set/disabled-calendar.svg",
  // form logos
  "bupa-logo": "assets/shared/global-images/partner-logos/form-logos/bupa.svg",
  "cashbook-logo": "assets/shared/global-images/partner-logos/form-logos/cashbook.svg",
  "cashman-logo": "assets/shared/global-images/partner-logos/form-logos/cashman.svg",
  "clouderp-logo": "assets/shared/global-images/partner-logos/form-logos/clouderp.svg",
  "ehaz-logo": "assets/shared/global-images/partner-logos/form-logos/ehaz.svg",
  "innvoice-logo": "assets/shared/global-images/partner-logos/form-logos/innvoice.svg",
  "instacash-logo": "assets/shared/global-images/partner-logos/form-logos/instacash.svg",
  "kulcssoft-logo": "assets/shared/global-images/partner-logos/form-logos/kulcssoft.svg",
  "otpebiz-logo": "assets/shared/global-images/partner-logos/form-logos/otpebiz.svg",
  "quick-logo": "assets/shared/global-images/partner-logos/form-logos/quick.svg",
  "shoprenter-logo": "assets/shared/global-images/partner-logos/form-logos/shoprenter.svg",
  "shoptet-logo": "assets/shared/global-images/partner-logos/form-logos/shoptet.svg",
  "symbion-logo": "assets/shared/global-images/partner-logos/form-logos/symbion.svg",
  "szamlazzhu-logo": "assets/shared/global-images/partner-logos/form-logos/szamlazzhu.svg",
  "tharanis-logo": "assets/shared/global-images/partner-logos/form-logos/tharanis.svg",
  "whmcloud-logo": "assets/shared/global-images/partner-logos/form-logos/whmcloud.svg",
  // mini logos
  "ehaz-mini": "assets/shared/global-images/partner-logos/mini-logos/ehaz.svg",
  "other-mini": "assets/shared/global-images/partner-logos/mini-logos/other.svg",
  "payee-mini": "assets/shared/global-images/partner-logos/mini-logos/payee.svg",
  "quick-mini": "assets/shared/global-images/partner-logos/mini-logos/quick.svg",
  "szamlazzhu-mini": "assets/shared/global-images/partner-logos/mini-logos/szamlazzhu.svg",
  "whm-mini": "assets/shared/global-images/partner-logos/mini-logos/whm.svg",

  // pwc
  "pwc-arrow-down": "assets/shared/global-images/icon-set/pwc/arrow-down.svg",
  "pwc-copy": "assets/shared/global-images/icon-set/pwc/copy.svg",
  "pwc-external-link": "assets/shared/global-images/icon-set/pwc/external-link.svg",
  "pwc-more": "assets/shared/global-images/icon-set/pwc/more.svg",
  "pwc-plus": "assets/shared/global-images/icon-set/pwc/plus.svg",
  "pwc-radio-checked": "assets/shared/global-images/icon-set/pwc/radio-checked.svg",
  "pwc-upload-cloud": "assets/shared/global-images/icon-set/pwc/upload-cloud.svg",
  "pwc-checkbox-filled": "assets/shared/global-images/icon-set/pwc/checkbox-filled.svg",
  "pwc-checkmark-darker-green-circle": "assets/shared/global-images/icon-set/pwc/checkmark-darker-green-circle.svg",
  "pwc-checkmark-green-circle": "assets/shared/global-images/icon-set/pwc/checkmark-green-circle.svg",
  "pwc-toggle-on": "assets/shared/global-images/icon-set/pwc/toggle-on.svg",
  "pwc-trash": "assets/shared/global-images/icon-set/pwc/trash.svg",
  "pwc-logo": "assets/shared/global-images/partner-logos/pwc-logo.svg",
  "pwc-simplified": "assets/shared/global-images/partner-logos/pwc-simplified.svg",
  "pwc-lock": "assets/shared/global-images/icon-set/pwc/lock.svg",
  "en-flag-circle": "assets/shared/global-images/icon-set/pwc/en-flag-circle.svg",
  "pwc_logo_bw_black": "assets/shared/global-images/icon-set/pwc/pwc_logo_bw_black.svg",
  "pwc_logo_bw_white": "assets/shared/global-images/icon-set/pwc/pwc_logo_bw_white.svg",
  "pwc-login": "assets/shared/global-images/icon-set/pwc/pwc-login.svg",
  "pwc-mail": "assets/shared/global-images/icon-set/pwc/pwc-mail.svg",
  "pwc-avatar-outline": "assets/shared/global-images/icon-set/pwc/pwc-avatar-outline.svg",
  "link-outline": "assets/shared/global-images/icon-set/pwc/link-outline.svg",
  "pwc-soft": "assets/shared/global-images/icon-set/pwc/pwc-soft.svg",
  "pwc-demand-letter": "assets/shared/global-images/icon-set/pwc/pwc-demand-letter.svg",
  "pwc-hard": "assets/shared/global-images/icon-set/pwc/pwc-hard.svg",
};
