<payee-popup
  [title]="dialogData?.transition_label"
  [onCancel]="onCancel"
>
  <ng-template #payeePopupTitleTemplate>
    <h1 class="title">{{ dialogData?.transition_label }}</h1>
  </ng-template>
  <div class="subtitle">Belső megjegyzés</div>

  <form [formGroup]="form">
    <payee-form-field
      width="wide"
      height="taller"
    >
      <textarea
        payee-input
        placeholder="Fejtse ki részletesen..."
        formControlName="message"
      ></textarea>
    </payee-form-field>

    <div class="subtitle">Határidő felvétele</div>

    <payee-form-field width="wide">
      <payee-datepicker
        formControlName="deadline"
        [min]="minDate"
      ></payee-datepicker>
    </payee-form-field>

    <div class="subtitle">Fájl(ok) feltöltése</div>

    <payee-dropzone (dropped)="onDrop($event)"></payee-dropzone>

    <div class="files-list">
      <div
        class="file-item flex vcenter"
        *ngFor="let file of files; let fileIndex = index"
      >
        <div class="file-status">
          <payee-icon
            [name]="environment.CHECKMARK_GREEN_CIRCLE_ICON"
            *ngIf="!file.loading && !file.error"
          ></payee-icon>
          <mat-progress-spinner *ngIf="file.loading"></mat-progress-spinner>
          <payee-icon
            name="cross-black"
            *ngIf="!file.loading && file.error"
          ></payee-icon>
        </div>
        <div class="file-name grow1">{{ file.name }}</div>
        <button
          payee-icon-button
          color="white"
          (click)="removeFile(fileIndex)"
        >
          <payee-icon [name]="environment.TRASH_ICON"></payee-icon>
        </button>
      </div>
    </div>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      [disabled]="form.invalid"
      payee-button
      width="wide"
      (click)="onSubmit()"
    >
      Rögzítés
    </button>
  </ng-template>
</payee-popup>
