import { Injectable } from '@angular/core';
import { ClientsService } from '../clients/clients.service';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { Router } from '@angular/router';
import moment, { Moment } from 'moment';

export type CanCreatePayeeCase =
  true
  | 'no-remaining-amount'
  | 'invalid-due-date'
  | `has-ongoing-case-${string}`
  | 'no-contact'
  | 'status-not-done'
  | 'invalid-debtor-tax-number';

export type TableHeader<T> = {
  title: string;
  align: 'center' | 'start';
  key?: keyof T;
};

export type TablePagination = {
  currentPage: number;
  perPage: number;
  total: number;
  lastPage: number;
};

export type GetPwcInvoicesParams = {
  search?: string;
  show_paid?: 1 | 0;
  page: number;
  per_page: number;
  sort_by?: 'invoice_number' | 'due_date_at' | 'customer_name' | 'remaining_amount';
  sort_direction?: 'asc' | 'desc';
};

export type PwcInvoicesTableData = {
  ort_id: number;
  invoice_number: string;
  due_date_at: string | null;
  customer: {
    name: string;
  };
  email: string;
  phone: string;
  original_amount: string;
  remaining_amount: string;
  currency_iso: string;
  can_create_payee_case: CanCreatePayeeCase;
  note: string;
};

export type GetPwcInvoicesResponse = {
  headers: TableHeader<PwcInvoicesTableData>[];
  // TODO
  sortBy: never[];
  data: PwcInvoicesTableData[];
  pagination: TablePagination;
};

export type CreatePwcCaseParams = {
  product_type: string;
};

export type PwcInvoicesOfDebtorTableData = {
  ort_id: number;
  invoice_number: string;
  due_date_at: string | null;
  remaining_amount: number;
  currency_iso: string;
  can_create_payee_case: CanCreatePayeeCase;
  status: string;
  note: string;
};

export type GetInvoicesOfDebtorParams = {
  page: number;
  per_page: number;
  sort_by?: string;
  sort_direction?: string;
};

export type GetInvoicesOfDebtorResponse = {
  headers: TableHeader<PwcInvoicesOfDebtorTableData>[];
  // TODO
  sortBy: never[];
  data: PwcInvoicesOfDebtorTableData[];
  pagination: TablePagination;
};

export type UploadPaymentExcelResponse = {
  stats: {
    all: number;
    success: number;
    error: number;
  };
  errors: {
    [k: number]: {
      data: {
        supplier_tax_number: string;
        invoice_number: string;
        amount: string;
      };
      reasons: string[];
    };
  };
};

export type UploadContactExcelResponse = {
  stats: {
    all: number;
    success: number;
    error: number;
  };
  errors: {
    [k: number]: {
      data: {
        supplier_tax_number: string;
        invoice_number: string;
        email?: string;
        phone?: string;
        due_date_at?: string;
      };
      reasons: string[];
    };
  };
};

@Injectable({
  providedIn: 'root'
})
export class PwcService {

  constructor(
    private clientsService: ClientsService,
    private router: Router,
  ) { }

  readonly statusMap: Record<string, { icon: string, color: string, label: string; }> = {
    ReceivedByPwc: {
      icon: 'icon-time-fill',
      color: 'grey-dark',
      label: 'PwC-hez beérkezett',
    },
    SentToTaxAuthority: {
      icon: 'icon-information-fill',
      color: 'blue-king',
      label: 'Adóhatóságnak elküldve',
    },
    NotReported: {
      icon: 'icon-alert-fill',
      color: 'blue-king',
      label: 'Nem riportált',
    },
    Done: {
      icon: 'icon-circle-checkmark-fill',
      color: 'alert-green',
      label: 'Kész',
    },
    Warning: {
      icon: 'icon-alert-outline',
      color: 'alert-red',
      label: 'Figyelmeztetés',
    },
    Aborted: {
      icon: 'icon-circle-delete-fill',
      color: 'alert-red',
      label: 'Megszakítva',
    },
    PendingTechnicalAnnulment: {
      icon: 'icon-time-fill',
      color: 'blue-king',
      label: 'Technikai érvénytelenítés folyamatban',
    },
    TechnicallyAnnulled: {
      icon: 'icon-circle-delete-fill',
      color: 'alert-red',
      label: 'Technikailag érvénytelenítve',
    },
  } as const;

  showDemandLetterWarning(dueDateAt: string): boolean {
    const maxDemandLetterDate = moment().subtract(20, 'days').format('YYYY-MM-DD');
    return dueDateAt > maxDemandLetterDate;
  }

  getDemandLetterWarningDate(dueDateAt: string): Moment {
    return moment(dueDateAt).add(20, 'days');
  }

  getCanCreatePayeeCaseMessage(can_create_payee_case: CanCreatePayeeCase): string {
    if (can_create_payee_case === true) {
      return '';
    }
    if (can_create_payee_case === 'no-remaining-amount') {
      return 'Az ügy ki van fizetve.';
    }
    if (can_create_payee_case === 'no-contact') {
      return 'Ügyindításhoz meg kell adni az adós elérhetőségeit.'
        + '\nHasználja a kontakt lista feltöltése funkciót.';
    }
    if (can_create_payee_case === 'invalid-due-date') {
      return 'Ügyindításhoz meg kell adni a számla fizetési határidejét.'
        + '\nHasználja a kontakt lista feltöltése funkciót.';
    }
    if (can_create_payee_case === 'status-not-done') {
      return 'Csak akkor indítható ügy, ha a számla státusza "Kész".';
    }
    if (can_create_payee_case === 'invalid-debtor-tax-number') {
      return 'Az adós nem szerepel a cégadatbázisban,'
        + '\npróbálja meg később.';
    }

    const hasOngoingCaseRegex = /^has-ongoing-case-(.*)/;
    const hasOngoingCaseResult = hasOngoingCaseRegex.exec(can_create_payee_case);
    if (hasOngoingCaseResult) {
      return `Van futó ügy a számlára. (${hasOngoingCaseResult[1]})`;
    }

    return can_create_payee_case;
  }

  async getInvoices(
    params: GetPwcInvoicesParams,
    clientId = this.clientsService.selectedClientId,
  ): Promise<GetPwcInvoicesResponse> {
    if (!clientId) {
      return;
    }

    const url = new URL(environment.baseUrl + `/api/client/${clientId}/pwc-invoice`);

    Object.entries(params)
      .filter(([_, value]) => !!value)
      .forEach(([key, value]) => url.searchParams.append(key, value.toString()));

    const response = await axios.get<GetPwcInvoicesResponse>(url.toString());
    return response.data;
  }

  async getInvoicesOfDebtor(
    params: GetInvoicesOfDebtorParams,
    caseId: string,
  ): Promise<GetInvoicesOfDebtorResponse> {
    const url = new URL(environment.baseUrl + `/api/payee-case/${caseId}/pwc-invoice`);

    Object.entries(params)
      .filter(([_, value]) => !!value)
      .forEach(([key, value]) => url.searchParams.append(key, value.toString()));

    const response = await axios.get<GetInvoicesOfDebtorResponse>(url.toString());
    return response.data;
  }

  async createCase(
    params: CreatePwcCaseParams,
    ort_id: number,
    clientId = this.clientsService.selectedClientId,
  ): Promise<void> {
    if (!clientId) {
      return;
    }

    const url = environment.baseUrl + `/api/client/${clientId}/pwc-invoice/${ort_id}`;

    const response = await axios.post<{ id: string; }>(url, params);
    await this.router.navigateByUrl(`case/${response.data.id}`);
    this.clientsService.setClients();
  }

  async uploadPaymentExcel(file: File): Promise<UploadPaymentExcelResponse> {
    const url = environment.baseUrl + `/api/invoice/import/payment`;
    const data = new FormData();
    data.append('file', file);
    const result = await axios.post(url, data);
    return result.data;
  }

  async uploadContactExcel(file: File): Promise<UploadContactExcelResponse> {
    const url = environment.baseUrl + `/api/invoice/import/contact`;
    const data = new FormData();
    data.append('file', file);
    const result = await axios.post(url, data);
    return result.data;
  }

  async downloadContactExcel(
    invoice_numbers: string[] = [],
    client_uuid = this.clientsService.selectedClientId,
  ): Promise<void> {
    const url = environment.baseUrl + '/api/invoice/export/contact';
    const result = await axios.get<Blob>(url, {
      params: {
        invoice_numbers,
        client_uuid,
      },
      responseType: 'blob', // important
    });

    this.downloadFile(result.data, 'pwc_contact.xlsx');
  }

  async downloadPaymentExcel(
    invoice_numbers: string[] = [],
    client_uuid = this.clientsService.selectedClientId,
  ): Promise<void> {
    const url = environment.baseUrl + '/api/invoice/export/payment';
    const result = await axios.get<Blob>(url, {
      params: {
        invoice_numbers,
        client_uuid,
      },
      responseType: 'blob', // important
    });

    this.downloadFile(result.data, 'pwc_payment.xlsx');
  }

  private downloadFile(blob: Blob, fileName: string) {
    const href = URL.createObjectURL(blob);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
}
