import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-new-password-success',
  templateUrl: './new-password-success.component.html',
  styleUrls: ['./new-password-success.component.scss']
})
export class NewPasswordSuccessComponent {
  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle('Új jelszó kérése - Real-time Debt Management Tool');
  }
}
