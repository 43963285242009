import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-data-box',
  templateUrl: './invoice-data-box.component.html',
  styleUrls: ['./invoice-data-box.component.scss']
})
export class InvoiceDataBoxComponent {
  @Input() waitingForInvoices = false;
  @Input() loading = false;

  /*@ViewChild(MatSort) set sort(sort: MatSort) {
    if (sort === this.invoiceFormDataService.invoiceDataSource.sort) {
      return;
    }
    this.invoiceFormDataService.invoiceDataSource.sort = sort;
  }

  invoiceLabels: Record<string, string> = {};

  get szamlazzhuUrl() { return environment.szamlazzhu.url; }
  get showTable() { return this.waitingForInvoices || this.invoiceFormDataService.invoiceDataSource.data.length > 0; }

  constructor(
    public invoiceFormService: InvoiceFormService,
    private remoteConfigService: RemoteConfigService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.invoiceLabels = JSON.parse(
      await this.remoteConfigService.getStringConfig('szamlazzhu_invoice_types', JSON.stringify({}))
    );
  }

  onAllSelected(selected: boolean) {
    this.invoiceFormService.setAllInvoiceFormControlValue(selected);
  }

  getGroupOfInvoice(invoice: KsziUsefulData): KsziUsefulData[] {
    return this.invoiceFormDataService.invoicesData.getValue().filter(inv => {
      return inv.groupId === invoice.groupId;
    });
  }

  correctionTrackBy(invoice: KsziUsefulData) {
    return (correction: KsziUsefulData) => {
      return `${invoice.szlhuId}-correction-${correction.szlhuId}`;
    };
  }

  doesInvoiceHaveGroup(invoice: KsziUsefulData): boolean {
    return this.invoiceFormDataService.invoicesData.getValue().some(inv => {
      return inv.groupId === invoice.groupId && inv.szlhuId !== invoice.szlhuId;
    });
  }*/
}
