import { Component, OnInit } from '@angular/core';
import { BankAccountFormService } from '../../services/form-services/bank-account-form-service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bank-account-dialog',
  templateUrl: './bank-account-dialog.component.html',
  styleUrls: ['./bank-account-dialog.component.scss']
})
export class BankAccountDialogComponent implements OnInit {
  constructor(
    public bankAccountFormService: BankAccountFormService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.titleService.setTitle('Bankadatok - Real-time Debt Management Tool');
  }

  onClose() {
    this.bankAccountFormService.openExitDialog();
  }

  async onSave() {
    await this.bankAccountFormService.saveData();
  }
}
