import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private router: Router,
  ) {
    this.titleService.setTitle('Bejelentkezés - Real-time Debt Management Tool');

    if (this.authService.isAuthenticated) {
      this.router.navigateByUrl(environment.afterLoginPath);
    }
  }

  pwcSso(): void {
    window.location.href = environment.pwcAuthUrl;
  }
}
