import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SzamlazzhuSsoApiService } from "../../../services/szamlazzhu/szamlazzhu-sso-api.service";
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ClientStorage } from 'ClientStorage';

@Component({
	selector: 'app-szamlazzhu-sso-response',
	templateUrl: './szamlazzhu-sso-response.component.html',
	styleUrls: ['./szamlazzhu-sso-response.component.scss']
})
export class SzamlazzhuSsoResponseComponent implements OnInit {
	private token: string;
	private error: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private szamlazzhuSsoApiService: SzamlazzhuSsoApiService,
		private authService: AuthService,
	) { }

	async ngOnInit() {
		// @todo @ivan - majd boviteni a production mode-al!
		const params = this.route.snapshot.queryParams;
		this.token = params.ssotoken;
		this.error = params.error;

		if (this.error) {
			return this.router.navigate(['sso/szamlazzhu/error'], { state: { error: this.error } });
		}

		if (this.token) {
			try {
				const ssokey = await this.szamlazzhuSsoApiService.getSsoResult(this.token);
				const userInfo = await this.szamlazzhuSsoApiService.getUserInfo(ssokey);
				if (!('email' in userInfo)) {
					console.warn('User has no email!');
					return this.router.navigate(['sso/szamlazzhu/error']);
				}

				const result = await this.szamlazzhuSsoApiService.authenticate(ssokey, userInfo);

				if (result.success === true) {
					await this.authService.loginWithCustomToken(result.firestoreToken, 'PLUGIN_SZAMLAZZHU');
					const next = ClientStorage.get('next') || '/user';
					ClientStorage.remove('next');

					this.router.navigateByUrl(next);
				} else {
					console.error(result.error);
					return this.router.navigate(['sso/szamlazzhu/error']);
				}
			} catch (e) {
				console.error(e);
				return this.router.navigate(['sso/szamlazzhu/error']);
			}
		} else {
			console.error('No token');
			return this.router.navigate(['sso/szamlazzhu/error']);
		}
	}
}
