<div class="header-container">
  <!--payee logo-->
  <div class="left">
    <payee-icon class="logo" [name]="environment.LOGO_ICON" (click)="onLogoClick()" width="170px" [height]="environment.PWC ? '60px' : '30px'"></payee-icon>
  </div>

  <!--title-->
  <div class="middle">
    Válaszd ki, mit szeretnél indítani
  </div>

  <!--button-->
  <div class="right">
    <button class="exit-button" (click)="onExitButtonClick()">
      Kilépés
    </button>
  </div>
</div>
