import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { CompanySearchAutocompleteItem, CompanySearchService } from '../company-search-service/company-search.service';

@Component({
  selector: 'payee-company-search-input-field',
  templateUrl: './payee-company-search-input-field.component.html',
  styleUrls: ['./payee-company-search-input-field.component.scss']
})
export class PayeeCompanySearchInputFieldComponent implements OnInit, OnDestroy {
  @Input() name = '';
  @Input() placeholder = '';
  @Input() isHouseRep = false;
  @Input() canBeInd = true;
  @Input() canBeHouse = true;
  @Input() otherSelection = true;
  @Input() width: string;
  @Input() height: string;
  @Input() nameFormControl: FormControl = new FormControl();
  @Input() isDeletable = false;
  @Input() isSearchDisabled = false;

  @Output() individualSelected = new EventEmitter<string>();
  @Output() houseSelected = new EventEmitter<string>();
  @Output() companySearchItemSelected = new EventEmitter<CompanySearchAutocompleteItem>();
  @Output() nameChanged = new EventEmitter();
  @Output() delete = new EventEmitter();

  private companyObserver = new Subject<string>();
  companies: CompanySearchAutocompleteItem[] = [];

  private _nameInput: ElementRef<HTMLInputElement>;
  @ViewChild('nameInput') set nameInput(nameInput: ElementRef<HTMLInputElement>) {
    if (!nameInput) {
      return;
    }
    this._nameInput = nameInput;

    nameInput.nativeElement.addEventListener('input', () => {
      this.companyObserver.next(nameInput.nativeElement.value);
    });
  }
  get nameInput() { return this._nameInput; }

  get companyAutocompleteLoading() { return this.companySearchService.autocompleteSearchLoading; }
  get companySearchLoading() { return this.companySearchService.detailedSearchLoading; }
  get companyLoading() { return this.companyAutocompleteLoading || this.companySearchLoading; }

  constructor(private companySearchService: CompanySearchService) {
    this.companyObserver
      .pipe(debounceTime(777), filter(v => v.length >= 3), distinctUntilChanged())
      .subscribe({
        next: async companyName => {
          this.companies = await this.companySearchService.getCompanySearchAutocompleteItems(companyName);
          this.nameChanged.emit();
        },
      });
  }

  async ngOnInit() {
    this.companies = await this.companySearchService.getCompanySearchAutocompleteItems(this.nameFormControl?.value);
    this.nameChanged.emit();
  }

  fillWithIndValues(name: string) {
    this.name = name;
    this.individualSelected.emit(name);
  }

  fillWithCompanySearchItemValues(autocompleteItem: CompanySearchAutocompleteItem) {
    this.name = autocompleteItem.name;
    this.companySearchItemSelected.emit(autocompleteItem);
  }

  fillWithHouseValues(name: string) {
    this.name = name;
    this.houseSelected.emit(name);
  }

  onDeleteButtonClick() {
    this.delete.emit();
  }

  ngOnDestroy(): void {
    this.companyObserver.complete();
  }
}
