import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClientsService, SelectedClient } from 'src/app/services/clients/clients.service';
import { UsersService } from 'src/app/services/users/users.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  userName: string = '';
  clientName: string = '';

  clients: any[] = [];

  private readonly destroy = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private clientsService: ClientsService,
    private router: Router,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.usersService.userSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: user => {
          if (!user?.name) {
            this.userName = '';
          } else {
            const [firstName = '', lastName = ''] = user.name.split(' ', 2);
            this.userName = (firstName[0] ?? '') + (lastName[0] ?? '');
          }
        },
      });

    this.clientsService.selectedClientSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: client => {
          this.clientName = client?.name ?? '';
        },
      });

    this.clientsService.clientsSubject
      .pipe(takeUntil(this.destroy))
      .subscribe({
        next: clients => {
          this.clients = clients.map(c => ({
            value: c,
            label: c.name,
          }));
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onSelect(event: { originEvent: unknown, selected: { value: SelectedClient; }; }): void {
    this.clientsService.setSelectedClient(event.selected.value);
  }

  navigateToProfile(): void {
    this.router.navigateByUrl('user/user-profile');
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }
}
