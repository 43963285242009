<div id="page-container" class="flex column" *ngIf="!loading">
  <div id="content-wrap" class="grow1">
    <!--header-->
    <app-form-header
      [selectedStepIndex]="2"
      [payeeId]="paymentStepService.paymentStepData.payee_id"
    >
    </app-form-header>

    <!--content-->
    <div class="main-content-wrapper">
      <!--main content left-->
      <div class="main-content-left">
        <div>
          <!--payment method component-->
          <app-payment-method></app-payment-method>
          <!--back button-->
          <div class="backward-button top-margin-30">
            <button payee-back-button (click)="onBackButtonClick()">
              Vissza az összegzésre
            </button>
          </div>
        </div>
      </div>

      <!--main content right-->
      <div class="main-content-right">
        <!--payment details component-->
        <div>
          <app-payment-details></app-payment-details>
        </div>
      </div>
    </div>
  </div>

  <!--footer-->
  <app-footer></app-footer>
</div>

<!--loading screen-->
<app-loading-screen *ngIf="loading"></app-loading-screen>
