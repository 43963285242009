<!--contact title-->
<div class="component-title top-margin-30">
  Ide küldjük a fizetési felszólításokat
</div>

<!--tel number wrapper-->
<div class="tel-number-wrapper top-margin-10">
  <!--prefix-->
  <payee-dropdown
    class="prefix"
    [formControl]="communicationFormService.prefixFormControl"
    height="tall"
  >
    <ng-template #menuDisplayTemplate let-selected>
      <div class="flex vcenter" *ngIf="selected">
        <span class="grow1">{{ selected }}</span>
        <payee-icon
          class="prefix-flag-icon"
          [name]="'hu-flag-circle'"
        ></payee-icon>
      </div>
    </ng-template>
  </payee-dropdown>

  <!--telephone number-->
  <payee-form-field>
    <input
      payee-input
      class="tel-number"
      placeholder="301234567"
      [formControl]="communicationFormService.phoneNumberFormControl"
      (ngModelChange)="formatPhoneNumber()"
    />
    <ng-container
      payee-error
      *ngIf="communicationFormService.phoneNumberFormControl.dirty"
    >
      <div
        *ngIf="
          communicationFormService.phoneNumberFormControl.hasError(
            'invalidPhoneNumber'
          )
        "
      >
        Helytelen formátum!
      </div>
    </ng-container>
  </payee-form-field>
</div>

<!--email addresses-->
<div class="top-margin-10">
  <payee-form-field height="tall" width="widest">
    <input
      payee-input
      [formControl]="communicationFormService.emailAddressFormControl"
      [placeholder]="'finance@debtor.com'"
    />
    <ng-container
      payee-error
      *ngIf="communicationFormService.emailAddressFormControl.dirty"
    >
      <div
        *ngIf="
          communicationFormService.emailAddressFormControl.hasError('required')
        "
      >
        A mező kitöltése kötelező!
      </div>
      <div
        *ngIf="
          communicationFormService.emailAddressFormControl.hasError('email')
        "
      >
        Létező email címet adj meg!
      </div>
    </ng-container>
  </payee-form-field>
</div>

<!--info box-->
<div class="top-margin-10">
  <payee-info class="info-box phone-number-info-box">
    <span class="info-box-text">
      Ha nem ad meg telefonszámot, attól még elindul a felszólítás, csupán
      kizárólag email csatornán fogjuk keresni az adósát.
    </span>
  </payee-info>
</div>

<!--explanation title-->
<div class="component-title top-margin-30">Megjegyzés</div>

<!--explanation-->
<div class="text-area-container top-margin-10">
  <payee-form-field width="widest" height="shorter">
    <input
      payee-input
      placeholder="Írd le, hogy mi történt az ügyben. Pl. A TT-2022-103 sorszámú számla nem lett kifizetve."
      [formControl]="communicationFormService.descriptionFormControl"
    />
    <ng-container
      payee-error
      *ngIf="communicationFormService.descriptionFormControl.dirty"
    >
      <div
        *ngIf="
          communicationFormService.descriptionFormControl.hasError('required')
        "
      >
        A mező kitöltése kötelező!
      </div>
    </ng-container>
  </payee-form-field>
</div>

<!--info box-->
<div class="top-margin-10">
  <payee-info class="info-box debtor-info-box">
    <span class="info-box-text"> Az adós ezt látni fogja. </span>
  </payee-info>
</div>

<!--tone of voice-->
<!-- <div class="top-margin-10">
  <payee-dropdown
    [formControl]="communicationFormService.toneOfVoiceFormControl"
    [options]="tovOptions"
    height="tall"
    width="widest"
    [displayWith]="tovOptionDisplayWith"
  >
  </payee-dropdown>
</div> -->

<!--info box-->
<!-- <div class="top-margin-10">
  <payee-info class="info-box tov-info-box">
    <span class="info-box-text">
      Mindegyik hangnem formális és barátságos, de a hivatalos hangnem egy
      távolságtartóbb, hivatalosabb, míg a barátságos hangnem egy közvetlenebb
      hangnem. Itt láthatsz példákat rá:
      <a
        color="blue-king"
        href="https://help.payee.tech/hu/articles/6251457-mi-lesz-kikuldve-az-adosnak-az-egyes-modulokban"
      >
        <strong>Tudásközpont</strong>
      </a>
    </span>
  </payee-info>
</div> -->

<!--partial payment title-->
<!--<div class="component-title top-margin-30">
  Részletfizetés
</div>-->

<!--partial payment toggle-->
<!--<div class="toggle-wrapper top-margin-10">
  <div class="toggle-icon">
    <payee-icon *ngIf="communicationFormService.isPaymentPlanAllowedFormControl.value" [name]="'toggle-on'" (click)="onToggleClick(false)"></payee-icon>
    <payee-icon *ngIf="!communicationFormService.isPaymentPlanAllowedFormControl.value" [name]="'toggle-off'" (click)="onToggleClick(true)"></payee-icon>
  </div>
  <div class="toggle-text">Az adós fizethet részletekben</div>
</div>-->
