import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import axios from 'axios';
import { environment } from 'src/environments/environment';

export type UpdateUserParams = {
  name: string;
};

export type User = {
  email: string;
  has_2fa: boolean;
  id: number;
  name: string | null;
};

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  readonly userSubject = new BehaviorSubject<User | null>(null);

  constructor(
    private authService: AuthService,
  ) {
    this.authService.authSubject
      .subscribe({
        next: async isLoggedIn => {
          if (isLoggedIn) {
            const user = await this.setUser();
            this.userSubject.next(user);
          } else {
            this.userSubject.next(null);
          }
        },
      });
  }

  async getUser(): Promise<User> {
    return await new Promise(resolve => this.userSubject.pipe(filter(user => !!user)).subscribe(resolve));
  }

  async updateUser(params: UpdateUserParams): Promise<User> {
    const url = environment.baseUrl + `/api/user`;
    const response = await axios.patch<User>(url, params);
    return response.data;
  }

  async requestUserDeletion() {
    throw new Error('Unimplemented');
    // await this.authService.logout();
  }

  private async setUser(): Promise<User> {
    const url = environment.baseUrl + `/api/user`;
    const result = await axios.get(url);
    return result.data;
  }
}
