<div class="payment-details-wrapper">
  <!--details-->
  <div class="data-details-wrapper">
    <payee-icon *ngIf="isHardForm" [name]="'hard-icon'" height="80px" width="auto"></payee-icon>
    <payee-icon *ngIf="isSoftForm" [name]="'soft-icon'" height="80px" width="auto"></payee-icon>
    <payee-icon *ngIf="isDemandLetterForm" [name]="'demand-letter-icon'" height="80px" width="auto"></payee-icon>
    <div class="details">
      <h2 *ngIf="isHardForm">Fizetési meghagyás</h2>
      <h2 *ngIf="isSoftForm">Fizetési felszólítás</h2>
      <h2 *ngIf="isDemandLetterForm">Ügyvédi levél</h2>
      <div>Hitelező: {{paymentStepService.paymentStepData.client_name}}</div>
      <div>Adós: {{paymentStepService.paymentStepData.debtor_name}}</div>
    </div>
  </div>

  <!--horizontal divider line-->
  <div class="horizontal-line-divider top-margin-30"></div>

  <!--fees-->
  <div class="top-margin-20">
    <ng-container *ngFor="let fee of paymentStepService.paymentStepData.fees">
      <div class="fee-wrapper top-margin-5">
        <div class="title">
          {{fee.label}}
        </div>
        <div class="fee-amount">
          <span class="label">{{fee.details_text}}</span>
          <span class="amount">{{fee.amount | number:'1.0':'hu'}} HUF</span>
        </div>
      </div>
    </ng-container>
  </div>

  <!--horizontal divider line-->
  <div class="horizontal-line-divider top-margin-20"></div>

  <!--total payable amount-->
  <div class="total-payment-wrapper top-margin-20">
    <div class="title">
      Fizetendő végösszeg
    </div>
    <div class="amount-wrapper">
      <span class="label">Bruttó</span>
      <span class="amount">
        {{paymentStepService.paymentStepData.start_case_price | number:'1.0':'hu'}} HUF
      </span>
    </div>
  </div>
</div>
