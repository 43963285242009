import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';

import { Case, Claim, Client, Debtor } from 'src/types';
import { ClientsService } from '../clients/clients.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DiscardCaseDialogComponent } from 'src/app/dialogs/case/discard-case-dialog/discard-case-dialog.component';

export interface QuickHardColGuestUploadUser {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export type CreateCaseParams = {
  product_type: string;
};

export type CreateCaseResponse = {
  id: string;
};

export type GetCasesParams = {
  debtor_name?: string;
  payee_case_reference_id?: string;
  stage?: 'draft' | 'ongoing' | 'closed';
  page?: number;
  per_page?: number;
};

export type CasesTableData = {
  id: string;
  payee_case_reference_id: string;
  parties: {
    debtor_name: string | null;
    client_name: string;
  };
  state: {
    stage: string;
    state_label_text_color: string;
    state_label_background_color: string;
    state_label: string;
    stage_label: string;
  };
  claim: {
    remaining_amount: number;
    original_amount: number;
    currency: string;
  };
  actions: {
    id: string;
    label: string;
    dialog: string;
  }[];
  is_deletable: boolean;
  is_editable: boolean;
};

export type CasesTableHeader = {
  title: string;
  align: 'center' | 'start';
  key?: keyof CasesTableData;
};

export type GetCasesResponse = {
  headers: CasesTableHeader[];
  // TODO
  sortBy: never[];
  data: CasesTableData[];
  pagination: {
    currentPage: number;
    perPage: number;
    total: number;
    lastPage: number;
  };
};

@Injectable({
  providedIn: 'root'
})
export class CasesService {
  constructor(
    private clientsService: ClientsService,
    private dialog: MatDialog,
  ) { }

  async getCases(params: GetCasesParams, clientId = this.clientsService.selectedClientId): Promise<GetCasesResponse> {
    if (!clientId) {
      throw new Error('Client id not set');
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case`;
    const result = await axios.get<GetCasesResponse>(
      url,
      {
        params,
      },
    );

    return result.data;
  }

  async getCaseByClaimToken(claimToken: string): Promise<{
    caseUrl: string;
    customToken: string;
  }> {
    throw new Error('Unimplemented');
  }

  async createCase(params: CreateCaseParams, clientId = this.clientsService.selectedClientId): Promise<CreateCaseResponse> {
    if (!clientId) {
      return;
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case`;
    const result = await axios.post<CreateCaseResponse>(url, params);
    this.clientsService.setClients();
    return result.data;
  }

  async updateCase(id: string, data: Partial<Case>): Promise<void> {
    throw new Error('Unimplemented');
  }

  openDeleteCaseDialog(id: string): MatDialogRef<DiscardCaseDialogComponent> {
    return this.dialog.open(DiscardCaseDialogComponent, {
      data: {
        id,
      },
    });
  }

  async deleteCase(caseId: string, clientId = this.clientsService.selectedClientId): Promise<void> {
    if (!clientId || !caseId) {
      return;
    }

    const url = environment.baseUrl + `/api/payee-case/${caseId}`;
    await axios.delete(url);
  }

  /**
   * @param email email of user
   * @returns Promise inculding the tracking id of the case
   */
  async uploadGuestCase(caseData: Case, claims: Claim[], debtors: Debtor[], client: Client, user: unknown) {
    throw new Error('Unimplemented');
  }

  async startWithCardPayment(caseId: string, clientId = this.clientsService.selectedClientId): Promise<void> {
    if (!clientId || !caseId) {
      return;
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case/${caseId}/start-with-card-payment`;
    const response = await axios.get<string>(url);
    window.location.href = response.data;
  }

  async startWithTransfer(caseId: string, clientId = this.clientsService.selectedClientId): Promise<void> {
    if (!clientId || !caseId) {
      return;
    }

    const url = environment.baseUrl + `/api/client/${clientId}/case/${caseId}/start-with-transfer`;
    const response = await axios.post<string>(url);
    console.log(response.data);
  }
}
