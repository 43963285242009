<div class="wrapper">
  <!-- <app-header title="Ügyek"></app-header> -->
  <div class="content">
    <div class="tabs">
      <mat-tab-group
        [selectedIndex]="selectedTab"
        animationDuration="0ms"
        (selectedTabChange)="navigateToTab($event)"
      >
        <mat-tab label="Piszkozatok" [disabled]="loading">
          <ng-template mat-tab-label>
            <div
              class="flex vcenter hcenter tab-label-box"
              [class.active]="showDrafts"
            >
              <span class="tab-label">Piszkozatok</span>
              <div class="case-counter">{{ draftCount }}</div>
            </div>
          </ng-template>

          <payee-info class="state-info" *ngIf="!environment.PWC">
            Ezek az ügyek, amiket félbehagytál. Ha egy Számlázz.hu-s számláról
            az Ügyvédi és jogi megoldások linkre kattintva jöttél ide, akkor
            minden ilyen átkattintással keletkezett egy piszkozat.
          </payee-info>
          <payee-info class="state-info" *ngIf="environment.PWC">
            Ezek az ügyek, amik a
            <a routerLink="/user/pwc-invoices" color="white">Számlák</a>
            menüpontból az
            <i>Ügy létrehozása</i> gombbal létre lettek hozva, de nem lettek
            elindítva. Az <i>Ügy szerkesztése</i> gombbal megnyitni, majd az
            adatok megadása után elindítani, az <i>Ügy törlése</i> gombbal
            törölni tudja az egyes piszkozatokat.
          </payee-info>
        </mat-tab>
        <mat-tab label="Nyitott ügyek" [disabled]="loading">
          <ng-template mat-tab-label>
            <div
              class="flex vcenter hcenter tab-label-box"
              [class.active]="showOngoing"
            >
              <span class="tab-label">Nyitott ügyek</span>
              <div class="case-counter">{{ ongoingCount }}</div>
            </div>
          </ng-template>

          <payee-info class="state-info" *ngIf="!environment.PWC">
            Ezek az ügyek, amik sikeresen elindultak, és jelenleg is futnak. Ha
            bármelyikkel teendőd lenne, akkor arról emailben is értesítünk majd.
          </payee-info>
          <payee-info class="state-info" *ngIf="environment.PWC">
            Ezek az ügyek, amik sikeresen elindultak, és jelenleg is futnak. Ha
            bármelyikkel teendője lenne, akkor arról emailben is értesítjük
            majd.
          </payee-info>
        </mat-tab>
        <mat-tab label="Lezárt ügyek" [disabled]="loading">
          <ng-template mat-tab-label>
            <div
              class="flex vcenter hcenter tab-label-box"
              [class.active]="showClosed"
            >
              <span class="tab-label">Lezárt ügyek</span>
              <div class="case-counter">{{ closedCount }}</div>
            </div>
          </ng-template>

          <payee-info class="state-info" *ngIf="!environment.PWC">
            Ezek az ügyek, amik vagy sikeresen kifizetésre kerültek, vagy egyéb
            okból lettek leállítva.
          </payee-info>
          <payee-info class="state-info" *ngIf="environment.PWC">
            Ezek az ügyek, amik megtérültek, vagy egyéb okból le lettek állítva.
          </payee-info>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="toolbar flex vcenter">
      <div class="flex grow1 vcenter">
        <payee-dropdown
          width="short"
          [options]="filterTypeOptions"
          [displayWith]="filterTypeLabel"
          [formControl]="filterType"
        >
        </payee-dropdown>
        <payee-form-field appearance="outline">
          <input
            payee-input
            placeholder="Keressen rá az ügyre"
            [formControl]="filter"
            [matAutocomplete]="auto"
          />
        </payee-form-field>
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <ng-container *ngIf="filterType.value === 'debtorName'">
            <mat-option
              *ngFor="let debtor of searchedDebtorNames"
              (click)="onDebtorFilterSelected(debtor)"
              [value]="debtor"
            >
              {{ debtor }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
    </div>
    <ng-container *ngIf="!loading && hasCases">
      <app-cases-table
        [data]="tableData"
        (pageSizeChange)="setPageSize($event)"
        [pageSize]="pageSize"
        (pageIndexChange)="setPageIndex($event)"
        [pageIndex]="pageIndex"
        [length]="length"
        (deleted)="onDeleted()"
        (filterCase)="filterCase($event)"
      ></app-cases-table>
    </ng-container>
    <div class="loading-spinner alt-theme" *ngIf="loading">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="100"
      ></mat-progress-spinner>
    </div>
    <div
      class="nocases flex grow1 column vcenter"
      *ngIf="!loading && !hasCases"
    >
      <!-- <img
        src="assets/shared/global-images/big-svgs/welcome.svg"
        style="width: 400px; height: 300px"
      /> -->
      <span class="no-cases-text" color="grey-dark">Nem található ügy</span>
      <!-- <span *ngIf="selectedTab === tabs.draft" class="no-cases-text"
        >Hoppá, még nincs piszkozatod. Kezdd el most!</span
      >
      <span *ngIf="selectedTab === tabs.ongoing" class="no-cases-text"
        >Hoppá, még nem indítottál ügyet. Kezdd el most!</span
      >
      <span *ngIf="selectedTab === tabs.closed" class="no-cases-text"
        >Hoppá, még nem zárult le egy ügyed sem. Kezdd el most!</span
      > -->
      <!-- <button mat-flat-button routerLink="/cases/wizard">
        <img
          src="assets/shared/global-images/icons/plus-white.svg"
          width="14px"
          height="14px"
        />
        <span>Új ügy</span>
      </button> -->
    </div>
  </div>
</div>
