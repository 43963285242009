import { FormService } from './form-service';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HelpersService } from '../../../../../services/helpers/helpers.service';
import { ClientDialogComponent } from '../../dialogs/client-dialog/client-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { InitStepService } from "../init-step.service";
import { GroupTypeEnum } from "../../models/common/group-type-enum";
import { ClientStepData } from "../../models/client/client-step-data";
import { getEmptyRepresentativeData, RepresentativeData } from "../../models/common/representative-data";
import { ClientData } from "../../models/client/client-data";
import moment from "moment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormErrorSnackbarComponent } from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";

@Injectable({
  providedIn: 'root'
})
export class ClientFormService extends FormService {
  clientStepData: ClientStepData;
  form: FormGroup;

  isAddressEditable = false;
  isAdditionalDataEditable = false;

  // root form controls
  get groupTypeFormControl(): FormControl { return this.form?.get('groupTypeFormControl') as FormControl; };
  get clientFormGroup(): FormGroup { return this.form.get('clientFormGroup') as FormGroup; };
  get representativeFormGroup(): FormGroup { return this.form.get('representativeFormGroup') as FormGroup; };

  // client
  get taxNumberFormControl() { return this.clientFormGroup.get('taxNumberFormControl') as FormControl; }
  get registrationNumberFormControl() { return this.clientFormGroup.get('registrationNumberFormControl') as FormControl; }
  get nameFormControl() { return this.clientFormGroup.get('nameFormControl') as FormControl; }
  get countryFormControl() { return this.clientFormGroup.get('countryFormControl') as FormControl; }
  get postcodeFormControl() { return this.clientFormGroup.get('postcodeFormControl') as FormControl; }
  get settlementFormControl() { return this.clientFormGroup.get('settlementFormControl') as FormControl; }
  get streetFormControl() { return this.clientFormGroup.get('streetFormControl') as FormControl; }
  get motherNameFormControl() { return this.clientFormGroup.get('motherNameFormControl') as FormControl; }
  get birthDateFormControl() { return this.clientFormGroup.get('birthDateFormControl') as FormControl; }
  get birthPlaceFormControl() { return this.clientFormGroup.get('birthPlaceFormControl') as FormControl; }

  // representative
  get repNameFormControl() { return this.representativeFormGroup.get('repNameFormControl') as FormControl; }
  get repTaxNumberFormControl() { return this.representativeFormGroup.get('repTaxNumberFormControl') as FormControl; }
  get repTypeFormControl() { return this.representativeFormGroup.get('repTypeFormControl') as FormControl; }
  get repCountryFormControl() { return this.representativeFormGroup.get('repCountryFormControl') as FormControl; }
  get repPostcodeFormControl() { return this.representativeFormGroup.get('repPostcodeFormControl') as FormControl; }
  get repSettlementFormControl() { return this.representativeFormGroup.get('repSettlementFormControl') as FormControl; }
  get repStreetFormControl() { return this.representativeFormGroup.get('repStreetFormControl') as FormControl; }

  get clientAddress() {
    return this.postcodeFormControl.value + ' '
      + this.settlementFormControl.value + ', '
      + this.streetFormControl.value;
  }

  get representativeAddress() {
    return this.repPostcodeFormControl.value + ' '
      + this.repSettlementFormControl.value + ', '
      + this.repStreetFormControl.value;
  }

  get additionalDataString() {
    if (!this.isIndividualWithTaxNumber) {
      return '';
    }

    const birthDate = this.birthDateFormControl.value?.format('YYYY.MM.DD');
    const birthPlace = this.birthPlaceFormControl.value
    const mothersName = this.motherNameFormControl.value;

    return `${birthDate}, ${birthPlace} (a.n.: ${mothersName})`;
  }

  get isHouseClient() {
    return this.clientStepData.type === GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITHOUT_TAX_NUMBER
      || this.clientStepData.type === GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER;
  }

  get isHouseWithRepresentativeWithoutTaxNumber() {
    return this.clientStepData.type === GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITHOUT_TAX_NUMBER;
  }

  get isIndividualWithTaxNumber() {
    return this.clientStepData.type === GroupTypeEnum.INDIVIDUAL_WITH_TAX_NUMBER;
  }

  constructor(
    public helpersService: HelpersService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private initStepService: InitStepService,
    private snackbar: MatSnackBar,
  ) {
    // set initial parent basic data
    super(helpersService, dialog);
    this.setText(
        'Kinek tartoznak? (Hitelező)',
        'Add meg, hogy kinek tartoznak'
    );
    this.urlName = 'client';
    this.isEditable = false;

    // TODO: set szamlazzhu specific data
    // this.showSzamlazzhuIcon = this.formHelperService.isSzamlazzhu;

    // set client step data
    this.clientStepData = this.initStepService.clientStepData;
    this.initStepService.getClientStepDataListener()
      .subscribe((clientStepData: ClientStepData) => {
        this.clientStepData = clientStepData;
      });
  }

  public deleteRepresentative() {
    this.form.setControl('representativeFormGroup', this.getRepresentativeFormGroup(getEmptyRepresentativeData()));
  }

  // override
  buildForm() {
    if (this.isHouseWithRepresentativeWithoutTaxNumber
      && !this.clientStepData.representative_data?.representative?.name) {
      this.clientStepData.type = GroupTypeEnum.HOUSE_WITH_REPRESENTATIVE_WITH_TAX_NUMBER;
    }
    this.form = this.getForm();
    this.setEditable();
  }

  // override
  setMenuTexts() {
    this.dropdownTitle = this.clientStepData.client_data?.client?.name ?? '';
    if (this.isIndividualWithTaxNumber) {
      this.dropdownTitle += `\n${this.additionalDataString}`;
    }
    this.dropdownItems = [`${this.clientStepData.client_data?.client?.name ?? ''}`];
  }

  // override
  openDialog() {
    return this.dialog.open(ClientDialogComponent, {
      position: {
        top: '50px',
      },
      autoFocus: true,
      disableClose: true,
    });
  }

  // override
  async persistData() {
    this.updateClientStepData();

    try {
      await this.initStepService.saveClientStepData();
    } catch (error) {
      console.error(error);
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
    }
  }

  private getForm() {
    const clientData = this.clientStepData.client_data;
    const representativeData = this.clientStepData.representative_data;

    return this.formBuilder.group({
      groupTypeFormControl: [this.clientStepData.type ?? GroupTypeEnum.OTHER_WITH_TAX_NUMBER],
      clientFormGroup: this.getClientFormGroup(clientData),
      representativeFormGroup: this.getRepresentativeFormGroup(representativeData)
    });
  }

  private getClientFormGroup(clientData: ClientData) {
    return this.formBuilder.group({
      taxNumberFormControl: [
        clientData.client?.tax_number ?? '',
        [this.isHouseClient ? Validators.required : Validators.nullValidator]
      ],
      registrationNumberFormControl: [
        clientData.client?.registration_number ?? '',
        []
      ],
      nameFormControl: [
        clientData.client?.name ?? '',
        [Validators.required]
      ],
      countryFormControl: [
        {
          value: 'Magyarország',
          disabled: true,
        },
        [Validators.required]
      ],
      postcodeFormControl: [
        clientData.address?.postcode ?? '',
        [Validators.required]
      ],
      settlementFormControl: [
        clientData.address?.settlement ?? '',
        [Validators.required]
      ],
      streetFormControl: [
        clientData.address?.street ?? '',
        [Validators.required]
      ],
      motherNameFormControl: [
        clientData.client?.mother_name ?? '',
        [this.isIndividualWithTaxNumber ? Validators.required : Validators.nullValidator]
      ],
      birthDateFormControl: [
        clientData.client.birth_date ? moment(clientData.client.birth_date,'YYYY-MM-DD') : null,
        [this.isIndividualWithTaxNumber ? Validators.required : Validators.nullValidator]
      ],
      birthPlaceFormControl: [
        clientData.client?.birth_place ?? '',
        [this.isIndividualWithTaxNumber ? Validators.required : Validators.nullValidator]
      ],
    });
  }

  private getRepresentativeFormGroup(representativeData: RepresentativeData) {
    return this.formBuilder.group({
      repNameFormControl: [
        representativeData?.representative?.name ?? '',
        [this.isHouseClient ? Validators.required : Validators.nullValidator]
      ],
      repTypeFormControl: [{
        value: 'Közös képviselő',
        disabled: true,
      }, [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]],
      repTaxNumberFormControl: [
        representativeData.representative.tax_number ?? '',
        []
      ],
      repCountryFormControl: [{
        value: representativeData?.address?.country ?? 'Magyarország',
        disabled: true,
      }, [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]],
      repPostcodeFormControl: [
        representativeData?.address?.postcode ?? '',
        [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]
      ],
      repSettlementFormControl: [
        representativeData?.address?.settlement ?? '',
        [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]
      ],
      repStreetFormControl: [
        representativeData?.address?.street ?? '',
        [this.isHouseWithRepresentativeWithoutTaxNumber ? Validators.required : Validators.nullValidator]
      ],
    });
  }

  private updateClientStepData() {
    this.clientStepData.type = this.groupTypeFormControl.value ?? GroupTypeEnum.OTHER_WITH_TAX_NUMBER;
    this.clientStepData.client_data.client.name = this.nameFormControl.value ?? '';
    this.clientStepData.client_data.client.tax_number = this.taxNumberFormControl.value ?? '';
    this.clientStepData.client_data.client.registration_number = this.registrationNumberFormControl.value ?? '';
    this.clientStepData.client_data.client.mother_name = this.motherNameFormControl.value ?? '';
    this.clientStepData.client_data.client.birth_place = this.birthPlaceFormControl.value ?? '';
    this.clientStepData.client_data.client.birth_date = this.birthDateFormControl.value?.format('YYYY-MM-DD') ?? '';

    this.clientStepData.client_data.address.country = this.countryFormControl.value ?? '';
    this.clientStepData.client_data.address.postcode = this.postcodeFormControl.value ?? '';
    this.clientStepData.client_data.address.settlement = this.settlementFormControl.value ?? '';
    this.clientStepData.client_data.address.street = this.streetFormControl.value ?? '';

    this.clientStepData.representative_data.representative.name = this.repNameFormControl.value ?? '';
    this.clientStepData.representative_data.representative.tax_number = this.repTaxNumberFormControl.value ?? '';

    this.clientStepData.representative_data.address.country = this.repCountryFormControl.value ?? '';
    this.clientStepData.representative_data.address.postcode = this.repPostcodeFormControl.value ?? '';
    this.clientStepData.representative_data.address.settlement = this.repSettlementFormControl.value ?? '';
    this.clientStepData.representative_data.address.street = this.repStreetFormControl.value ?? '';
  }

  private setEditable() {
    if (this.form.invalid) {
      this.isEditable = true;
    }

    if (this.countryFormControl.invalid
      || this.postcodeFormControl.invalid
      || this.settlementFormControl.invalid
      || this.streetFormControl.invalid
    ) {
      this.isAddressEditable = true;
    }

    if (this.birthDateFormControl.invalid
      || this.birthPlaceFormControl.invalid
      || this.motherNameFormControl.invalid)
    {
      this.isAdditionalDataEditable = true;
    }
  }
}
