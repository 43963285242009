import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AxiosError } from 'axios';
import moment from 'moment';
import { PwcInvoicesTableData, PwcService } from 'src/app/services/pwc/pwc.service';

@Component({
  selector: 'app-create-case-dialog',
  templateUrl: './create-case-dialog.component.html',
  styleUrls: ['./create-case-dialog.component.scss']
})
export class CreateCaseDialogComponent {
  type: 'notice' | 'demand_letter' | 'hard_1' = 'notice';

  loading = false;

  readonly demandLetterWarningDate: string;
  readonly showDemandLetterWarning: boolean;

  constructor(
    private pwcService: PwcService,
    private snackbar: MatSnackBar,
    public dialogRef: MatDialogRef<CreateCaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private invoice: PwcInvoicesTableData,
  ) {
    this.showDemandLetterWarning = this.pwcService.showDemandLetterWarning(this.invoice.due_date_at);
    this.demandLetterWarningDate = this.pwcService.getDemandLetterWarningDate(this.invoice.due_date_at).format('YYYY.MM.DD.');
  }

  async createCase(): Promise<void> {
    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      await this.pwcService.createCase({
        product_type: this.type,
      }, this.invoice.ort_id);
      this.dialogRef.close();
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data?.code === 'customer-company-not-found') {
          this.snackbar.open(
            'Az adós nem szerepel a cégadatbázisban, próbálja meg később.',
            'OK',
            { duration: 5000 },
          );
          this.invoice.can_create_payee_case = 'invalid-debtor-tax-number';
          this.dialogRef.close();
          return;
        }
      }
      console.error(error);
      this.snackbar.open('Valami hiba történt', 'OK', { duration: 5000 });
    } finally {
      this.loading = false;
    }
  }

  onSelectionChange(type: typeof this.type): void {
    this.type = type;
  }

  readonly onCancel = (): void => {
    this.dialogRef.close();
  };
}
