<payee-popup
  [title]="dialogData?.transition_label"
  [onCancel]="onCancel"
>
  <ng-template #payeePopupTitleTemplate>
    <h1 class="title">{{ dialogData?.transition_label }}</h1>
  </ng-template>
  <div class="subtitle">Belső megjegyzés</div>

  <form [formGroup]="form">
    <payee-form-field
      width="wide"
      height="taller"
    >
      <textarea
        payee-input
        placeholder="Fejtse ki részletesen..."
        formControlName="message"
      ></textarea>
    </payee-form-field>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      [disabled]="form.invalid"
      payee-button
      width="wide"
      (click)="onSubmit()"
    >
      Rögzítés
    </button>
  </ng-template>
</payee-popup>
