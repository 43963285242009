import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SzamlazzhuSsoApiService } from "../../../services/szamlazzhu/szamlazzhu-sso-api.service";

@Component({
	selector: 'app-szamlazzhu-sso',
	templateUrl: './szamlazzhu-sso.component.html',
	styleUrls: ['./szamlazzhu-sso.component.scss']
})
export class SzamlazzhuSsoComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private szamlazzhuSsoApiService: SzamlazzhuSsoApiService,
	) { }

	async ngOnInit() {
		let ssoToken = this.route.snapshot.params['ssotoken'];

		if (!ssoToken) {
			ssoToken = await this.szamlazzhuSsoApiService.getSsoToken();
		}

		await this.szamlazzhuSsoApiService.redirectToSzamlazzhuLogin(ssoToken);
	}

}
