import { Injectable } from "@angular/core";
import { environment } from "../../../../../environments/environment";
import { ClaimsAndLegalBaseStepData } from "../models/claims-and-legal-relationship/claims-and-legal-base-step-data";
import { BankAccountStepData } from "../models/bank-account/bank-account-step-data";
import { CommunicationStepData } from "../models/communication/communication-step-data";
import { FormTypeEnum } from "../models/common/form-type-enum";
import { DebtorStepData } from "../models/debtor/debtor-step-data";
import { ClientStepData } from "../models/client/client-step-data";
import { Observable, Subject } from "rxjs";
import { GroupTypeEnum } from "../models/common/group-type-enum";
import { DebtorData } from "../models/debtor/debtor-data";
import { Address } from "../models/common/address";
import { Debtor } from "../models/debtor/debtor";
import { RepresentativeData } from "../models/common/representative-data";
import { Representative } from "../models/common/representative";
import { GetInitDataDto } from "../models/common/get-init-data-dto";
import { ClientData } from "../models/client/client-data";
import { Client } from "../models/client/client";
import { getEmptyClaim } from "../models/claims-and-legal-relationship/claim";
import { TransformFormDataService } from "./transform-form-data.service";
import { PayeeFile } from "../models/common/payee-file";
import { ToneOfVoiceEnum } from '../models/communication/tone-of-voice-enum';
import { ClientsService } from "src/app/services/clients/clients.service";
import axios, { AxiosError } from 'axios';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class InitStepService {
  private _debtorStepData: DebtorStepData;
  private _debtorStepDataListener = new Subject<DebtorStepData>();

  private _claimsAndLegalBaseStepData: ClaimsAndLegalBaseStepData;
  private _claimsAndLegalBaseStepDataListener = new Subject<ClaimsAndLegalBaseStepData>();

  private _communicationStepData: CommunicationStepData;
  private _communicationStepDataListener = new Subject<CommunicationStepData>();

  private _clientStepData: ClientStepData;
  private _clientStepDataListener = new Subject<ClientStepData>();

  private _bankAccountStepData: BankAccountStepData;
  private _bankAccountStepDataListener = new Subject<BankAccountStepData>();

  private _formType: FormTypeEnum;
  private _caseUuid = '';
  private _payeeId = '';

  get debtorStepData() {
    return this._debtorStepData;
  }

  get claimsAndLegalBaseStepData() {
    return this._claimsAndLegalBaseStepData;
  }

  get communicationStepData() {
    return this._communicationStepData;
  }

  get clientStepData() {
    return this._clientStepData;
  }

  get bankAccountStepData() {
    return this._bankAccountStepData;
  }

  get payeeId() {
    return this._payeeId;
  }

  get formType() {
    return this._formType;
  }

  constructor(
    private clientsService: ClientsService,
    private transformFormDataService: TransformFormDataService,
    private router: Router,
  ) {
  }

  getDebtorStepDataListener(): Observable<DebtorStepData> {
    return this._debtorStepDataListener.asObservable();
  }

  getCommunicationStepDataListener(): Observable<CommunicationStepData> {
    return this._communicationStepDataListener.asObservable();
  }

  getClaimsAndLegalBaseStepDataListener(): Observable<ClaimsAndLegalBaseStepData> {
    return this._claimsAndLegalBaseStepDataListener.asObservable();
  }

  getClientStepDataListener(): Observable<ClientStepData> {
    return this._clientStepDataListener.asObservable();
  }

  getBankAccountStepDataListener(): Observable<BankAccountStepData> {
    return this._bankAccountStepDataListener.asObservable();
  }

  getCaseUuid() {
    return this._caseUuid;
  }

  setCaseUuid(caseUuid: string) {
    this._caseUuid = caseUuid;
  }

  async setInitStepData(): Promise<{ redirecting: boolean; }> {
    try {
      const url = `${environment.baseUrl}/api/payee-case/${this._caseUuid}/init`;
      const { data } = await axios.get(url);
      this.setAllData(data.init_form_data);
      return {
        redirecting: false,
      };
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 403 && 'payee_case_reference_id' in error.response.data) {
          await this.router.navigate(['/user/cases'], {
            queryParams: {
              filterType: 'payeeId',
              filter: error.response.data.payee_case_reference_id,
            },
          });
          return {
            redirecting: true,
          };
        }
      }
      console.error('Error while loading init step data', error);
      throw error;
    }
  }

  async saveDebtorStepData() {
    const partialDebtorStepData = this.getReadyToSaveDebtorStepData();

    const url = `${environment.baseUrl}/api/payee-case/${this._caseUuid}/debtor`;
    const { data } = await axios.put(url, partialDebtorStepData);
    this.setDebtorStepData(data.debtor_step);
  }

  async saveCommunicationStepData() {
    const url = `${environment.baseUrl}/api/payee-case/${this._caseUuid}/communication-data`;
    const { data } = await axios.put(url, this.communicationStepData);
    this.setCommunicationStepData(data.communication_step);
  }

  async saveClaimsAndLegalRelationshipStepData() {
    const formData = this.transformFormDataService.objectToFormData(this.claimsAndLegalBaseStepData);
    // Must have Laravel hack, do not delete (transforms post request to put request)
    formData.append('_method', 'PUT');

    const url = `${environment.baseUrl}/api/payee-case/${this._caseUuid}/claims-and-legal-base`;
    const { data } = await axios.post(url, formData);
    this.setClaimsAndLegalBaseStepData(data.claims_and_legal_base_step);
  }

  async saveClientStepData() {
    const partialClientStepData = this.getReadyToSaveClientStepData();

    const url = `${environment.baseUrl}/api/payee-case/${this._caseUuid}/client`;
    const { data } = await axios.put(url, partialClientStepData);
    this.setClientStepData(data.client_step);
  }

  async saveBankAccountStepData(): Promise<void> {
    const data = await this.clientsService.updateBankAccount(this.bankAccountStepData);
    this.setBankAccountStepData(data);
  }

  setAllData(data: GetInitDataDto) {
    this._formType = data.type;
    this._payeeId = data.payee_id;

    this.setDebtorStepData(data.debtor_step);
    this.setClaimsAndLegalBaseStepData(data.claims_and_legal_base_step);
    this.setCommunicationStepData(data.communication_step);
    this.setClientStepData(data.client_step);
    this.setBankAccountStepData(data.bank_account_step);
  }

  setDebtorStepData(debtorStepData: DebtorStepData) {
    this._debtorStepData = {
      type: debtorStepData.type ?? GroupTypeEnum.OTHER_WITH_TAX_NUMBER,
      debtor_data: {
        debtor: {
          name: debtorStepData.debtor_data?.debtor?.name ?? '',
          tax_number: debtorStepData.debtor_data?.debtor?.tax_number ?? '',
          registration_number: debtorStepData.debtor_data?.debtor?.registration_number ?? '',
        } as Debtor,
        address: {
          country: debtorStepData.debtor_data?.address?.country ?? 'Magyarország',
          settlement: debtorStepData.debtor_data?.address?.settlement ?? '',
          postcode: debtorStepData.debtor_data?.address?.postcode ?? '',
          street: debtorStepData.debtor_data?.address?.street ?? '',
        } as Address,
      } as DebtorData,
      representative_data: {
        representative: {
          name: debtorStepData.representative_data?.representative?.name ?? '',
          tax_number: debtorStepData.representative_data?.representative?.tax_number ?? ''
        } as Representative,
        address: {
          country: debtorStepData.representative_data?.address?.country ?? 'Magyarország',
          settlement: debtorStepData.representative_data?.address?.settlement ?? '',
          postcode: debtorStepData.representative_data?.address?.postcode ?? '',
          street: debtorStepData.representative_data?.address?.street ?? '',
        } as Address,
      } as RepresentativeData,
    };

    this._debtorStepDataListener.next(this.debtorStepData);
  }

  setCommunicationStepData(communicationStepData: CommunicationStepData) {
    this._communicationStepData = {
      email_address: communicationStepData?.email_address ?? '',
      phone_number: communicationStepData?.phone_number ?? '',
      description: communicationStepData?.description ?? '',
      country_iso: communicationStepData?.country_iso ?? 'HU',
      tone_of_voice: communicationStepData?.tone_of_voice ?? ToneOfVoiceEnum.FORMAL,
      is_payment_plan_allowed: communicationStepData?.is_payment_plan_allowed ?? false,
    };

    this._communicationStepDataListener.next(this.communicationStepData);
  }

  setClaimsAndLegalBaseStepData(claimsAndLegalBaseStepData: ClaimsAndLegalBaseStepData) {
    // set basic data
    this._claimsAndLegalBaseStepData = {
      claims: [],
      legal_base: {
        is_common_cost_balance: claimsAndLegalBaseStepData.legal_base.is_common_cost_balance,
        legal_relationship_explanation: claimsAndLegalBaseStepData.legal_base.legal_relationship_explanation,
        legal_relationship_explanation_files: [],
      }
    };

    // set claims
    for (const claim of claimsAndLegalBaseStepData.claims) {
      this._claimsAndLegalBaseStepData.claims.push({
        uuid: claim.uuid,
        amount: claim.amount,
        due_date_at: claim.due_date_at,
        file: {
          uuid: claim.file?.uuid ?? null,
          file_name: claim.file?.file_name ?? '',
          file: null,
        } as PayeeFile,
        ref_id: claim.ref_id,
      });
    }

    // set empty claim
    if (this._claimsAndLegalBaseStepData.claims.length === 0) {
      this._claimsAndLegalBaseStepData.claims = [getEmptyClaim()];
    }

    // set legal relationship explanation files
    for (const file of claimsAndLegalBaseStepData.legal_base.legal_relationship_explanation_files) {
      this.claimsAndLegalBaseStepData.legal_base.legal_relationship_explanation_files.push({
        uuid: file.uuid,
        file_name: file.file_name,
        file: null,
      } as PayeeFile);
    }

    this._claimsAndLegalBaseStepDataListener.next(this.claimsAndLegalBaseStepData);
  }

  setClientStepData(clientStepData: ClientStepData) {
    this._clientStepData = {
      type: clientStepData.type ?? GroupTypeEnum.OTHER_WITH_TAX_NUMBER,
      client_data: {
        client: {
          name: clientStepData.client_data?.client?.name ?? '',
          tax_number: clientStepData.client_data?.client?.tax_number ?? '',
          registration_number: clientStepData.client_data?.client?.registration_number ?? '',
          mother_name: clientStepData.client_data?.client?.mother_name ?? '',
          birth_date: clientStepData.client_data?.client?.birth_date ?? '',
          birth_place: clientStepData.client_data?.client?.birth_place ?? '',
        } as Client,
        address: {
          country: clientStepData.client_data?.address?.country ?? 'Magyarország',
          settlement: clientStepData.client_data?.address?.settlement ?? '',
          postcode: clientStepData.client_data?.address?.postcode ?? '',
          street: clientStepData.client_data?.address?.street ?? '',
        } as Address,
      } as ClientData,
      representative_data: {
        representative: {
          name: clientStepData.representative_data?.representative?.name ?? '',
          tax_number: clientStepData.representative_data?.representative?.tax_number ?? ''
        } as Representative,
        address: {
          country: clientStepData.representative_data?.address?.country ?? 'Magyarország',
          settlement: clientStepData.representative_data?.address?.settlement ?? '',
          postcode: clientStepData.representative_data?.address?.postcode ?? '',
          street: clientStepData.representative_data?.address?.street ?? '',
        } as Address,
      } as RepresentativeData,
    } as ClientStepData;

    this._clientStepDataListener.next(this.clientStepData);
  }

  setBankAccountStepData(bankAccountStepData: BankAccountStepData) {
    this._bankAccountStepData = {
      account_holder_name: bankAccountStepData?.account_holder_name ?? '',
      account_number: bankAccountStepData?.account_number ?? '',
    } as BankAccountStepData;

    this._bankAccountStepDataListener.next(this.bankAccountStepData);
  }

  private getReadyToSaveDebtorStepData(): Partial<DebtorStepData> {
    switch (this.debtorStepData.type) {
      case GroupTypeEnum.OTHER_WITH_TAX_NUMBER: {
        const { debtor_data, type } = this.debtorStepData;
        return { debtor_data, type };
      }
      case GroupTypeEnum.INDIVIDUAL_WITHOUT_TAX_NUMBER: {
        const { debtor_data, type } = this.debtorStepData;
        return { debtor_data, type };
      }
      default: {
        return this.debtorStepData;
      }
    }
  }

  private getReadyToSaveClientStepData(): Partial<ClientStepData> {
    switch (this.clientStepData.type) {
      case GroupTypeEnum.INDIVIDUAL_WITH_TAX_NUMBER: {
        const { client_data, type } = this.clientStepData;
        return { client_data, type };
      }
      case GroupTypeEnum.OTHER_WITH_TAX_NUMBER: {
        const { client_data, type } = this.clientStepData;
        return { client_data, type };
      }
      default: {
        return this.clientStepData;
      }
    }
  }
}
