import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { isValidNumber } from 'libphonenumber-js';
import * as ibantools from 'ibantools';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  markAllChildrenAsDirty(form: FormArray | FormGroup | FormControl | AbstractControl) {
    if (!form) {
      return;
    }
    if (form instanceof FormArray) {
      form.controls.forEach(control => this.markAllChildrenAsDirty(control));
      return;
    }
    if (form instanceof FormGroup) {
      Object.values(form.controls).forEach(control => this.markAllChildrenAsDirty(control));
      return;
    }
    form.markAsDirty();
  }

  markAllChildrenAsPristine(form: FormArray | FormGroup | FormControl | AbstractControl) {
    if (!form) {
      return;
    }
    if (form instanceof FormArray) {
      form.controls.forEach(control => this.markAllChildrenAsPristine(control));
      return;
    }
    if (form instanceof FormGroup) {
      Object.values(form.controls).forEach(control => this.markAllChildrenAsPristine(control));
      return;
    }
    form.markAsPristine();
  }

  bankAccountValidator(control: AbstractControl) {
    const value = control.value;
    const electronicFormatted = ibantools.electronicFormatIBAN(value);
    if (!ibantools.isValidBBAN(electronicFormatted, 'HU') && !ibantools.isValidIBAN(electronicFormatted)) {
      return {
        pattern: true,
      };
    }

    return null;
  }

  BBANValidator(control: AbstractControl) {
    let value = control.value;
    if (value?.length === 17) { // including dash
      value += '00000000';
    }
    const electronicFormatted = ibantools.electronicFormatIBAN(value);
    if (!ibantools.isValidBBAN(electronicFormatted, 'HU')) {
      return {
        pattern: true,
      };
    }

    return null;
  }

  getBBANFormat(value: string) {
    if (!value) {
      return '';
    }

    return value
      .replace(/\D/g, '')
      .slice(0, 24)
      .match(/.{1,8}/g)
      .join('-');
  }

  getHUFAmountFormat(value: number) {
    if (!value) {
      return '';
    }

    const numberOnly = value.toString().replace(/\D/g, '');
    const formattedValue = numberOnly.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');

    return formattedValue;
  }

  formatNumbers(control: AbstractControl) {
    const value = control.value;

    if (typeof value !== 'string') {
      return;
    }

    const formattedValue = value.replace(/\D/g, '').slice(0, 12);

    if (formattedValue === value) {
      return;
    }
    control.patchValue(formattedValue, { emitEvent: true });
  }

  formatLetters(control: AbstractControl) {
    const value = control.value;

    const formattedValue = value.replace(/[^a-zA-ZÁÉÍÓÖŐÚÜŰáéíóöőúüű]+$/, '');

    if (formattedValue === value) {
      return;
    }

    control.patchValue(formattedValue, { emitEvent: false });
  }

  formatBankAccount(control: AbstractControl) {
    const value = control.value as string;
    if (/^\d{8}-\d{8}-\d{1,}$/g.test(value)) {
      // Already 3 section long bankAccount
      control.setValue(value.substr(0, 26));
    } else if (/^\d{9,}$/g.test(value) || /^\d{8}-\d{9,}$/g.test(value)) {
      // Reached 2nd or 3rd section
      const tmp = value.replace(/\D/g, '').substr(0, 24);
      const pieces = [];
      for (let i = 0; i < tmp.length; i += 8) {
        pieces.push(tmp.substr(i, 8));
      }
      control.setValue(pieces.join('-'));
    }
    // else we don't know the format of the bank account
  }

  phoneNumberValidatorWithPrefix(prefix: AbstractControl | string) {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const prefixString = typeof prefix === 'string' ? prefix : prefix.value;
      const value = prefixString + control.value;

      if (isValidNumber(value)) {
        return null;
      }
      return { invalidPhoneNumber: true };
    }
  }

  phoneNumberValidator(control: AbstractControl) {
    if (!control.value || isValidNumber(control.value)) {
      return null;
    }
    return { invalidPhoneNumber: true };
  }
}
