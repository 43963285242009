<payee-popup title="Biztosan törli a piszkozatot?">
  <div class="img-box flex vcenter hcenter">
    <img
      src="assets/shared/global-images/icons/trash-grey.svg"
      height="71.43px"
    />
  </div>
  <ng-template #payeePopupSubmitTemplate>
    <button
      payee-button
      color="alert-red"
      width="wide"
      height="tall"
      (click)="submit()"
    >
      <ng-container>Törlés</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button cancel-button"
      payee-button
      [isBorderButton]="true"
      [mat-dialog-close]="false"
      color="white"
      width="wide"
      height="tall"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
