<div class="header flex vcenter">
  <img src="assets/shared/global-images/payee-logos/v1/black-payee-blue-dot.svg" width="110px" height="30.05px" class="logo">
  <div class="header-title flex grow1 column vcenter">
  </div>
  <div class="close-box flex vcenter">
    <div class="vertical-line"></div>
    <a routerLink="/login"><img src="assets/shared/global-images/icons/cross-blue.svg"></a>
  </div>
</div>

<mat-divider></mat-divider>

<div class="content flex column vcenter">
  <img src="assets/shared/global-images/icons/checkmark-circle-green-big.svg" class="checkmark" width="100px" height="100px">
  <div class="title">Jelenleg vendég vagy. Regisztrálsz egy Payee felhasználót?</div>
  <div class="description">
    Ahhoz, hogy a korábban indított ügyeidet egy helyen láthasd,
    érdemes létrehoznod egy felhasználót. Ehhez add meg a <strong>nevedet</strong>
    és egy <strong>jelszót</strong> is..
  </div>

  <form [formGroup]="registerForm" class="register-form" (ngSubmit)="register()">
    <app-custom-input-field [error]="lastName.invalid && lastName.dirty">
      <div custom-label [class.custom-label-top]="!!lastNameInput.value">Vezetékneved</div>
      <input custom-input #lastNameInput formControlName="lastName">
      <mat-error custom-error *ngIf="lastName.hasError('required') && lastName.dirty">
        A mezőt kötelező kitölteni!
      </mat-error>
    </app-custom-input-field>

    <app-custom-input-field [error]="firstName.invalid && firstName.dirty">
      <div custom-label [class.custom-label-top]="!!firstNameInput.value">Utóneved</div>
      <input custom-input #firstNameInput formControlName="firstName">
      <mat-error custom-error *ngIf="firstName.hasError('required') && firstName.dirty">
        A mezőt kötelező kitölteni!
      </mat-error>
    </app-custom-input-field>

    <app-custom-input-field [error]="password.invalid && password.dirty">
      <div custom-label [class.custom-label-top]="!!passwordInput.value">Jelszavad</div>
      <input custom-input #passwordInput formControlName="password" type="password">
      <mat-error custom-error *ngIf="password.hasError('required') && password.dirty">
        A mezőt kötelező kitölteni!
      </mat-error>
      <mat-error custom-error *ngIf="password.hasError('minlength') && password.dirty">
        A jelszónak legalább 6 karakternek kell lennie!
      </mat-error>
    </app-custom-input-field>

    <app-custom-input-field [error]="passwordConfirm.invalid && passwordConfirm.dirty">
      <div custom-label [class.custom-label-top]="!!passwordConfirmInput.value">Jelszavad újra</div>
      <input custom-input #passwordConfirmInput formControlName="passwordConfirm" type="password">
      <mat-error custom-error *ngIf="passwordConfirm.hasError('required') && passwordConfirm.dirty">
        A mezőt kötelező kitölteni!
      </mat-error>
      <mat-error custom-error *ngIf="registerForm.hasError('passwordsNotMatching') && !passwordConfirm.hasError('required')">
        A jelszavaknak egyezniük kell!
      </mat-error>
    </app-custom-input-field>

    <button mat-button class="submit-button" [disabled]="registerForm.invalid" *ngIf="!loading">Payee felhasználó létrehozása</button>
  </form>

  <ng-container *ngIf="loading">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="100" class="loading-spinner"></mat-progress-spinner>
  </ng-container>

  <ng-container *ngIf="!loading">
    <button mat-button class="cancel-button" routerLink="/login">Majd máskor</button>

    <button mat-button class="start-new-case-button" (click)="startNewCase()">Új ügy indítása</button>
  </ng-container>

  <div class="attorney-partner flex vcenter">
    <div class="title">Ügyvédi partnerünk:</div>
    <img src="assets/shared/global-images/partner-logos/meyer-levinson.png" width="246px" height="85px">
  </div>
</div>