import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingDialogService } from 'src/app/services/loading-dialog/loading-dialog.service';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';

export type DialogData = {
  transition_ids: {
    accepted: string;
    denied: string;
  };
};

@Component({
  selector: 'app-full-payment-statement',
  templateUrl: './full-payment-statement.component.html',
  styleUrls: ['./full-payment-statement.component.scss']
})
export class FullPaymentStatementComponent implements OnInit {
  dialogData?: DialogData;

  constructor(
    private dialogRef: MatDialogRef<FullPaymentStatementComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private workflowService: WorkflowService,
  ) { }

  async ngOnInit() {
    this.dialogData = await this.workflowService.getDialogData({
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    }) as DialogData;
  }

  readonly submit = async (accepted: boolean) => {
    if (!this.dialogData) {
      return;
    }

    const transitionId = accepted ?
      this.dialogData.transition_ids.accepted
      : this.dialogData.transition_ids.denied;

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId,
    };

    this.dialogRef.close(closeValue);
  };
}
