<div class="step-container" [class.active-step]="formService.isActiveStep()">
  <!--left column-->
  <div class="left-column">
    <!--title of the step-->
    <div>
      <!--title string-->
      <span class="new-title">{{ formService.title }}</span>
      <!--dropdown arrow-->
      <span *ngIf="formService.dropdownItems.length > 1">
        <span *ngIf="isOpen" (click)="isOpen = false">
          <payee-icon class="arrow-icon" name="arrow-up-black"></payee-icon>
        </span>
        <span *ngIf="!isOpen" (click)="isOpen = true">
          <payee-icon class="arrow-icon" name="arrow-down-black"></payee-icon>
        </span>
      </span>
    </div>

    <!--description-->
    <div
      *ngIf="!formService.isActiveStep() && !formService.form.valid"
      class="new-description top-margin-5"
    >
      {{ formService.description }}
    </div>

    <!--set data button-->
    <div *ngIf="formService.isActiveStep()" class="set-data-button">
      <button payee-button (click)="onButtonClick()">
        Adja meg az adatokat
      </button>
    </div>

    <!--if some data is already set-->
    <div
      *ngIf="
        (formService.form.dirty || formService.form.valid) &&
        !formService.isActiveStep()
      "
    >
      <div *ngIf="!isOpen && formService.form.valid">
        <div class="new-description top-margin-5">
          {{ formService.dropdownTitle }}
        </div>
      </div>
      <div *ngIf="isOpen && formService.form.valid">
        <div *ngFor="let item of formService.dropdownItems">
          <div class="new-description top-margin-5">{{ item }}</div>
        </div>
      </div>
      <!--edit cta-->
      <div
        *ngIf="formService.form.enabled"
        class="blue-cta top-margin-5"
        (click)="onButtonClick()"
      >
        <span *ngIf="!formService.isEditable">Megtekintés</span>
        <span *ngIf="formService.isEditable">Szerkesztés</span>
      </div>
    </div>
  </div>

  <!--right column-->
  <div class="right-column">
    <div *ngIf="formService.form.valid" class="icons-container">
      <div class="icons">
        <payee-icon
          *ngIf="formService.showSzamlazzhuIcon"
          name="szamlazzhu-icon"
          matTooltipClass="szamlazzhu-icon-tooltip"
          matTooltip="Ezt az adatot előtöltöttük neked a Számlázz.hu-ból"
          matTooltipPosition="above"
        ></payee-icon>
        <payee-icon
          [name]="environment.CHECKMARK_GREEN_CIRCLE_ICON"
        ></payee-icon>
      </div>
    </div>
  </div>
</div>
