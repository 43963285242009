<form class="flex bank-account-box" [formGroup]="bankAccountForm">
  <payee-form-field width="wide">
    <input payee-input [formControl]="accountHolderName" />
    <span payee-label>Kedvezményezett neve</span>
    <mat-error
      payee-error
      *ngIf="accountHolderName.dirty && !accountHolderName.value"
      >A mező kitöltése kötelező</mat-error
    >
  </payee-form-field>
  <payee-form-field width="wide">
    <input
      payee-input
      [formControl]="accountNumber"
      (input)="formatBankAccount(accountNumber)"
    />
    <span payee-label>Bankszámlaszám/IBAN</span>
    <mat-error
      payee-error
      *ngIf="accountNumber.dirty && accountNumber.hasError('required')"
    >
      A mező kitöltése kötelező
    </mat-error>
    <mat-error
      payee-error
      *ngIf="accountNumber.value && accountNumber.errors?.pattern"
    >
      Helytelen formátum.
    </mat-error>
  </payee-form-field>
  <payee-form-field width="wide">
    <input payee-input [formControl]="bankName" />
    <span payee-label>Számlavezető bank neve</span>
    <mat-error
      payee-error
      *ngIf="bankName.dirty && bankName.hasError('required')"
    >
      A mező kitöltése kötelező
    </mat-error>
  </payee-form-field>
</form>

<div class="save-icon flex vcenter" [class.show]="saveCount > 0">
  <img src="assets/shared/global-images/icons/dot-green.svg" />
  <span>Automatikusan mentve</span>
</div>
