import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
	ControlValueAccessor,
	FormBuilder,
	FormControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'payee-autocomplete',
	templateUrl: './payee-autocomplete.component.html',
	styleUrls: ['./payee-autocomplete.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => PayeeAutocompleteComponent),
		multi: true,
	}],
})
export class PayeeAutocompleteComponent implements OnInit, OnDestroy, ControlValueAccessor {
	searchControl: FormControl;
	private selected: any;

	private readonly subscriptionKiller = new Subject<void>();

	@Input() placeholder = '';

	@Input() displayWith: (data: any) => string = data => data;

	@Input() filterPredicate: (data: any, filter: string) => boolean = (data, filter) => {
		if (typeof filter !== 'string') {
			return true;
		}
		const displayed = this.displayWith(data);
		if (typeof displayed === 'string') {
			return displayed.toLowerCase().includes(filter.toLowerCase());
		}
		return JSON.stringify(data).toLowerCase().includes(filter.toLowerCase());
	};

	private _options: any[] = [];
	@Input() set options(options: any[]) {
		this._options = options;
		this.filteredOptions = options.filter(option => this.filterPredicate(option, this.searchControl.value));
	}
	get options() { return this._options; }
	filteredOptions: any[] = [];

	constructor(
		private fb: FormBuilder,
	) {
		this.searchControl = this.fb.control('');
	}

	ngOnInit(): void {
		this.searchControl.valueChanges
			.pipe(takeUntil(this.subscriptionKiller))
			.subscribe({
				next: change => {
					this.filteredOptions = this.options.filter(option => this.filterPredicate(option, change));
				},
			});
	}

	ngOnDestroy(): void {
		this.subscriptionKiller.next();
		this.subscriptionKiller.complete();
	}

	// ControlValueAccessor

	onChange: (obj: any) => void = () => {};

	onTouched: () => void = () => {};

	writeValue(obj: any): void {
		this.selected = obj;
		this.fillSearch();
	}

	registerOnChange(fn: (obj: any) => void): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		if (isDisabled) {
			this.searchControl.disable();
		} else {
			this.searchControl.enable();
		}
	}

	optionSelected(event: MatAutocompleteSelectedEvent): void {
		const value = event.option.value;
		this.selected = value;
		this.fillSearch();
		this.onChange(value);
	}

	fillSearch() {
		this.searchControl.setValue(this.displayWith(this.selected));
	}
}
