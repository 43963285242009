import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-legal-base-title',
  templateUrl: './legal-base-title.component.html',
  styleUrls: ['./legal-base-title.component.scss']
})
export class LegalBaseTitleComponent {
  @Input() text = '';

  constructor() { }
}
