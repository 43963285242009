import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import {FormType} from "./type-selector/type-selector.component";
import { CasesService } from 'src/app/services/cases/cases.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-form-selector',
  templateUrl: './form-selector.component.html',
  styleUrls: ['./form-selector.component.scss']
})
export class FormSelectorComponent implements OnInit {
  type: string = FormType.SOFT;
  isSzamlazzhu = false;
  loading = false;
  szamlazzhuUrl: string;

  constructor(
    private authService: AuthService,
    private casesService: CasesService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) { }

  async ngOnInit() {
    throw new Error('Unimplemented');
    // this.isSzamlazzhu = this.authService.origin === 'PLUGIN_SZAMLAZZHU';

    // const configuredUrl = await this.remoteConfig.getStringConfig('szamlazz_base_url', '');
    // if (!configuredUrl) {
    //   return '';
    // }
    // const url = new URL(configuredUrl);
    // return url.origin;
  }

  onSelectedTypeChange(type: string) {
    this.type = type;
  }

  async onNextButtonClick() {
    if (this.loading) {
      return;
    }

    try {
      this.loading = true;
      const result = await this.casesService.createCase({
        product_type: this.type,
      });
      await this.router.navigateByUrl(`case/${result.id}`);
    } catch (error) {
      console.log(error);
      this.snackbar.open('Valami hiba történt', 'OK', {
        duration: 10000,
      });
    } finally {
      this.loading = false;
    }
  }

  readonly environment = environment;
}
