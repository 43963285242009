import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-restore-forgot-password',
  templateUrl: './restore-forgot-password.component.html',
  styleUrls: ['./restore-forgot-password.component.scss']
})
export class RestoreForgotPasswordComponent {

  passwordChanged: boolean;
  passwordForm: FormControl;

  actionCode: string;
  apiKey: string;
  continueUrl: string;
  email: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    this.passwordForm = this.fb.control('', [Validators.required, Validators.minLength(6)]);
    this.titleService.setTitle('Új jelszó beállítása - Real-time Debt Management Tool');

    this.route.queryParams.subscribe(param => {
      this.continueUrl = param.continueUrl;
      this.actionCode = param.oobCode;
      this.apiKey = param.apiKey;

      const url = new URL(this.continueUrl);
      this.email = url.searchParams.get('email');
    });
  }

  async setNewPassword(): Promise<void> {
    throw new Error('Unimplemented');
    const pwValue = this.passwordForm.value;
    // try {
    //   // Verify the password reset code is valid.
    //   const accountEmail = await firebase.auth().verifyPasswordResetCode(this.actionCode);

    //   // TODO: Show the reset screen with the user's email and ask the user for
    //   // the new password.
    //   // Save the new password.
    //   await firebase.auth().confirmPasswordReset(this.actionCode, pwValue);
    //   // Password reset has been confirmed and new password updated.
    //   // TODO: Display a link back to the app, or sign-in the user directly
    //   // if the page belongs to the same domain as the app:
    //   await firebase.auth().signInWithEmailAndPassword(accountEmail, pwValue);
    //   this.passwordChanged = true;
    // } catch (error) {
    //   console.log(error);
    //   this.passwordForm.setErrors({error: true});
    //   return;
    // }
    this.router.navigate(['success-forgot-password']);
  }
}
