<div class="client-data-box-container">
  <div *ngIf="isClientEditing">
    <!--completed view only with address and tax number-->
    <div *ngIf="!clientFormService.isAddressEditable">
      <payee-completed-search-box
        [name]="clientFormService.nameFormControl.value"
        [address]="clientFormService.clientAddress"
        [taxNumber]="clientFormService.taxNumberFormControl.value"
        [isDeletable]="false"
        [disabled]="true">
      </payee-completed-search-box>
    </div>

    <!--company search field-->
    <div *ngIf="clientFormService.isAddressEditable" class="top-margin-30">
      <payee-company-search-input-field
        [nameFormControl]="clientFormService.nameFormControl"
        [isDeletable]="false"
        [isSearchDisabled]="true"
        [width]="'600px'"
        [height]="'68px'">
      </payee-company-search-input-field>
    </div>

    <app-form-house
      *ngIf="clientFormService.isHouseClient"
      [taxNumberFormControl]="clientFormService.taxNumberFormControl"
      [registrationNumberFormControl]="clientFormService.registrationNumberFormControl">
    </app-form-house>

    <div *ngIf="clientFormService.isAddressEditable">
      <!--address component title-->
      <div class="component-title top-margin-30">
        {{debtorFormService.debtorStepData.debtor_data.debtor.name}} kézbesítési címe
      </div>

      <!--address component-->
      <div class="top-margin-10">
        <app-form-address
          [countryFormControl]="clientFormService.countryFormControl"
          [postcodeFormControl]="clientFormService.postcodeFormControl"
          [cityFormControl]="clientFormService.settlementFormControl"
          [streetFormControl]="clientFormService.streetFormControl">
        </app-form-address>
      </div>
    </div>

    <div *ngIf="clientFormService.isAdditionalDataEditable">
      <!--other details data title-->
      <div class="component-title top-margin-30">
        {{clientFormService.nameFormControl.value}} további adatai
      </div>

      <!--other data component-->
      <div class="top-margin-10">
        <app-form-other-data
          [motherNameFormControl]="clientFormService.motherNameFormControl"
          [birthPlaceFormControl]="clientFormService.birthPlaceFormControl"
          [birthDateFormControl]="clientFormService.birthDateFormControl">
        </app-form-other-data>
      </div>
    </div>

    <div *ngIf="clientFormService.isHouseClient">
      <!--representative title-->
      <div class="component-title top-margin-30">
        {{clientFormService.nameFormControl.value}} képviselője
      </div>

      <!--representative search input field-->
      <div *ngIf="isRepEditing" class="top-margin-10">
        <payee-company-search-input-field
          [isHouseRep]="true"
          [nameFormControl]="clientFormService.repNameFormControl"
          [isDeletable]="true"
          [width]="'600px'"
          [height]="'68px'"
          [placeholder]="'Keress rá...'"
          [isSearchDisabled]="clientFormService.isHouseWithRepresentativeWithoutTaxNumber"
          (companySearchItemSelected)="onCompanySearchRepresentativeSelected($event)"
          (individualSelected)="onIndividualRepresentativeSelected($event)"
          (delete)="onRepresentativeDelete()">
        </payee-company-search-input-field>
      </div>

      <!--for individual representatives-->
      <div *ngIf="clientFormService.isHouseWithRepresentativeWithoutTaxNumber && isRepEditing">
        <!--fixed representative type-->
        <div class="top-margin-10">
          <payee-dropdown [formControl]="clientFormService.repTypeFormControl" height="tall" width="widest">
            <!--styling-->
            <ng-template #menuDisplayTemplate let-selected>
              <div class="flex vcenter" *ngIf="selected">
                <span class="grow1">{{selected}}</span>
              </div>
            </ng-template>
          </payee-dropdown>
        </div>

        <!--representative address-->
        <div class="top-margin-10">
          <app-form-address
            [countryFormControl]="clientFormService.repCountryFormControl"
            [postcodeFormControl]="clientFormService.repPostcodeFormControl"
            [cityFormControl]="clientFormService.repSettlementFormControl"
            [streetFormControl]="clientFormService.repStreetFormControl">
          </app-form-address>
        </div>
      </div>

      <!--completed representative search summarizing box-->
      <div *ngIf="!isRepEditing" class="top-margin-30">
        <payee-completed-search-box
          [name]="clientFormService.repNameFormControl.value"
          [address]="clientFormService.representativeAddress"
          [taxNumber]="clientFormService.taxNumberFormControl.value"
          (deleteButtonClick)="onRepresentativeDelete()"
          (editButtonClick)="onRepresentativeEdit()">
        </payee-completed-search-box>
      </div>
    </div>
  </div>

  <!--completed view-->
  <div *ngIf="!isClientEditing">
    <payee-completed-search-box
      [name]="clientFormService.nameFormControl.value"
      [address]="clientFormService.clientAddress"
      [additionalData]="clientFormService.additionalDataString"
      [taxNumber]="clientFormService.taxNumberFormControl.value"
      [isDeletable]="false"
      [disabled]="!clientFormService.isEditable"
      (editButtonClick)="onClientEdit()">
    </payee-completed-search-box>
  </div>
</div>
