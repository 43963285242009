<header>
  <ap-header [type]="'solid'">
    <ng-template ngTemplate="title">Real-time Debt Management Tool</ng-template>
    <ng-template ngTemplate="options">
      <ap-button [icon]="'login-fill'" routerLink="/login"></ap-button>
    </ng-template>
  </ap-header>
</header>

<div class="wrapper grow1 flex vcenter hcenter">
  <h1 class="error-message">
    Az Ön felhasználói fiókja nincs regisztrálva. Kérjük vegye fel a kapcsolatot
    az ügyfélszolgálattal
  </h1>
</div>

<footer>
  <app-footer></app-footer>
</footer>
