import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import axios, { AxiosError } from 'axios';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { z } from 'zod';

const queryParamSchema = z.object({
  code: z.string(),
  iss: z.string(),
  client_id: z.string(),
});
const tokensSchema = z.object({
  access_token: z.string(),
  id_token: z.string(),
  expires_in: z.number().int(),
  refresh_token: z.string(),
  token_type: z.string(),
});

@Component({
  selector: 'app-oauth-redirect',
  templateUrl: './oauth-redirect.component.html',
  styleUrls: ['./oauth-redirect.component.scss']
})
export class OauthRedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
  ) { }

  async ngOnInit() {
    try {
      const { code } = queryParamSchema.parse(this.route.snapshot.queryParams);
      const url = environment.baseUrl + '/api/oauth/token';
      this.authService.clearTokens();
      const response = await axios.post(url, { code });
      const { access_token, expires_in, refresh_token, id_token } = tokensSchema.parse(response.data);
      this.authService.setTokens({
        access_token,
        refresh_token,
        expires_in,
        id_token,
      });
      this.router.navigateByUrl(environment.afterLoginPath);
    } catch (error) {
      if (error instanceof AxiosError) {
        const data = error.response?.data;
        if (data?.code === 'user-not-found') {
          this.router.navigateByUrl('user-not-found');
          return;
        }
      }
      console.error('Something went wrong while authenticating', error);
      this.router.navigateByUrl('login');
    }
  }
}
