import { FormService } from './form-service';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { HelpersService } from '../../../../../services/helpers/helpers.service';
import { ClaimDialogComponent } from '../../dialogs/claim-dialog/claim-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment/moment';
import { InitStepService } from "../init-step.service";
import { Claim, getEmptyClaim } from "../../models/claims-and-legal-relationship/claim";
import { ClaimsAndLegalBaseStepData } from "../../models/claims-and-legal-relationship/claims-and-legal-base-step-data";
import { LegalBase } from "../../models/claims-and-legal-relationship/legal-base";
import { PayeeFile } from "../../models/common/payee-file";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormErrorSnackbarComponent } from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";

@Injectable({
    providedIn: 'root'
})
export class ClaimFormService extends FormService {
    claimsAndLegalBaseStepData: ClaimsAndLegalBaseStepData;
    form: FormGroup;

    claimsDataHasAlreadyBeenValid = false;

    // first layer
    get claimFormArray(): FormArray {
        return this.form.get('claimFormArray') as FormArray;
    };

    get legalRelationshipFormGroup(): FormGroup {
        return this.form.get('legalRelationshipFormGroup') as FormGroup;
    }

    // claim
    getSelectedClaimFormGroup(index: number) {
        return this.claimFormArray.at(index);
    }

    getClaimIdFormControl(index: number) {
        return this.getSelectedClaimFormGroup(index).get('claimIdFormControl') as FormControl;
    }

    getClaimAmountFormControl(index: number) {
        return this.getSelectedClaimFormGroup(index).get('claimAmountFormControl') as FormControl;
    }

    getClaimDueDateAtFormControl(index: number) {
        return this.getSelectedClaimFormGroup(index).get('claimDueDateAtFormControl') as FormControl;
    }

    // claim file
    getClaimFileFormGroup(index: number) {
        return this.getSelectedClaimFormGroup(index).get('claimFileFormGroup') as FormGroup;
    }

    getClaimFileIdFormControl(index: number) {
        return this.getClaimFileFormGroup(index).get('fileIdFormControl') as FormControl;
    }

    getClaimFileNameFormControl(index: number) {
        return this.getClaimFileFormGroup(index).get('fileNameFormControl') as FormControl;
    }

    getClaimFileFormControl(index: number) {
        return this.getClaimFileFormGroup(index).get('fileFormControl') as FormControl;
    }

    // legal relationship
    get isCommonCostBalanceFormControl(): FormControl {
        return this.legalRelationshipFormGroup.get('isCommonCostBalanceFormControl') as FormControl;
    };

    get legalRelationshipExplanationFormControl(): FormControl {
        return this.legalRelationshipFormGroup.get('legalRelationshipExplanationFormControl') as FormControl;
    };

    // legal relationship file
    get legalRelationshipExplanationFileFormArray(): FormArray {
        return this.legalRelationshipFormGroup.get('legalRelationshipExplanationFileFormArray') as FormArray;
    };

    getLegalRelationshipExplanationFileIdFormControl(index: number) {
        return this.legalRelationshipExplanationFileFormArray.at(index).get('fileIdFormControl') as FormControl;
    }

    getLegalRelationshipExplanationFileNameFormControl(index: number) {
        return this.legalRelationshipExplanationFileFormArray.at(index).get('fileNameFormControl') as FormControl;
    }

    getLegalRelationshipExplanationFileFormControl(index: number) {
        return this.legalRelationshipExplanationFileFormArray.at(index).get('fileFormControl') as FormControl;
    }

    constructor(
        public helpersService: HelpersService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private initStepService: InitStepService,
        private snackbar: MatSnackBar,
    ) {
        // set initial parent basic data
        super(helpersService, dialog);
        this.setText(
            'Követelések',
            `Add meg, hogy mennyivel tartozik az adós`
        );
        this.urlName = 'claims';

        // set claims and legal base step data
        this.claimsAndLegalBaseStepData = this.initStepService.claimsAndLegalBaseStepData;
        this.initStepService.getClaimsAndLegalBaseStepDataListener()
            .subscribe((claimsAndLegalBaseStepData: ClaimsAndLegalBaseStepData) => {
                this.claimsAndLegalBaseStepData = claimsAndLegalBaseStepData;
            });
    }

    public addClaim() {
        const emptyClaimFormGroup = this.getClaimFormGroup(getEmptyClaim());
        this.claimFormArray.push(emptyClaimFormGroup);

        this.updateFormControlDisability();
    }

    public deleteClaim(index: number) {
        this.claimFormArray.removeAt(index);
    }

    public addLegalRelationshipFile(payeeFile: PayeeFile) {
        const fileFormGroup = this.getFileFormGroup(payeeFile);
        this.legalRelationshipExplanationFileFormArray.push(fileFormGroup);
    }

    public deleteLegalRelationshipFile(index: number) {
        this.legalRelationshipExplanationFileFormArray.removeAt(index);
    }

    public updateFormControlDisability() {
        this.claimFormArray.controls.forEach(claimFormGroup => {
            const claimAmountFormControl = claimFormGroup.get('claimAmountFormControl');
            const dateFormControl = claimFormGroup.get('claimDueDateAtFormControl');
            const fileFormGroup = claimFormGroup.get('claimFileFormGroup');

            if (fileFormGroup.invalid) {
                claimAmountFormControl.disable();
                dateFormControl.disable();
                //} else if (this.formHelperService.isSzamlazzhu) { szamlazzhu
                //  claimAmountFormControl.enable();
                //  dateFormControl.disable();
            } else {
                dateFormControl.enable();
                claimAmountFormControl.enable();
                dateFormControl.markAsPristine();
                claimAmountFormControl.markAsPristine();
                this.areErrorMessagesShown = false;
            }
        });
    }

    public getClaimsSumAmount(): number {
        let total = 0;

        this.claimFormArray?.controls.forEach((formGroup: FormGroup) => {
            const claimAmountFormControl = formGroup.get('claimAmountFormControl');
            total += Number(claimAmountFormControl.value);
        });


        return total;
    }

    public isAnyClaimAmountGiven(): boolean {
        let isGiven = false;
        this.claimFormArray.controls.forEach((formGroup: FormGroup) => {
            const claimAmountFormControl = formGroup.get('claimAmountFormControl');
            if (claimAmountFormControl.value) {
                isGiven = true;
            }
        });

        return isGiven;
    }

    // override
    onValueChange() {
        this.setClaimsDataHasAlreadyBeenValid();

        this.valueChanged = true;
    }

    //override
    buildForm() {
        this.form = this.getForm();

        this.setClaimsDataHasAlreadyBeenValid();
        this.updateFormControlDisability();

        this.legalRelationshipExplanationFormControl.updateValueAndValidity();
        this.legalRelationshipExplanationFileFormArray.updateValueAndValidity();
    }

    // override
    setMenuTexts() {
        this.dropdownTitle = `${this.helpersService.getHUFAmountFormat(this.getClaimsSumAmount())} HUF`;
        this.dropdownItems = [];
        for (const claim of this.claimsAndLegalBaseStepData.claims) {
            const dueDate = moment(claim.due_date_at).format('YYYY.MM.DD.');
            this.dropdownItems.push(`${this.helpersService.getHUFAmountFormat(claim.amount)} HUF - Lejárt: ${dueDate}`);
        }
    }

    // override
    openDialog() {
        return this.dialog.open(ClaimDialogComponent, {
            position: {
                top: '50px',
            },
            autoFocus: false,
            disableClose: true,
        });
    }

    private setClaimsDataHasAlreadyBeenValid() {
        if (this.claimFormArray.valid
          && this.getClaimAmountFormControl(0).value
          && this.getClaimDueDateAtFormControl(0).value)
        {
            this.claimsDataHasAlreadyBeenValid = true;
        }
    }

    // override
    async persistData() {
        this.updateClaimsAndLegalBaseStepData();

        try {
            await this.initStepService.saveClaimsAndLegalRelationshipStepData();
        } catch (error) {
            console.error(error);
            this.snackbar.openFromComponent(FormErrorSnackbarComponent);
        }
    }

    private getForm(): FormGroup {
        const claims = this.claimsAndLegalBaseStepData.claims;
        const legalBase = this.claimsAndLegalBaseStepData.legal_base;

        return this.formBuilder.group({
            claimFormArray: this.getClaimFormArray(claims),
            legalRelationshipFormGroup: this.getLegalRelationshipFormGroup(legalBase)
        });
    }

    private getClaimFormArray(claims: Claim[]): FormArray {
        const claimFormArray = this.formBuilder.array([]);

        for (let i = 0; i < claims.length; i++) {
            const formGroup = this.getClaimFormGroup(claims[i]);
            claimFormArray.push(formGroup);
        }

        claimFormArray.setValidators(this.totalSumValidator);

        return claimFormArray;
    }

    private getClaimFormGroup(claim: Claim): FormGroup {
        return this.formBuilder.group({
            claimIdFormControl: [
                claim.uuid ?? '',
                [],
            ],
            claimAmountFormControl: [
                claim.amount || '',
                [Validators.required, Validators.max(3E7)]
            ],
            claimDueDateAtFormControl: [
                claim.due_date_at ? moment(claim.due_date_at, 'YYYY-MM-DD') : null,
                [Validators.required]
            ],
            claimFileFormGroup: this.getFileFormGroup(claim.file),
            placeholderFormControl: [],
            ref_id: claim.ref_id,
        });
    }

    private getLegalRelationshipFormGroup(legalBase: LegalBase): FormGroup {
        return this.formBuilder.group({
            isCommonCostBalanceFormControl: [
                legalBase.is_common_cost_balance ?? false,
                []
            ],
            legalRelationshipExplanationFormControl: [
                legalBase.legal_relationship_explanation ?? '',
                [this.requiredValidator('isCommonCostBalanceFormControl', 'legalRelationshipExplanationFormControl')],
            ],
            legalRelationshipExplanationFileFormArray:
                this.getLegalRelationshipExplanationFileFormArray(legalBase.legal_relationship_explanation_files)
        });
    }

    private getLegalRelationshipExplanationFileFormArray(files: PayeeFile[]): FormArray {
        const legalRelationshipExplanationFileFormArray = this.formBuilder.array([], [this.checkEmptyFormArray()]);

        for (const file of files) {
            const legalRelationshipExplanationFileFormGroup = this.getFileFormGroup(file);
            legalRelationshipExplanationFileFormArray.push(legalRelationshipExplanationFileFormGroup);
        }

        return legalRelationshipExplanationFileFormArray;
    }

    private getFileFormGroup(file: PayeeFile): FormGroup {
        return this.formBuilder.group({
            fileIdFormControl: [
                file.uuid ?? null,
                []
            ],
            fileNameFormControl: [
                file.file_name ?? '',
                [Validators.required]
            ],
            fileFormControl: [
                file.file ?? null,
                []
            ]
        });
    }

    private totalSumValidator = () => {
        const claimsSumAmount = this.getClaimsSumAmount();
        const moreThanThirtyMillion = claimsSumAmount > 3E7;

        return moreThanThirtyMillion ? {sumExceeded: true} : null;
    };

    private checkEmptyFormArray(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const parent = control.parent;
            if (!parent) {
              return null;
            }
            const isCommonCostBalance = parent.get('isCommonCostBalanceFormControl')?.value;

            if (isCommonCostBalance) {
              return null;
            }

            const formArray = control as FormArray;

            // Check if formArray has no form groups or controls
            if (!formArray || formArray.length === 0) {
                return {'emptyFormArray': true};
            }

            return null; // Return null if validation passes
        };
    }

    private requiredValidator(controlName: string, originalControlName: string) {
      return (control: FormControl) => {
        const parent = control.parent;
        if (!parent) {
          return null;
        }
        const requiredControl = parent.get(controlName);
        const originalControl = parent.get(originalControlName);

        const value = requiredControl.value;

        if (value || originalControl.value) {
          return null;
        }

        return { required: true };
      };
    }

    private updateClaimsAndLegalBaseStepData() {
        this.claimsAndLegalBaseStepData.claims = [];

        for (let i = 0; i < this.claimFormArray.controls.length; i++) {
            this.claimsAndLegalBaseStepData.claims.push({
                uuid: this.getClaimIdFormControl(i).value ?? null,
                amount: this.getClaimAmountFormControl(i).value,
                due_date_at: this.getClaimDueDateAtFormControl(i).value.format('YYYY-MM-DD'),
                file: {
                    uuid: this.getClaimFileIdFormControl(i).value ?? null,
                    file_name: this.getClaimFileNameFormControl(i).value,
                    file: this.getClaimFileFormControl(i).value
                },
                ref_id: this.getClaimFileFormGroup(i).value.ref_id,
            })
        }

        this.claimsAndLegalBaseStepData.legal_base.is_common_cost_balance = this.isCommonCostBalanceFormControl.value;
        this.claimsAndLegalBaseStepData.legal_base.legal_relationship_explanation = this.legalRelationshipExplanationFormControl.value;
        this.claimsAndLegalBaseStepData.legal_base.legal_relationship_explanation_files = [];

        for (let i = 0; i < this.legalRelationshipExplanationFileFormArray.controls.length; i++) {
            this.claimsAndLegalBaseStepData.legal_base.legal_relationship_explanation_files.push({
                uuid: this.getLegalRelationshipExplanationFileIdFormControl(i).value ?? null,
                file_name: this.getLegalRelationshipExplanationFileNameFormControl(i).value,
                file: this.getLegalRelationshipExplanationFileFormControl(i).value
            })
        }
    }
}
