import { Injectable } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';

@Injectable({
  providedIn: 'root'
})
export class GooglemapsService {
  private readonly loader: Loader;

  constructor() {
    this.loader = new Loader({
      apiKey: 'AIzaSyAH5UvmLz3OFt4cbuLCaJ7EIS2arOS2FWM',
    });
  }

  async getAutocomplete() {
    const { Autocomplete } = await this.loader.importLibrary('places');
    return Autocomplete;
  }
}
