<div class="top-margin-20 information-container">
  <!--full claims amount-->
  <div class="summary-item">
    <div class="black-title">Teljes követelés</div>
    <div class="black-title">
      {{
        summaryStepService.summaryStepData.total_claims_amount
          | number : "1.0" : "hu"
      }}
      HUF
    </div>
  </div>

  <!--debtor description-->
  <div class="debtor-description top-margin-5">
    {{ summaryStepService.summaryStepData.debtor_name }} adósnak összesen ennyit
    kell megfizetnie
  </div>

  <!--optional claims-->
  <div
    class="optional-claims top-margin-20"
    *ngIf="summaryStepService.summaryStepData.type !== FormTypeEnum.SOFT"
  >
    <app-optional-claim-title></app-optional-claim-title>
    <div class="top-margin-10">
      <payee-toggle
        [selected]="summaryStepService.summaryStepData.is_added_interest_claim"
        [text]="'Törvényes kamatok'"
        (selectionChanged)="onInterestClick($event)"
        ap-tooltip
        tooltipContent="A teljes követelés megfizetéséig felhalmozódó kamatok érvényesítése"
        direction="top"
      ></payee-toggle>
    </div>
    <div
      class="top-margin-10"
      *ngIf="
        summaryStepService.summaryStepData
          .has_claims_eligible_for_flat_rate_cost
      "
    >
      <payee-toggle
        [selected]="
          summaryStepService.summaryStepData.is_added_flat_rate_cost_claim
        "
        [text]="'Törvény szerinti 40 EUR behajtási költségátalány'"
        (selectionChanged)="onFortyEurosClick($event)"
        ap-tooltip
        tooltipContent="Az egy éven belül lejárt követelésekre követelésenként
                    40 EUR-nak megfelelő összeg érvényesítése"
        direction="top"
      ></payee-toggle>
    </div>
    <div
      class="top-margin-10"
      *ngIf="summaryStepService.summaryStepData.type === FormTypeEnum.HARD"
    >
      <payee-toggle
        [selected]="true"
        [disabled]="true"
        [text]="'Eljárási díj'"
        ap-tooltip
        tooltipContent="Fizetési meghagyás esetén automatikusan az eljárási díj
        is hozzáadódik a követeléshez, amit az adósnak kell megfizetnie."
        direction="top"
      ></payee-toggle>
    </div>
    <div
      class="top-margin-10"
      *ngIf="summaryStepService.summaryStepData.type === FormTypeEnum.HARD"
    >
      <payee-toggle
        [selected]="true"
        [disabled]="true"
        [text]="'Ügyindítási díj'"
        ap-tooltip
        tooltipContent="Fizetési meghagyás esetén automatikusan az ügyindítási díj
        is hozzáadódik a követeléshez, amit az adósnak kell megfizetnie."
        direction="top"
      ></payee-toggle>
    </div>
  </div>
</div>
