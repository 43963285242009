import { Injectable } from '@angular/core';
import { FormService } from '../aa-new-form/form-steps/init-step/services/form-services/form-service';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { HelpersService } from '../services/helpers/helpers.service';
import { MatDialog } from '@angular/material/dialog';
import { PwcInvoiceDialogComponent } from './pwc-invoice-dialog/pwc-invoice-dialog.component';
import { InitStepService } from '../aa-new-form/form-steps/init-step/services/init-step.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormErrorSnackbarComponent } from '../snackbars/form-error-snackbar/form-error-snackbar.component';
import { GetInvoicesOfDebtorResponse, PwcInvoicesOfDebtorTableData, PwcService } from '../services/pwc/pwc.service';
import { Claim } from '../aa-new-form/form-steps/init-step/models/claims-and-legal-relationship/claim';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class PwcInvoiceFormService extends FormService {
  override form: FormArray;
  private invoices = new Map<string, PwcInvoicesOfDebtorTableData>();

  constructor(
    public helpersService: HelpersService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private initStepService: InitStepService,
    private snackbar: MatSnackBar,
    private pwcService: PwcService,
  ) {
    super(helpersService, dialog);
    this.setText(
      'Számlák kiválasztása',
      'Válaszd ki a számlákat',
    );

    this.form = this.fb.array([], [Validators.required]);
  }

  readonly indexOfSelectedInvoice = (invoice: PwcInvoicesOfDebtorTableData): number => {
    return this.form.value.indexOf(invoice.ort_id.toString());
  };

  readonly isInvoiceSelected = (invoice: PwcInvoicesOfDebtorTableData): boolean => {
    return this.indexOfSelectedInvoice(invoice) >= 0;
  };

  addInvoice(invoice: PwcInvoicesOfDebtorTableData): void {
    if (this.isInvoiceSelected(invoice)) {
      return;
    }

    this.form.push(this.fb.control(invoice.ort_id.toString()));
  }

  removeInvoice(invoice: PwcInvoicesOfDebtorTableData): void {
    const index = this.indexOfSelectedInvoice(invoice);
    if (index >= 0) {
      this.form.removeAt(index);
    }
  }

  toggleInvoice(invoice: PwcInvoicesOfDebtorTableData): void {
    if (this.isInvoiceSelected(invoice)) {
      this.removeInvoice(invoice);
    } else {
      this.addInvoice(invoice);
    }
  }

  async getInvoices(page: number, per_page: number, sort_by?: string, sort_direction?: string): Promise<GetInvoicesOfDebtorResponse> {
    const result = await this.pwcService.getInvoicesOfDebtor({
      page,
      per_page,
      sort_by,
      sort_direction,
    }, this.initStepService.getCaseUuid());

    for (const invoice of result.data) {
      this.invoices.set(invoice.ort_id.toString(), invoice);
    }

    return result;
  }

  override buildForm() {
    this.form.clear();
    this.initStepService.claimsAndLegalBaseStepData.claims
      .filter(claim => !!claim.ref_id)
      .forEach(claim => {
        this.form.push(this.fb.control(claim.ref_id));
      });
  }

  override async persistData() {
    this.updateData();

    try {
      await this.initStepService.saveClaimsAndLegalRelationshipStepData();
      await this.initStepService.saveCommunicationStepData();
      this.dialog.closeAll();
    } catch (error) {
      console.error(error);
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
    }
  }

  override setMenuTexts() {
    const sum: number = this.initStepService.claimsAndLegalBaseStepData.claims.reduce((sum, c) => sum + c.amount, 0);
    this.dropdownTitle = `${this.helpersService.getHUFAmountFormat(sum)} HUF`;
    this.dropdownItems = [];
    for (const claim of this.initStepService.claimsAndLegalBaseStepData.claims) {
      const dueDate = moment(claim.due_date_at).format('YYYY.MM.DD.');
      const amount = this.helpersService.getHUFAmountFormat(claim.amount);
      this.dropdownItems.push(`${amount} HUF - Lejárt: ${dueDate}`);
    }
  }

  override openDialog() {
    return this.dialog.open(PwcInvoiceDialogComponent, {
      position: {
        top: '50px',
      },
      autoFocus: false,
      disableClose: true,
    });
  }

  private readonly updateData = (): void => {
    const claimsAndLegalBaseStepData = this.initStepService.claimsAndLegalBaseStepData;
    const claims = claimsAndLegalBaseStepData.claims;
    const ortIds = this.form.value as number[];
    const claimsToKeep = claims
      .filter(claim => ortIds.some(ort_id => claim.ref_id === ort_id.toString()));

    const newInvoiceIds: string[] = ortIds
      .filter((ort_id: number) => !claims.some(c => c.ref_id === ort_id.toString()))
      .map(ortId => ortId.toString());

    const newClaims = newInvoiceIds.map<Claim>(ort_id => {
      const invoice = this.invoices.get(ort_id);
      if (!invoice) {
        return;
      }
      return {
        amount: invoice.remaining_amount,
        due_date_at: invoice.due_date_at,
        file: {
          file: null,
          file_name: null,
          uuid: null,
        },
        uuid: null,
        ref_id: ort_id.toString(),
      };
    });

    claimsAndLegalBaseStepData.claims = [...claimsToKeep, ...newClaims];

    const isCommonCostBalance =
      claimsAndLegalBaseStepData.legal_base.is_common_cost_balance ||
      claimsAndLegalBaseStepData.legal_base.legal_relationship_explanation_files.length === 0
      || !claimsAndLegalBaseStepData.legal_base.legal_relationship_explanation;

    claimsAndLegalBaseStepData.legal_base.is_common_cost_balance = isCommonCostBalance;

    this.initStepService.communicationStepData.description = ortIds
      .map(ortId => {
        const invoice = this.invoices.get(ortId.toString());
        return invoice.note ?? invoice.invoice_number;
      })
      .join(', ');
  };
}
