import { Component, OnDestroy, OnInit } from '@angular/core';
import { SzamlazzhuService } from '../../../../../services/szamlazzhu/szamlazzhu.service';
import { InvoiceFormService } from '../../services/form-services/invoice-form-service';
import { Title } from '@angular/platform-browser';
import { FormErrorSnackbarComponent } from "../../../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-invoice-dialog',
  templateUrl: './invoice-dialog.component.html',
  styleUrls: ['./invoice-dialog.component.scss']
})
export class InvoiceDialogComponent implements OnInit, OnDestroy {
  loading = true;
  waiting!: number;
  all!: number;

  // TODO: be kell állítani
  private loadingTimePerInvoice: number = 3;
  private ksziInfoInterval: NodeJS.Timeout;

  get expectedLoadingTime(): number {
    const expectedLoadingTimeInSeconds = this.waiting * this.loadingTimePerInvoice;
    const expectedLoadingTimeInMinutes = Math.ceil(expectedLoadingTimeInSeconds / 60);
    return expectedLoadingTimeInMinutes;
  }

  constructor(
    public invoiceFormService: InvoiceFormService,
    private szamlazzhuService: SzamlazzhuService,
    private titleService: Title,
    private snackbar: MatSnackBar,
  ) { }

  async ngOnInit() {
    this.titleService.setTitle('Számla kiválasztása - Real-time Debt Management Tool');

    this.ksziInfoInterval = setInterval(async () => {
      const oldWaiting = this.waiting;
      await this.setKsziStatus();
      if (oldWaiting > this.waiting) {
        await this.fetchInvoices();
      }
    }, 10000);

    try {
      await this.setKsziStatus();
      await this.fetchInvoices();
    } catch (error) {
      console.error(error);
      this.snackbar.openFromComponent(FormErrorSnackbarComponent);
    } finally {
      this.loading = false;
    }
  }

  onClose() {
    this.invoiceFormService.openExitDialog();
  }

  async onSave() {
    await this.invoiceFormService.saveData();
  }

  private async setKsziStatus() {
    const ksziInfoResult = await this.szamlazzhuService.getKsziInfo();

    this.waiting = ksziInfoResult['kszi_wait_ki'].count;
    this.all = ksziInfoResult['all_ki'].count;
  }

  private async fetchInvoices() {
    // const szamlazzhuInvoices = await this.szamlazzhuService.getAvailableInvoices(this.debtorsFormDataService.debtorsData[0].id);
    // await this.invoicesFormDataService.setInvoicesData(szamlazzhuInvoices);
    // TODO
  }

  ngOnDestroy(): void {
    clearInterval(this.ksziInfoInterval);
  }
}
