import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-form-data-step-exit-dialog',
  templateUrl: './form-data-step-exit-dialog.component.html',
  styleUrls: ['./form-data-step-exit-dialog.component.scss']
})
export class FormDataStepExitDialogComponent {
  constructor(
      private dialogRef: MatDialogRef<FormDataStepExitDialogComponent>,
      private dialog: MatDialog,
  ) {}

  exit() {
    this.dialog.closeAll();
  }

  stay() {
    this.dialogRef.close();
  }
}
