import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { PwcInvoicesTableData, PwcService } from 'src/app/services/pwc/pwc.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { CreateCaseDialogComponent } from '../create-case-dialog/create-case-dialog.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pwc-invoices-table',
  templateUrl: './pwc-invoices-table.component.html',
  styleUrls: ['./pwc-invoices-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PwcInvoicesTableComponent implements OnChanges {
  @Input() data: PwcInvoicesTableData[] = [];

  @Input() pageSize: number = 10;
  @Input() pageIndex: number = 1;
  @Input() length?: number;
  @Output() page = new EventEmitter<PageEvent>();
  @Output() sort = new EventEmitter<Sort>();

  expandedElement?: PwcInvoicesTableData;
  readonly displayedColumns: string[] = [
    // 'details_toggle',
    'invoice_number',
    ...(environment.pwcInvoiceShowStatus ? ['status'] : []),
    'supplier_tax_number',
    'due_date_at',
    'customer_name',
    'remaining_amount',
    'email',
    'phone',
    'note',
    'actions',
  ];
  readonly dataSource = new MatTableDataSource<PwcInvoicesTableData>();

  get statusMap() { return this.pwcService.statusMap; }
  get clientTaxNumber(): string {
    const client: any = this.clientsService.selectedClientSubject.value;
    return client?.tax_number;
  }

  constructor(
    private dialog: MatDialog,
    private clientsService: ClientsService,
    private pwcService: PwcService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if ('data' in changes) {
      const newData = changes.data.currentValue;
      this.dataSource.data = newData;
    }
  }

  onActionsMenuOpen(element: PwcInvoicesTableData): void { }
  onActionsMenuClosed(): void { }

  async createCase(element: PwcInvoicesTableData): Promise<void> {
    this.dialog.open(CreateCaseDialogComponent, {
      data: element,
    });
  }

  updateExpandedElement(element: PwcInvoicesTableData): void {
    // TODO

    // if (this.expandedElement === element) {
    //   this.expandedElement = null;
    // } else {
    //   this.expandedElement = element;
    // }
  }

  pageChange(event: PageEvent): void {
    this.page.emit(event);
  }

  sortData(event: Sort): void {
    this.sort.emit(event);
  }

  getCreateCaseTooltip(invoice: PwcInvoicesTableData): string {
    return this.pwcService.getCanCreatePayeeCaseMessage(invoice.can_create_payee_case);
  }
}
