import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwcInvoicesComponent } from './pwc-invoices.component';
import { PwcInvoicesTableComponent } from './pwc-invoices-table/pwc-invoices-table.component';
import { PayeeFormFieldsModule } from 'src/app/shared/payee-form-fields/payee-form-fields.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { HeaderModule } from '../header/header.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CreateCaseDialogComponent } from './create-case-dialog/create-case-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PayeePopupModule } from 'src/app/shared/payee-popup/payee-popup.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PayeeInfoModule } from 'src/app/shared/payee-info/payee-info.module';

@NgModule({
  declarations: [
    PwcInvoicesComponent,
    PwcInvoicesTableComponent,
    CreateCaseDialogComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HeaderModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    PayeeButtonsModule,
    MatCheckboxModule,
    PayeeFormFieldsModule,
    PayeeIconModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDialogModule,
    PayeePopupModule,
    MatTooltipModule,
    PayeeInfoModule,
  ]
})
export class PwcInvoicesModule { }
