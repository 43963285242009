<div
  class="claim-box-container"
  [ngClass]="selectedClaimForm.valid ? 'valid' : 'invalid'"
  *ngIf="!!selectedClaimForm"
>
  <!--number of the claim and trash icon-->
  <div class="claim-number-row">
    <div *ngIf="selectedClaimForm.valid" class="left number">
      {{ selectedClaimIndex + 1 }}. Követelés
    </div>
    <div
      *ngIf="
        !selectedClaimForm.valid && selectedClaimForm.hasError('noEvidence')
      "
      class="left title"
    >
      Töltsön fel egy számlát vagy egyenlegközlőt
    </div>
    <div
      *ngIf="
        !selectedClaimForm.valid && !selectedClaimForm.hasError('noEvidence')
      "
      class="left title"
    >
      Adja meg az adatokat!
    </div>
    <div *ngIf="isDeletable" class="right">
      <payee-icon
        [name]="environment.TRASH_ICON"
        (click)="onDelete()"
      ></payee-icon>
    </div>
  </div>

  <!--evidence-->
  <div class="evidence-row">
    <app-claim-evidence-upload
      [selectedClaimIndex]="selectedClaimIndex"
    ></app-claim-evidence-upload>

    <!--error message-->
    <div
      class="top-margin-10"
      *ngIf="
        claimFormService.areErrorMessagesShown &&
        claimFormService.getClaimFileFormGroup(selectedClaimIndex).invalid
      "
    >
      <mat-error custom-error> Fájl feltöltése kötelező! </mat-error>
    </div>
  </div>

  <!--claim amount-->
  <div class="amount-row">
    <div class="input-label">Követelés összege</div>
    <payee-form-field>
      <input
        payee-input
        class="amount-input"
        [formControl]="
          claimFormService.getClaimAmountFormControl(selectedClaimIndex)
        "
        (input)="formatClaimAmount()"
        value="{{
          claimFormService.getClaimAmountFormControl(selectedClaimIndex)
            .valueChanges
            | async
            | number : '1.0' : 'hu'
        }}"
        [placeholder]="
          claimFormService.getClaimAmountFormControl(selectedClaimIndex)
            .disabled
            ? ''
            : 'Ide írd az összeget...'
        "
      />

      <div payee-suffix>
        <div class="huf-suffix">
          <payee-icon
            class="hu-flag-circle"
            [name]="'hu-flag-circle'"
          ></payee-icon>
          <div
            [ngClass]="
              claimFormService.getClaimAmountFormControl(selectedClaimIndex)
                .disabled
                ? 'disabled'
                : ''
            "
          >
            HUF
          </div>
        </div>
      </div>

      <ng-container
        payee-error
        *ngIf="
          claimFormService.getClaimAmountFormControl(selectedClaimIndex).dirty
        "
      >
        <div
          *ngIf="
            claimFormService
              .getClaimAmountFormControl(selectedClaimIndex)
              .hasError('required');
            else claim_amount_invalid
          "
        >
          A mező kitöltése kötelező!
        </div>
        <ng-template #claim_amount_invalid>
          <div
            *ngIf="
              claimFormService
                .getClaimAmountFormControl(selectedClaimIndex)
                .hasError('claimAmountInvalid');
              else claim_max
            "
          >
            A követelés összege nem haladhatja meg a számlán szereplő bruttó
            összeget!
          </div>
        </ng-template>
        <ng-template #claim_max>
          <div
            *ngIf="
              claimFormService
                .getClaimAmountFormControl(selectedClaimIndex)
                .hasError('max')
            "
          >
            A követelés összege nem haladhatja meg a 30.000.000 Forintot!
          </div>
        </ng-template>
      </ng-container>
    </payee-form-field>
  </div>

  <!--expiration date-->
  <div class="expiration-date-row">
    <div class="input-label">Mikor járt le a számla?</div>
    <payee-form-field [height]="'tall'" [width]="'560px'">
      <ng-container payee-error *ngIf="claimFormService.areErrorMessagesShown">
        <div
          *ngIf="
            claimFormService
              .getClaimDueDateAtFormControl(selectedClaimIndex)
              .hasError('required')
          "
        >
          A mező kitöltése kötelező!
        </div>
      </ng-container>
      <payee-datepicker
        max="{{ date | date : 'yyyy-MM-dd' }}"
        [placeholder]="
          claimFormService.getClaimDueDateAtFormControl(selectedClaimIndex)
            .disabled
            ? ''
            : 'Adja meg a napot...'
        "
        [formControl]="
          claimFormService.getClaimDueDateAtFormControl(selectedClaimIndex)
        "
      >
      </payee-datepicker>
    </payee-form-field>
  </div>
</div>
