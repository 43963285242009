<div id="page-container" class="flex column" *ngIf="!loading">
  <div id="content-wrap" class="grow1">
    <!--header-->
    <app-form-header
      [selectedStepIndex]="1"
      [payeeId]="summaryStepService.summaryStepData.payee_id"
    >
    </app-form-header>

    <!--middle part-->
    <div class="container">
      <div class="middle-part">
        <!--small title-->
        <h5
          class="small-title"
          [ngSwitch]="summaryStepService.summaryStepData.type"
        >
          <ng-container *ngSwitchCase="FormTypeEnum.SOFT"
            >Fizetési felszólítás indítása</ng-container
          >
          <ng-container *ngSwitchCase="FormTypeEnum.DEMAND_LETTER"
            >Ügyvédi levél indítása</ng-container
          >
          <ng-container *ngSwitchCase="FormTypeEnum.HARD"
            >Fizetési meghagyás indítása</ng-container
          >
        </h5>

        <!--title-->
        <div class="new-big-title top-margin-5">Összegzés</div>

        <!--description-->
        <div class="new-big-description top-margin-20">
          Tekintse át a megadott adatokat.
        </div>

        <app-summary-description-box></app-summary-description-box>

        <app-summary-optional-claims-box></app-summary-optional-claims-box>

        <!-- <div class="dashed-divider"></div> -->

        <!-- <app-summary-start-case-fee-box></app-summary-start-case-fee-box> -->

        <!--buttons-->
        <div class="buttons-wrapper top-margin-20">
          <div class="backward-button">
            <button payee-back-button (click)="onBackButtonClick()">
              Vissza az adatmegadásra
            </button>
          </div>
          <div class="forward-button-payment">
            <button payee-button (click)="onForwardButtonClick()">
              <!-- Levettük a payment steppet, PwC-nek ez egyből elindítja az ügyet -->
              Ügy indítása
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--footer-->
  <app-footer></app-footer>
</div>

<!--loading screen-->
<app-loading-screen *ngIf="loading"></app-loading-screen>
