import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormSelectorComponent } from './aa-new-form/form-selector/form-selector.component';
import { InitStepComponent } from "./aa-new-form/form-steps/init-step/init-step.component";
import { PaymentStepComponent } from "./aa-new-form/form-steps/payment-step/payment-step.component";
import { SummaryStepComponent } from "./aa-new-form/form-steps/summary-step/summary-step.component";
import {
  CashbookLoginFailedComponent
} from './components/cashbook/cashbook-login-failed/cashbook-login-failed.component';
import {
  CashbookLoginSuccessComponent
} from './components/cashbook/cashbook-login-success/cashbook-login-success.component';
import { ClaimsRerouterComponent } from './components/claims-rerouter/claims-rerouter.component';
import { CreatePasswordComponent } from './components/create-password/create-password.component';
import { AdminCasesComponent } from './components/dashboard/admin-cases/admin-cases.component';
import { ClientProfileComponent } from './components/dashboard/client-profile/client-profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PwcInvoicesComponent } from './components/dashboard/pwc-invoices/pwc-invoices.component';
import { UserProfileComponent } from './components/dashboard/user-profile/user-profile.component';
import { EhazSsoErrorComponent } from './components/ehaz/ehaz-sso-error/ehaz-sso-error.component';
import { EhazSsoComponent } from './components/ehaz/ehaz-sso/ehaz-sso.component';
import {
  GetPasswordLinkSuccessComponent
} from './components/forgot-password/get-password-link-success/get-password-link-success.component';
import { GetPasswordLinkComponent } from './components/forgot-password/get-password-link/get-password-link.component';
import {
  NewPasswordSuccessComponent
} from './components/forgot-password/new-password-success/new-password-success.component';
import { NewPasswordComponent } from './components/forgot-password/new-password/new-password.component';
import { LoginComponent } from './components/login/login/login.component';
import { RegisterComponent } from './components/login/register/register.component';
import { SignupFinalizationComponent } from './components/login/signup-finalization/signup-finalization.component';
import { TwofaFormComponent } from './components/login/twofa-form/twofa-form.component';
import { VerifyEmailComponent } from './components/login/verify-email/verify-email.component';
import { VerifyTokenExpiredComponent } from './components/login/verify-token-expired/verify-token-expired.component';
import { RestoreForgotPasswordComponent } from './components/restore-forgot-password/restore-forgot-password.component';
import { SuccessForgotPasswordComponent } from './components/success-forgot-password/success-forgot-password.component';
import {
  SzamlazzhuSsoErrorComponent
} from "./components/szamlazzhu/szamlazzhu-sso-error/szamlazzhu-sso-error.component";
import {
  SzamlazzhuSsoResponseComponent
} from "./components/szamlazzhu/szamlazzhu-sso-response/szamlazzhu-sso-response.component";
import { SzamlazzhuSsoComponent } from "./components/szamlazzhu/szamlazzhu-sso/szamlazzhu-sso.component";
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ClientTaxidGuardService } from './services/client-taxid-guard/client-taxid-guard.service';
import {
  OauthRedirectComponent as PwcOauthRedirectComponent,
} from 'src/app/shared/pwc/oauth-redirect/oauth-redirect.component';
import { LoadingScreenComponent } from './shared/loading-screen/loading-screen.component';
import { NoClientAttachedComponent } from './components/no-client-attached/no-client-attached.component';
import { UserNotFoundComponent } from 'src/app/shared/user-not-found/user-not-found.component';
import { UserGuideComponent } from './user-guide/user-guide.component';

const routes: Routes = [
  { path: 'cases/wizard', component: FormSelectorComponent, canActivate: [AuthGuardService, ClientTaxidGuardService] },
  { path: 'no-client-attached', component: NoClientAttachedComponent },
  { path: 'user-not-found', component: UserNotFoundComponent },
  {
    path: 'user',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    children: [
      // {path: 'api', component: ApiConnectionComponent},
      { path: 'user-profile', component: UserProfileComponent },
      { path: 'client-profile', component: ClientProfileComponent },
      // { path: 'campaign', component: CampaignComponent },
      { path: 'edit-case/:id', redirectTo: '/login' },
      {
        path: 'cases',
        component: AdminCasesComponent,
      },
      {
        path: 'pwc-invoices',
        component: PwcInvoicesComponent,
      },
      { path: '**', redirectTo: 'cases' }
    ]
  },

  // form routes
  { path: 'case/:uuid', redirectTo: 'case/:uuid/data-entry', pathMatch: 'full' },
  {
    path: 'case/:uuid/data-entry', component: InitStepComponent, children:
      [
        { path: '**', redirectTo: '', pathMatch: 'full' }
      ]
  },
  { path: 'case/:uuid/summary', component: SummaryStepComponent },
  // { path: 'case/:uuid/payment', component: PaymentStepComponent },
  { path: 'case/:uuid/payment', redirectTo: '/case/:uuid/summary' },

  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'verify-token-expired', component: VerifyTokenExpiredComponent },
  { path: 'signup-finalization', component: SignupFinalizationComponent },
  { path: 'create-password', component: CreatePasswordComponent, canActivate: [AuthGuardService] },
  { path: 'forgot-password-get-link', component: GetPasswordLinkComponent },
  { path: 'forgot-password-get-link-success', component: GetPasswordLinkSuccessComponent },
  { path: 'forgot-password-new-password/:token', component: NewPasswordComponent },
  { path: 'forgot-password-new-password-success', component: NewPasswordSuccessComponent },
  { path: 'restore-forgot-password', component: RestoreForgotPasswordComponent },
  { path: 'success-forgot-password', component: SuccessForgotPasswordComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout-success', redirectTo: '/login' },
  {
    path: '2fa', children: [
      { path: 'verify', component: TwofaFormComponent }
    ],
  },
  { path: 'signup', component: RegisterComponent },
  { path: 'beta-signup', redirectTo: 'signup' },
  {
    path: '_oauth',
    children: [
      { path: 'cashbook', redirectTo: '/login' },
      { path: 'cashbook_login_success', component: CashbookLoginSuccessComponent },
      { path: 'cashbook_login_failed', component: CashbookLoginFailedComponent },
      { path: '**', redirectTo: '/login' }
    ]
  },
  {
    path: 'oauth',
    children: [
      { path: 'pwc', component: PwcOauthRedirectComponent },
    ],
  },
  { path: 'claims/:claimToken', component: ClaimsRerouterComponent },
  { path: 'sso/ehaz', component: EhazSsoComponent },
  { path: 'sso/ehaz/error', component: EhazSsoErrorComponent },
  { path: 'sso/szamlazzhu', component: SzamlazzhuSsoComponent },
  { path: 'sso/szamlazzhu/error', component: SzamlazzhuSsoErrorComponent },
  { path: 'sso/szamlazzhu/response', component: SzamlazzhuSsoResponseComponent },
  { path: 'loading', component: LoadingScreenComponent },
  { path: 'user-guide', component: UserGuideComponent, canActivate: [AuthGuardService] },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
