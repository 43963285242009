import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';

export const FormType = {
  SOFT: 'notice',
  MEDIUM: 'demand_letter',
  HARD: 'hard_1',
} as const;

@Component({
  selector: 'app-type-selector',
  templateUrl: './type-selector.component.html',
  styleUrls: ['./type-selector.component.scss']
})
export class TypeSelectorComponent {
  @Input() type: string = FormType.SOFT;
  @Output() selectedType = new EventEmitter<string>();

  hardDropdownOpened = false;

  constructor() { }

  onSelectionChange(type: string) {
    this.type = type;
    this.selectedType.emit(this.type);
  }

  readonly environment = environment;
}
