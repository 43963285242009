import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CasesService } from 'src/app/services/cases/cases.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ErrorSnackbarComponent } from 'src/app/snackbars/error-snackbar/error-snackbar.component';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent {
  registerForm: FormGroup;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private casesService: CasesService,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private usersService: UsersService,
  ) {
    this.registerForm = this.fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(6)]],
    }, { validator: this.passwordConfirmValidator });
  }

  get lastName() { return this.registerForm.get('lastName'); }
  get firstName() { return this.registerForm.get('firstName'); }
  get password() { return this.registerForm.get('password'); }
  get passwordConfirm() { return this.registerForm.get('passwordConfirm'); }

  passwordConfirmValidator = (form: FormGroup) => {
    const password = form.get('password');
    const passwordConfirm = form.get('passwordConfirm');
    if (password.value === passwordConfirm.value) {
      return null;
    }
    return { passwordsNotMatching: true };
  }

  async register() {
    if (this.registerForm.invalid || this.loading) {
      return;
    }

    try {
      this.loading = true;

      await this.authService.quickHardColLogin();

      await Promise.all([
        this.authService.registerPassword(this.password.value),
        this.usersService.updateUser({
          name: `${this.lastName.value} ${this.firstName.value}`,
        }),
      ]);

      this.router.navigate(['/user/cases']);
    } catch (error) {
      console.error(error);
      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        data: {
          message: 'Valami hiba történt, próbáld újra késöbb!',
          action: 'OK',
        },
      });
    } finally {
      this.loading = false;
    }
  }

  async startNewCase() {
    try {
      this.loading = true;
      const trackingId = await this.casesService.createCase({
        product_type: 'hard_1',
      });
      this.router.navigateByUrl(`/hard1/${trackingId}`);
    } catch (error) {
      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        data: {
          message: 'Valami hiba történt, próbáld újra késöbb!',
          action: 'OK',
        },
      });
    } finally {
      this.loading = false;
    }
  }
}
