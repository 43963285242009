import { Injectable } from '@angular/core';
import { Case } from 'src/types';

@Injectable({
  providedIn: 'root'
})
export class EhazSsoApiService {
  async eHazSso(eHazToken: string): Promise<{case: Case, token: string}> {
    throw new Error('Unimplemented');
    // const eHazSsoApi = this.functions.httpsCallable('eHazSsoApi');
    // const result = await eHazSsoApi({ token: eHazToken });
    // return result.data;
  }
}
