import { Component, OnDestroy, OnInit } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { filter } from 'rxjs/operators';
import * as ibantools from 'ibantools';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bank-account-edit',
  templateUrl: './bank-account-edit.component.html',
  styleUrls: ['./bank-account-edit.component.scss']
})
export class BankAccountEditComponent implements OnInit, OnDestroy {
  readonly bankAccountForm: FormGroup;
  readonly formatBankAccount = this.helperService.formatBankAccount;

  saveCount: number = 0;

  private readonly destroy = new Subject<void>();

  get accountHolderName(): FormControl { return this.bankAccountForm.get('accountHolderName') as FormControl; }
  get accountNumber(): FormControl { return this.bankAccountForm.get('accountNumber') as FormControl; }
  get bankName(): FormControl { return this.bankAccountForm.get('bankName') as FormControl; }

  constructor(
    private clientsService: ClientsService,
    private fb: FormBuilder,
    private helperService: HelpersService,
  ) {
    this.bankAccountForm = this.fb.group({
      accountHolderName: [''],
      accountNumber: ['', [this.helperService.bankAccountValidator]],
      bankName: [''],
    });
  }

  async ngOnInit() {
    this.clientsService.selectedClientSubject
      .pipe(filter(value => !!value))
      .subscribe({
        // TODO: fix any
        next: (client: any) => {
          const bankAccount = client.bankAccount;

          let accountNumber: string = '';
          if (ibantools.isValidIBAN(bankAccount?.iban)) {
            accountNumber = bankAccount.iban;
          } else if (ibantools.isValidBBAN(bankAccount?.account_number, 'HU')) {
            accountNumber = bankAccount.account_number;
          }

          this.bankAccountForm.patchValue({
            accountHolderName: bankAccount?.account_holder_name,
            accountNumber: accountNumber,
            bankName: bankAccount?.bank_name,
          }, { emitEvent: false });
        },
      });

    this.bankAccountForm.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(777), distinctUntilChanged())
      .subscribe({
        next: async () => await this.saveClient(),
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  async saveClient() {
    if (this.bankAccountForm.invalid) {
      return;
    }

    try {
      const electronicFormatted = ibantools.electronicFormatIBAN(this.accountNumber.value);
      const isIban = ibantools.isValidIBAN(electronicFormatted);
      const isBban = ibantools.isValidBBAN(electronicFormatted, 'HU');

      await this.clientsService.updateBankAccount({
        bank_name: this.bankName.value || undefined,
        account_holder_name: this.accountHolderName.value || undefined,
        account_number: isBban ? electronicFormatted : undefined,
        iban: isIban ? electronicFormatted : undefined,
      });

      ++this.saveCount;
      setTimeout(() => --this.saveCount, 2500);
    } catch (error) {
      console.error('Error while saving client bank account', error);
    }
  }
}
