<div class="wrapper">
  <div class="flex column grow1">
    <div class="flex grow1">
      <div class="flex column hcenter grow1">
        <div class="flex subtitle" *ngIf="subtitle">{{ subtitle }}</div>
        <div class="flex title" *ngIf="title">{{ title }}</div>
      </div>
      <div
        class="flex vcenter"
        style="padding-top: 31px; padding-bottom: 20px; gap: 16px"
      >
        <button
          mat-button
          class="client-selector-button"
          [matMenuTriggerFor]="menu"
        >
          <div class="flex vcenter">
            <span class="grow1">{{ clientName }}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </button>
        <mat-menu #menu xPosition="before">
          <button
            mat-menu-item
            *ngFor="let client of clients"
            (click)="selectClient(client)"
            routerLink="/user/client-profile"
          >
            {{ getClientName(client) }}
          </button>
          <button mat-menu-item class="profile" (click)="onProfileClick()">
            <span>Profil</span>
            <span class="menu-icon">
              <span class="profile-icon">NP</span>
              <span class="circle"></span>
            </span>
          </button>
          <button mat-menu-item class="logout" (click)="logout()">
            <span>Kijelentkezés</span>
            <payee-icon
              class="menu-icon"
              name="logout-red"
              height="20px"
            ></payee-icon>
          </button>
        </mat-menu>

        <div class="user-name-box flex vcenter">
          <payee-icon name="pwc-avatar-outline"></payee-icon>
          <div class="pwc-user-name">{{ pwcUserName }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
