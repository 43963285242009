import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import packageJSON from 'src/../package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  constructor() { }

  readonly environment = environment;

  get versionNumber() {
    return packageJSON.version;
  }
}
