import { Injectable } from '@angular/core';

import { Debtor } from 'src/types';
import { ClientsService } from '../clients/clients.service';
import { environment } from 'src/environments/environment';
import axios from 'axios';

export type GetDebtorsParams = {
  name?: string;
};

export type GetDebtorsResponse = {
  debtors: {
    name: string;
  }[];
};

@Injectable({
  providedIn: 'root'
})
export class DebtorsService {
  public isLoading = 0;

  constructor(
    private clientsService: ClientsService,
  ) { }

  getDebtor(debtorId: string) {
    throw new Error('Unimplemented');
  }

  async getDebtorById(debtorId: string): Promise<Debtor> {
    throw new Error('Unimplemented');
  }

  async getRelevantDebtors(debtorIds: string[] = []) {
    throw new Error('Unimplemented');
  }

  async updateDebtor(debtorId: string, data: Partial<Debtor>) {
    throw new Error('Unimplemented');
    // this.isLoading += 1;
    // this.isLoading -= 1;
  }

  async updateDebtorWithCompanySearchData(debtorId: string): Promise<Debtor> {
    throw new Error('Unimplemented');
  }

  async deleteDebtor(debtorId: string) {
    throw new Error('Unimplemented');
    // this.isLoading += 1;
    // this.isLoading -= 1;
  }

  async createDebtor(caseId: string) {
    throw new Error('Unimplemented');
    // this.isLoading += 1;
    // this.isLoading -= 1;
    // return result.data as Debtor;
  }

  async getDebtors(params: GetDebtorsParams, clientId = this.clientsService.selectedClientId): Promise<GetDebtorsResponse> {
    const url = environment.baseUrl + `/api/client/${clientId}/debtor`;
    const result = await axios.get<GetDebtorsResponse>(url, {
      params,
    });
    return result.data;
  }
}
