<div class="container">
  <div class="box" [ngClass]="{'selected': type === 'notice'}" (click)="onSelectionChange('notice')">
    <div class="soft-icon icon">
      <div class="icon-text">Soft</div>
    </div>
    <div class="top-margin-20">
      <payee-icon [name]="'soft-icon'" width="auto" height="80px"></payee-icon>
    </div>
    <div class="title top-margin-20" [ngClass]="{'selected-title': type === 'notice'}">
      Fizetési felszólítás
    </div>
    <div class="fees top-margin-10">
      <div>
        <span [ngClass]="{'selected-fees': type === 'notice'}">
          15% sikerdíj
        </span>
        vagy
        <span [ngClass]="{'selected-fees': type === 'notice'}">
          1 290 Ft
        </span>
      </div>
    </div>
    <div class="description top-margin-15" [ngClass]="{'selected-description': type === 'notice'}">
      Digitális felszólító üzenetek sorozata a Payee neve alól emailben és SMS-ben
    </div>
    <div class="label top-margin-15" [ngClass]="{'selected-label': type === 'notice'}">
      <div class="label-text" [ngClass]="{'selected-label-text': type === 'notice'}">
        Magas megnyitási arány
      </div>
    </div>
  </div>

  <!--medium-->
  <div class="box" [ngClass]="{'selected': type === 'demand_letter'}" (click)="onSelectionChange('demand_letter')">
    <div class="medium-icon icon">
      <div class="icon-text">Medium</div>
    </div>
    <div class="top-margin-20">
      <payee-icon [name]="'demand-letter-icon'" width="auto" height="80px"></payee-icon>
    </div>
    <div class="title top-margin-20" [ngClass]="{'selected-title': type === 'demand_letter'}">
      Ügyvédi levél
    </div>
    <div class="fees top-margin-10">
      <div [ngClass]="{'selected-fees': type === 'demand_letter'}">
        8 490 Ft
      </div>
    </div>
    <div class="description top-margin-15" [ngClass]="{'selected-description': type === 'demand_letter'}">
      Felszámolásban is használható tértivevényes ügyvédi felszólító levél
    </div>
    <div class="label top-margin-15" [ngClass]="{'selected-label': type === 'demand_letter'}">
      <div class="label-text" [ngClass]="{'selected-label-text': type === 'demand_letter'}">
        Ügyvéd által aláírva és postázva
      </div>
    </div>
  </div>

  <!--hard-->
  <div class="box" [ngClass]="{'selected': type === 'hard_1', 'opened': hardDropdownOpened}" (click)="onSelectionChange('hard_1')">
    <div class="hard-icon icon">
      <div class="icon-text">Hard</div>
    </div>
    <div class="top-margin-20">
      <payee-icon [name]="'hard-icon'" width="auto" height="80px"></payee-icon>
    </div>
    <div class="title top-margin-20" [ngClass]="{'selected-title': type === 'hard_1'}">
      Fizetési meghagyás
    </div>
    <div class="fees top-margin-10">
      <div [ngClass]="{'selected-fees': type === 'hard_1'}">
        24 990 Ft (+ 3% illeték)
      </div>
    </div>
    <div *ngIf="!hardDropdownOpened" class="closed-dropdown">
      <div class="closed-dropdown-text" [ngClass]="{'selected-closed-dropdown-text': type === 'hard_1'}">
        A végrehajtást is kezeljük
      </div>
      <div (click)="hardDropdownOpened=true">
        <payee-icon *ngIf="type === 'hard_1'" [name]="environment.ARROW_DOWN_ICON"></payee-icon>
        <payee-icon *ngIf="type !== 'hard_1'" [name]="'arrow-down-black'"></payee-icon>
      </div>
    </div>
    <div *ngIf="hardDropdownOpened" class="opened-dropdown top-margin-15">
      <div class="opened-dropdown-header">
        <div class="opened-dropdown-header-text">
          A végrehajtást is kezeljük
        </div>
        <div (click)="hardDropdownOpened=false">
          <payee-icon [name]="'arrow-up-black'"></payee-icon>
        </div>
      </div>
      <div class="opened-dropdown-text">
        A fizetési meghagyás jogerőre emelkedésekor a végrehajtás online elindítható, díja 24 990 Ft (+ 1% illeték)
      </div>
    </div>
    <div class="description top-margin-15" [ngClass]="{'selected-description': type === 'hard_1'}">
      Fizetési meghagyás kibocsátása közjegyző által és annak végrehajtása
    </div>
    <div class="label top-margin-15" [ngClass]="{'selected-label': type === 'hard_1'}">
      <div class="label-text" [ngClass]="{'selected-label-text': type === 'hard_1'}">
        Az egyedüli hivatalos jogi eszköz
      </div>
    </div>
  </div>
</div>
