import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';

export type DialogData = {
  transition_ids: {
    full_payment: string,
    partial_payment: string,
  },
  payee_case: {
    remaining_amount: number,
    currency: string,
  },
  claims: {
    uuid: string,
    force_checked: boolean;
    is_paid: boolean;
    remaining_amount: number,
    claim_type_label: string,
    optional_claims: {
      remaining_amount: number,
      claim_type_label: string,
    }[];
  }[];
};

export type TransitionParam = {
  amount: number;
  claim_uuids: string[];
};

type PaymentAmount = {
  amount: number;
  optional_claims: {
    amount: number;
  }[];
};

@Component({
  selector: 'app-manual-payment',
  templateUrl: './manual-payment.component.html',
  styleUrls: ['./manual-payment.component.scss']
})
export class ManualPaymentComponent implements OnInit, OnDestroy {
  dialogData!: DialogData;
  initLoading = false;

  readonly manualPaymentForm: FormGroup;
  paymentAmounts: PaymentAmount[] = [];
  private readonly destory = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ManualPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private workflowService: WorkflowService,
    private snackbar: MatSnackBar,
  ) {
    this.manualPaymentForm = this.fb.group({
      paymentAmount: '',
      selectedClaims: this.fb.array([], Validators.required),
    });
  }

  get isFullPayment(): boolean {
    const remainingAmount = this.dialogData?.payee_case?.remaining_amount ?? Infinity;
    const providedAmount = this.getNumberValueOfString(this.paymentAmount.value);

    return providedAmount >= remainingAmount;
  }
  get paymentAmount(): FormControl { return this.manualPaymentForm.get('paymentAmount') as FormControl; }
  get selectedClaims(): FormArray { return this.manualPaymentForm.get('selectedClaims') as FormArray; }

  async ngOnInit(): Promise<void> {
    try {
      this.initLoading = true;
      this.dialogData = await this.workflowService.getDialogData({
        caseId: this.data.caseId,
        transitionId: this.data.transitionId,
      }) as DialogData;

      this.dialogData.claims.forEach(claim => {
        if (claim.force_checked) {
          this.selectedClaims.push(this.fb.control(claim.uuid));
        }
      });
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt az adatok betöltésekor!', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
      return;
    } finally {
      this.initLoading = false;
    }

    this.updatePaymentAmountValidators();
    this.setPaymentAmounts();

    this.paymentAmount.valueChanges
      .pipe(
        takeUntil(this.destory),
        map(v => this.getNumberValueOfString(v).toLocaleString('hu-HU').replace(/^0*/, '')),
        distinctUntilChanged(),
      )
      .subscribe({
        next: (value: string) => {
          this.setPaymentAmounts();
          this.paymentAmount.patchValue(value, { emitEvent: false });
        },
      });

    if (this.dialogData!.transition_ids.full_payment === this.data.transitionId) {
      this.paymentAmount.patchValue(this.dialogData!.payee_case.remaining_amount);
    }
  }

  ngOnDestroy(): void {
    this.destory.next();
    this.destory.complete();
  }

  onCheckChange = (event: MatCheckboxChange): void => {
    const selectedClaims = this.selectedClaims.value;

    if (event.checked) {
      if (!selectedClaims.includes(event.source.value)) {
        this.selectedClaims.push(this.fb.control(event.source.value));
      }
    } else {
      for (let i = this.selectedClaims.length; i >= 0; --i) {
        if (this.selectedClaims.value[i] === event.source.value) {
          this.selectedClaims.removeAt(i);
        }
      }
    }

    this.updatePaymentAmountValidators();
    this.setPaymentAmounts();
  };

  readonly submit = () => {
    if (this.manualPaymentForm.invalid || !this.dialogData) {
      Object.values(this.manualPaymentForm.controls).forEach(control => control.markAsDirty());
      return;
    }

    const transitionId = this.isFullPayment ?
      this.dialogData.transition_ids.full_payment
      : this.dialogData.transition_ids.partial_payment;

    const params = this.getParams();

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId,
      params,
    };
    this.dialogRef.close(closeValue);
  };

  private updatePaymentAmountValidators() {
    const selectedClaims: DialogData['claims'] = this.selectedClaims.value
      .map((uuid: string) => this.dialogData!.claims?.find(claim => claim.uuid === uuid));

    const amount = selectedClaims.reduce((sum, claim) => {
      const optionalSum = claim.optional_claims.reduce((s, oc) => s + oc.remaining_amount, 0);
      return sum + claim.remaining_amount + optionalSum;
    }, 0);

    this.paymentAmount.setValidators([
      Validators.required,
      this.minValidator(1),
      this.maxValidator(amount),
    ]);

    if (this.selectedClaims.invalid) {
      this.paymentAmount.disable();
    } else {
      this.paymentAmount.enable();
    }
  }

  private minValidator(value: number) {
    return (control: AbstractControl) => {
      if (control.value === '') {
        return null;
      }

      const num = this.getNumberValueOfString(control.value);
      if (num < value) {
        return {
          min: {
            expected: value,
            actual: num,
          },
        };
      } else {
        return null;
      }
    };
  }

  private maxValidator(value: number) {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const num = this.getNumberValueOfString(control.value);
      if (num > value) {
        return {
          max: {
            expected: value,
            actual: num,
          },
        };
      } else {
        return null;
      }
    };
  }

  private setPaymentAmounts(): void {
    this.paymentAmounts = [];
    let remainingAmount = this.getNumberValueOfString(this.paymentAmount.value);
    this.dialogData?.claims?.concat().reverse().forEach(claim => {
      if (!this.selectedClaims.value.find(c => c === claim.uuid)) {
        this.paymentAmounts.push({
          amount: 0,
          optional_claims: claim.optional_claims.map(() => ({
            amount: 0,
          })),
        });
        return;
      }

      const optionalClaims = claim.optional_claims.concat()
        .reverse()
        .map(optionalClaim => {
          const paymentAmount = remainingAmount < optionalClaim.remaining_amount ?
            remainingAmount
            : optionalClaim.remaining_amount;
          remainingAmount -= paymentAmount;
          return {
            amount: paymentAmount
          };
        })
        .reverse();

      const paymentAmount = remainingAmount < claim.remaining_amount ?
        remainingAmount
        : claim.remaining_amount;
      remainingAmount -= paymentAmount;

      this.paymentAmounts.push({
        amount: paymentAmount,
        optional_claims: optionalClaims,
      });
    });
    this.paymentAmounts.reverse();
  }

  private getNumberValueOfString(str: string): number {
    if (typeof str === 'number') {
      return str;
    } else if (typeof str === 'string') {
      return parseInt(str.replace(/\D/g, '').slice(0, 8) || '0');
    } else {
      console.warn('Invalid number value', {
        type: typeof str,
      });
      return 0;
    }
  }

  private readonly getParams = (): TransitionParam => {
    const paymentAmount = this.getNumberValueOfString(this.paymentAmount.value);
    if (this.isFullPayment) {
      return {
        amount: paymentAmount,
        claim_uuids: this.dialogData.claims.map(c => c.uuid),
      };
    } else {
      return {
        amount: paymentAmount,
        claim_uuids: this.selectedClaims.value,
      };
    }
  };
}
