<div class="debtor-data-box-container">
  <div *ngIf="isDebtorEditing">
    <!--company search field-->
    <div class="top-margin-30">
      <payee-company-search-input-field
        [canBeInd]="true"
        [nameFormControl]="debtorFormService.nameFormControl"
        [isDeletable]="isDeletable()"
        [isSearchDisabled]="isSearchDisabled()"
        [width]="'600px'"
        [height]="'68px'"
        [placeholder]="'Keress cégre, vagy adj hozzá magánszemélyt'"
        (companySearchItemSelected)="onCompanySearchItemSelected($event)"
        (individualSelected)="onIndividualSelected($event)"
        (houseSelected)="onHouseSelected($event)"
        (delete)="onDelete()"
      >
      </payee-company-search-input-field>
    </div>

    <!--info box-->
    <div class="top-margin-30" *ngIf="!isSearchDisabled()">
      <payee-info [width]="'widest'">
        Keressen rá cégre, szervezetre, egyéni vállalkozóra az adatbázisunkban.
        Ha pedig magánszemély tartozik, csak gépelje be a teljes nevét.
      </payee-info>
    </div>

    <app-form-house
      *ngIf="debtorFormService.isHouseDebtor"
      [taxNumberFormControl]="debtorFormService.taxNumberFormControl"
      [registrationNumberFormControl]="
        debtorFormService.registrationNumberFormControl
      "
    >
    </app-form-house>

    <div
      *ngIf="
        debtorFormService.isHouseDebtor ||
        debtorFormService.isIndividualWithoutTaxNumber
      "
    >
      <!--address component title-->
      <div class="component-title top-margin-30">
        {{
          debtorFormService.debtorStepData.debtor_data.debtor.name
        }}
        kézbesítési címe
      </div>

      <!--address component-->
      <div class="top-margin-10">
        <app-form-address
          [countryFormControl]="debtorFormService.countryFormControl"
          [postcodeFormControl]="debtorFormService.postcodeFormControl"
          [cityFormControl]="debtorFormService.settlementFormControl"
          [streetFormControl]="debtorFormService.streetFormControl"
        >
        </app-form-address>
      </div>

      <!--info box-->
      <div class="top-margin-30">
        <payee-info [width]="'widest'">
          Erre a címre fogják kézbesiteni a fizetési meghagyást neki:
          {{ debtorFormService.nameFormControl.value }}. Lehet az adósnak az
          állandó lakóhelye vagy a tartózkodási helye, levezelési címe is.
        </payee-info>
      </div>
    </div>

    <div *ngIf="debtorFormService.isHouseDebtor">
      <!--representative title-->
      <div class="component-title top-margin-30">
        {{
          debtorFormService.debtorStepData.debtor_data.debtor.name
        }}
        képviselője
      </div>

      <!--representative search input field-->
      <div *ngIf="isRepEditing" class="top-margin-10">
        <payee-company-search-input-field
          [isHouseRep]="true"
          [nameFormControl]="debtorFormService.repNameFormControl"
          [isDeletable]="true"
          [width]="'600px'"
          [height]="'68px'"
          [placeholder]="'Keress rá...'"
          [isSearchDisabled]="
            debtorFormService.isHouseWithRepresentativeWithoutTaxNumber
          "
          (companySearchItemSelected)="onCompanyRepresentativeSelected($event)"
          (individualSelected)="onIndividualRepresentativeSelected($event)"
          (delete)="onRepresentativeDelete()"
        >
        </payee-company-search-input-field>
      </div>

      <!--for individual representatives-->
      <div
        *ngIf="
          debtorFormService.isHouseWithRepresentativeWithoutTaxNumber &&
          isRepEditing
        "
      >
        <!--fixed representative type-->
        <div class="top-margin-10">
          <payee-dropdown
            [formControl]="debtorFormService.repTypeFormControl"
            height="tall"
            width="widest"
          >
            <!--styling-->
            <ng-template #menuDisplayTemplate let-selected>
              <div class="flex vcenter" *ngIf="selected">
                <span class="grow1">{{ selected }}</span>
              </div>
            </ng-template>
          </payee-dropdown>
        </div>

        <!--representative address-->
        <div class="top-margin-10">
          <app-form-address
            [countryFormControl]="debtorFormService.repCountryFormControl"
            [postcodeFormControl]="debtorFormService.repPostcodeFormControl"
            [cityFormControl]="debtorFormService.repSettlementFormControl"
            [streetFormControl]="debtorFormService.repStreetFormControl"
          >
          </app-form-address>
        </div>
      </div>

      <!--completed representative search summarizing box-->
      <div *ngIf="!isRepEditing" class="top-margin-30">
        <payee-completed-search-box
          [name]="debtorFormService.repNameFormControl.value"
          [address]="debtorFormService.representativeAddress"
          [taxNumber]="debtorFormService.repTaxNumberFormControl.value"
          (deleteButtonClick)="onRepresentativeDelete()"
          (editButtonClick)="onRepresentativeEdit()"
        >
        </payee-completed-search-box>
      </div>
    </div>
  </div>
  <!--completed view-->
  <div *ngIf="!isDebtorEditing">
    <payee-completed-search-box
      [name]="debtorFormService.nameFormControl.value"
      [address]="debtorFormService.debtorAddress"
      [taxNumber]="debtorFormService.taxNumberFormControl.value"
      [isDeletable]="isDeletable()"
      [disabled]="!debtorFormService.isEditable"
      (editButtonClick)="onEdit()"
      (deleteButtonClick)="onDelete()"
    >
    </payee-completed-search-box>
  </div>
</div>
