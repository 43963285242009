import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CasesService, CasesTableData, GetCasesParams } from 'src/app/services/cases/cases.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { DebtorsService } from 'src/app/services/debtors/debtors.service';
import { environment } from '../../../../environments/environment';
import _ from 'lodash';

const FilterType = {
  payeeId: 'payeeId',
  debtorName: 'debtorName',
} as const;
type ValueOf<T> = T[keyof T];

@Component({
  selector: 'app-admin-cases',
  templateUrl: './admin-cases.component.html',
  styleUrls: ['./admin-cases.component.scss'],
})
export class AdminCasesComponent implements OnInit, OnDestroy {
  readonly tabs = {
    draft: 0,
    ongoing: 1,
    closed: 2,
  } as const;

  selectedTab: ValueOf<typeof this.tabs> = this.tabs.ongoing;
  get showDrafts() { return this.selectedTab === this.tabs.draft; }
  get showOngoing() { return this.selectedTab === this.tabs.ongoing; }
  get showClosed() { return this.selectedTab === this.tabs.closed; }

  // TODO Fix any
  private get client(): any | null { return this.clientsService.selectedClientSubject.value; }
  get draftCount(): number { return this.client?.draft_cases || 0; }
  get ongoingCount(): number { return this.client?.ongoing_cases || 0; }
  get closedCount(): number { return this.client?.closed_cases || 0; }

  readonly filter: FormControl;
  readonly filterTypeOptions = Object.values(FilterType);
  readonly filterType: FormControl;

  searchedDebtorNames: string[] = [];
  selectedDebtor: string;

  loading = true;

  tableData: CasesTableData[] = [];
  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;

  private readonly setCasesSub = new Subject<void>();
  private readonly destroy = new Subject<void>();

  get hasCases(): boolean { return this.tableData.length > 0; }

  constructor(
    private casesService: CasesService,
    private clientsService: ClientsService,
    private debtorsService: DebtorsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private titleService: Title,
    public dialog: MatDialog,
    public helpersService: HelpersService,
  ) {
    this.filter = this.fb.control('');
    this.filterType = this.fb.control(FilterType.payeeId);
  }

  async ngOnInit(): Promise<void> {
    this.titleService.setTitle('Ügyeim - Real-time Debt Management Tool');

    this.setCasesSub
      .pipe(
        takeUntil(this.destroy),
        filter(() => !!this.clientsService.selectedClientId),
        map(() => ({
          params: this.getCasesParams(),
          selectedClientId: this.clientsService.selectedClientId,
        })),
        distinctUntilChanged((x, y) => _.isEqual(x, y)),
      )
      .subscribe({
        next: async () => await this.setData(),
      });

    this.filter.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(1000), distinctUntilChanged())
      .subscribe(async value => {
        if (this.filterType.value === FilterType.debtorName) {
          if (value) {
            await this.setDebtorFilterNames();
          } else {
            this.selectedDebtor = null;
          }
        }
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { filter: value || null },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

        this.setCasesSub.next();
      });

    this.filterType.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(1000), distinctUntilChanged())
      .subscribe(async value => {
        if (value !== FilterType.debtorName) {
          this.selectedDebtor = null;
        } else {
          await this.setDebtorFilterNames();
        }

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { filterType: value || null },
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });

        this.setCasesSub.next();
      });

    this.clientsService.selectedClientSubject
      .pipe(
        takeUntil(this.destroy),
        filter(v => !!v),
        distinctUntilChanged((a, b) => a.id === b.id),
      )
      .subscribe({
        next: () => this.setCasesSub.next(),
      });

    const filterType = this.route.snapshot.queryParamMap.get('filterType') as any;
    if (this.filterTypeOptions.includes(filterType)) {
      this.filterType.patchValue(filterType);
    } else {
      this.filterType.patchValue(FilterType.payeeId);
    }

    const filterQueryParam = this.route.snapshot.queryParams.filter;
    if (filterQueryParam) {
      this.filter.patchValue(filterQueryParam);
    }

    const filterTypeQueryParam = this.route.snapshot.queryParams.filterType;
    if (this.filterTypeOptions.includes(filterTypeQueryParam)) {
      this.filterType.patchValue(filterTypeQueryParam);
    }

    const stateQueryParam = this.route.snapshot.queryParams.state;
    const stateTab = Object.entries(this.tabs).find(tab => tab[0] === stateQueryParam);
    if (stateTab) {
      this.selectedTab = stateTab[1];
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  navigateToTab(event: MatTabChangeEvent) {
    const state = Object.entries(this.tabs).filter(tab => tab[1] === event.index)[0][0] as keyof typeof this.tabs;

    // When we change tabs 2 things can be happenning
    // 1. We want to check out a different tab
    //    - In this case the id search should be clear
    // 2. We have searched for a case by its id and it should be shown in a different tab
    //    - In this case the id search should stay
    if (this.filterType.value === FilterType.payeeId && this.tabs[state] !== this.selectedTab) {
      // id should match exactly 1 case
      if (this.tableData.length !== 1 || this.tableData[0].id !== this.filter.value) {
        this.filter.patchValue(null);
      }
    }
    this.selectedTab = event.index as any;
    this.router.navigate(['/user/cases'], {
      relativeTo: this.route,
      queryParams: { filter: this.filter.value, filterType: this.filterType.value, state },
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
    this.setCasesSub.next();
  }

  filterTypeLabel(option: typeof this.filterTypeOptions[number]): string {
    switch (option) {
      case 'debtorName': return 'Adós neve';
      case 'payeeId': return 'Ügyazonosító';
      default: return option;
    }
  }

  readonly setPageSize = (n: number) => {
    this.pageSize = n;
    this.setCasesSub.next();
  };

  readonly setPageIndex = (n: number) => {
    this.pageIndex = n;
    this.setCasesSub.next();
  };

  readonly onDebtorFilterSelected = (debtor: string) => {
    this.selectedDebtor = debtor;

    this.setCasesSub.next();
  };

  readonly onDeleted = async () => {
    this.filter.setValue(null);
    await this.setData();
  };

  filterCase(id: string): void {
    this.filterType.patchValue(FilterType.payeeId);
    this.filter.patchValue(id);
  }

  private async setDebtorFilterNames(): Promise<void> {
    try {
      const result = await this.debtorsService.getDebtors({
        name: this.filter.value,
      });
      this.searchedDebtorNames = result.debtors.map(debtor => debtor.name);
    } catch (error) {
      console.error('Failed to get debtors', error);
      this.snackbar.open('Nem sikerült betölteni az adósokat!', 'OK', {
        duration: 5000,
      });
    }
  }

  private async setData(): Promise<void> {
    try {
      if (!this.clientsService.selectedClientId) {
        return;
      }
      this.loading = true;

      const params = this.getCasesParams();

      const [result] = await Promise.all([
        this.casesService.getCases(params),
        this.clientsService.setClients(),
      ]);

      if (params.payee_case_reference_id && result.data.length > 0) {
        this.selectedTab = this.tabs[result.data[0].state.stage];
      }

      this.length = result.pagination.total;
      this.tableData = result.data;
    } catch (error) {
      console.error('Error while loading cases', error);
      this.snackbar.open('Valami hiba történt az ügyek betöltésekor!', 'OK', {
        duration: 5000,
      });
    } finally {
      this.loading = false;
    }
  }

  readonly environment = environment;

  private getCasesParams(): GetCasesParams {
    const stage = Object.entries(this.tabs)
      .find(tab => tab[1] === this.selectedTab)[0] as keyof typeof this.tabs;

    const debtor_name = this.filterType.value === FilterType.debtorName ?
      this.selectedDebtor || undefined
      : undefined;

    const payee_case_reference_id = this.filterType.value === FilterType.payeeId ?
      this.filter.value || undefined
      : undefined;

    return {
      debtor_name,
      payee_case_reference_id,
      stage: payee_case_reference_id ? undefined : stage,
      page: this.pageIndex + 1,
      per_page: this.pageSize,
    };
  }
}
