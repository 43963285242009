import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoClientAttachedComponent } from './no-client-attached.component';

import { HeaderModule as PwcHeaderModule } from '@appkit4/angular-components/header';
import { ButtonModule as PwcButtonModule } from '@appkit4/angular-components/button';
import { AvatarModule as PwcAvatarModule } from '@appkit4/angular-components/avatar';
import { FooterModule } from 'src/app/shared/footer/footer.module';

@NgModule({
  declarations: [
    NoClientAttachedComponent,
  ],
  imports: [
    CommonModule,
    PwcHeaderModule,
    PwcButtonModule,
    PwcAvatarModule,
    FooterModule,
  ],
})
export class NoClientAttachedModule { }
