import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OauthRedirectComponent } from './oauth-redirect.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoadingScreenModule } from '../../loading-screen/loading-screen.module';

@NgModule({
  declarations: [
    OauthRedirectComponent
  ],
  exports: [
    OauthRedirectComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    LoadingScreenModule,
  ],
})
export class OauthRedirectModule { }
