<div class="payee-header-wrapper">
  <ng-template #logoTemplate>
    <!--<img src="assets/shared/global-images/payee-logos/payee-logo-black-blue.svg" width="100" height="30">-->
    <img src="assets/shared/global-images/partner-logos/pwc-logo.svg" height="50">
  </ng-template>

  <div class="payee-header-logo">
    <ng-container [ngTemplateOutlet]="headerLogoTemplateRef || logoTemplate"></ng-container>
  </div>
  <div class="payee-header-content">
    <ng-content></ng-content>
  </div>
  <div class="payee-header-button">
    <ng-container *ngIf="headerButtonTemplateRef">
      <payee-vertical-line style="height: 30px"></payee-vertical-line>
      <ng-container [ngTemplateOutlet]="headerButtonTemplateRef"></ng-container>
    </ng-container>
  </div>
</div>
