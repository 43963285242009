<payee-popup title="Kontakt lista feltöltése">
  <ng-container *ngIf="!uploadResult || uploadResult.stats.error > 0">
    <payee-dropzone
      title="Töltse fel az Excel fájlt"
      description=""
      (dropped)="dropped($event)"
      [multiple]="false"
    ></payee-dropzone>

    <div class="file" *ngIf="!!file">{{ file.name }}</div>

    <div *ngIf="uploadResult" class="upload-result">
      <h2>Riport</h2>
      <div class="upload-result-stats">
        <div class="flex vcenter">
          <span>Helyesen kitöltött sorok száma:</span>
          <span class="success-count"
            >&nbsp;{{ uploadResult.stats.success }}&nbsp;</span
          >
          <span>/ {{ uploadResult.stats.all }}</span>
        </div>
        <div class="flex vcenter">
          <span>Rosszul kitöltött sorok száma:</span>
          <span class="error-count"
            >&nbsp;({{ uploadResult.stats.error }})</span
          >
        </div>
      </div>
      <table class="upload-result-errors">
        <tr>
          <th style="width: 75px">id</th>
          <th style="width: 175px">Supplier Tax Number</th>
          <th style="width: 150px">Invoice number</th>
          <th style="width: 100px">Fizetési határidő</th>
          <th style="width: 100px">Email</th>
          <th style="width: 100px">Telefonszám</th>
          <th>reason</th>
        </tr>
        <tr
          class="upload-result-error-row"
          *ngFor="let row of uploadResult.errors | keyvalue"
        >
          <td>{{ row.key }}. sor</td>
          <td>{{ row.value.data.supplier_tax_number }}</td>
          <td>{{ row.value.data.invoice_number }}</td>
          <td>{{ row.value.data.due_date_at }}</td>
          <td>{{ row.value.data.email }}</td>
          <td>{{ row.value.data.phone }}</td>
          <td class="upload-result-error-row-list flex column">
            <span *ngFor="let error of row.value.reasons">{{ error }}</span>
          </td>
        </tr>
      </table>
    </div>

    <ng-template #payeePopupSubmitTemplate>
      <button
        *ngIf="!!file"
        payee-button
        color="blue-payee"
        [disabled]="loading"
        [isRoundButton]="false"
        width="wide"
        id="confirm-button"
        (click)="submit()"
      >
        Feltöltés
      </button>
    </ng-template>

    <ng-template #payeePopupCancelTemplate>
      <button
        payee-button
        color="alert-red"
        [isRoundButton]="false"
        width="wide"
        id="cancel-button"
        mat-dialog-close
      >
        Mégse
      </button>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="uploadResult?.stats?.error === 0">
    <h2 class="success-message">Minden sor sikeresen fel lett dolgozva!</h2>

    <ng-template #payeePopupCancelTemplate>
      <button
        payee-button
        color="blue-payee"
        [isRoundButton]="false"
        width="wide"
        id="cancel-button"
        [mat-dialog-close]="true"
      >
        Bezárás
      </button>
    </ng-template>
  </ng-container>
</payee-popup>
