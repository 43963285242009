import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNotFoundComponent } from './user-not-found.component';

import { HeaderModule as PwcHeaderModule } from '@appkit4/angular-components/header';
import { ButtonModule as PwcButtonModule } from '@appkit4/angular-components/button';
import { AvatarModule as PwcAvatarModule } from '@appkit4/angular-components/avatar';
import { FooterModule } from '../footer/footer.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    UserNotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    PwcHeaderModule,
    PwcButtonModule,
    PwcAvatarModule,
    FooterModule,
  ]
})
export class UserNotFoundModule { }
