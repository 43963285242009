<!-- <div class="wrapper flex">
  <app-sidebar></app-sidebar>
  <div class="content grow1 flex column">
    <div class="grow1">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div> -->

<header>
  <ap-header [type]="'solid'">
    <ng-template ngTemplate="title">Real-time Debt Management Tool</ng-template>
    <ng-template ngTemplate="options">
      <ap-dropdown
        [list]="clients"
        [selectType]="'single'"
        (onSelect)="onSelect($event)"
        [hideTitleOnInput]="true"
        [ngModel]="{ label: clientName }"
      >
        <ap-dropdown-list-item
          *ngFor="let item of clients"
          #dropdownListItem
          [item]="item"
        >
        </ap-dropdown-list-item>
      </ap-dropdown>
      <ap-button [icon]="'logout-fill'" (onClick)="logout()"></ap-button>
    </ng-template>
    <ng-template ngTemplate="user">
      <ap-avatar
        [name]="userName"
        borderWidth="0"
        diameter="40"
        [role]="'button'"
        (onClick)="navigateToProfile()"
      ></ap-avatar>
    </ng-template>
  </ap-header>
</header>

<div class="wrapper flex">
  <app-sidebar></app-sidebar>
  <div class="content grow1 flex column">
    <div class="grow1">
      <router-outlet></router-outlet>
    </div>
    <footer>
      <app-footer></app-footer>
    </footer>
  </div>
</div>
