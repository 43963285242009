import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RunTransition, WorkflowService } from 'src/app/shared/services/workflow/workflow.service';
import { environment } from '../../../../environments/environment';

export type DialogData = {
  transition_label: string;
};

@Component({
  selector: 'app-attachment-no-deadline-dialog',
  templateUrl: './attachment-no-deadline-dialog.component.html',
  styleUrls: ['./attachment-no-deadline-dialog.component.scss']
})
export class AttachmentNoDeadlineDialogComponent implements OnInit {
  dialogData?: DialogData;

  readonly form: FormGroup;
  files: File[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: RunTransition,
    private dialogRef: MatDialogRef<AttachmentNoDeadlineDialogComponent>,
    private workflowService: WorkflowService,
    private fb: FormBuilder,
  ) {
    this.form = this.fb.group({
      message: [''],
    });
  }

  async ngOnInit() {
    this.dialogData = await this.workflowService.getDialogData({
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
    }) as DialogData;
  }

  onDrop(files: FileList) {
    for (let i = 0; i < files.length; ++i) {
      this.files.push(files.item(i) as File);
    }
  }

  removeFile(i: number) {
    this.files.splice(i, 1);
  }

  readonly onSubmit = async () => {
    if (this.form.invalid) {
      return;
    }

    const closeValue: RunTransition = {
      caseId: this.data.caseId,
      transitionId: this.data.transitionId,
      params: {
        files: this.files,
        message: this.form.value.message,
      },
    };

    this.dialogRef.close(closeValue);
  }

  readonly onCancel = () => {
    this.dialogRef.close();
  }

  readonly environment = environment;
}
