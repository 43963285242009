<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="pencil"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>Nyilatkozás - Teljes kiegyenlítés</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <span class="description">
      Az adós jelezte, hogy befizette az összes tartozását. Kérjük, nyilatkozzon
      arról, hogy valóban a lejárt követeléseket az eljárással kapcsolatos
      költségeit is befizette-e.
    </span>
  </ng-template>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit(true)"
    >
      <ng-container>A teljes követelés meg lett fizetve</ng-container>
    </button>
    <button
      class="payee-button"
      payee-button
      color="alert-red"
      width="wide"
      height="normal"
      (click)="submit(false)"
    >
      <ng-container>Nem lett a teljes követelés megfizetve</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      [mat-dialog-close]="false"
      color="white"
      width="wide"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
