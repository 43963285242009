<ap-navigation
  [title]="'Felhasználói kézikönyv'"
  [avatarName]="userGuideService.userName"
  [width]="'360px'"
  [solid]="false"
  [hasHeader]="true"
  [hasFooter]="false"
  [navList]="navList"
  [selected]="selected"
  (onClick)="redirect($event)"
>
</ap-navigation>
