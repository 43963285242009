<header>
  <ap-header [type]="'solid'">
    <ng-template ngTemplate="title">Real-time Debt Management Tool</ng-template>
    <ng-template ngTemplate="options">
      <ap-button [icon]="'logout-fill'" (onClick)="logout()"></ap-button>
    </ng-template>
    <ng-template ngTemplate="user">
      <ap-avatar
        [name]="userName"
        borderWidth="0"
        diameter="40"
        [role]="'button'"
      ></ap-avatar>
    </ng-template>
  </ap-header>
</header>

<div class="wrapper grow1 flex vcenter hcenter">
  <h1 class="error-message">
    Ön jelenleg nincs egyik hitelezőhöz sem rendelve, kérjük vegye fel a
    kapcsolatot az ügyfélszolgálattal
  </h1>
</div>

<footer>
  <app-footer></app-footer>
</footer>
