<div class="wrapper">
  <ng-container *ngIf="!caseLogs && this.isSpinnerEnabled">
    <mat-progress-spinner
      diameter="100"
      mode="indeterminate"
    ></mat-progress-spinner>
  </ng-container>
  <ng-container *ngIf="!!caseLogs">
    <a class="show-all-events" (click)="showAllLogs = !showAllLogs">
      <ng-container *ngIf="!showAllLogs">
        Összes esemény mutatása
      </ng-container>
      <ng-container *ngIf="showAllLogs">
        Kevesebb esemény mutatása
      </ng-container>
    </a>
    <ng-container *ngFor="let caseLog of logsOfExpandedElement">
      <div class="event-date" #eventDate>
        {{ caseLog.happenedAt | date : "yyyy.MM.dd. HH:mm" }}
      </div>
      <div
        class="event-dot"
        [class.done]="isEventDone(caseLog)"
        [class.first]="isEventFirstDone(caseLog) || isEventFirstFuture(caseLog)"
        [class.error]="caseLog?.status === 'error'"
      ></div>
      <div class="event-label" [class.lastDone]="isEventLastDone(caseLog)">
        {{ caseLog.label }}
      </div>
      <div *ngIf="!!caseLog.message" class="event-message">
        Megjegyzés: {{caseLog.message}}
      </div>
      <div *ngIf="caseLog.files?.length" class="transition-files">
        <div class="transition-file-title" color="grey-dark">
          Az eseményhez kapcsolódó dokumentumok:
        </div>
        <a
          *ngFor="let file of caseLog.files"
          [href]="downloadUrlOfFile(file)"
          download
          target="_blank"
          class="transition-file flex vcenter"
          color="blue-king"
        >
          <span>{{ file.file_name }}</span>
          <payee-icon
            [name]="environment.EXTERNAL_LINK_ICON"
            height="15px"
          ></payee-icon>
        </a>
      </div>
    </ng-container>
  </ng-container>
</div>
