import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwcInvoiceDialogComponent } from './pwc-invoice-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PayeeButtonsModule } from 'src/app/shared/payee-buttons/payee-buttons.module';
import { TooltipModule } from '@appkit4/angular-components/tooltip';
import { PayeeIconModule } from 'src/app/shared/payee-icon/payee-icon.module';



@NgModule({
  declarations: [
    PwcInvoiceDialogComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    PayeeButtonsModule,
    TooltipModule,
    PayeeIconModule,
  ]
})
export class PwcInvoiceDialogModule { }
