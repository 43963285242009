<div id="page-container">
  <div id="content-wrap">
    <app-form-selector-header></app-form-selector-header>

    <div class="flex column vcenter top-margin-60">
      <payee-info *ngIf="isSzamlazzhu" id="szamlazzhu-warning">
        <div>
          Felhívjuk a figyelmedet, hogy ez most egy Payee ügyindítás lesz, azaz
          kézzel kell feltöltened a számláidat. Ha szeretnéd kényelmesebben
          elindítani az ügyedet, akkor indíts el közvetlenül a Számlázz.hu-ból:
          <a class="szamlazzhu-link" color="blue-king" [href]="szamlazzhuUrl">
            Tovább a Számlázz.hu fiókomba
          </a>
        </div>
      </payee-info>

      <app-type-selector
        [class.top-margin-50]="isSzamlazzhu"
        (selectedType)="onSelectedTypeChange($event)"
      ></app-type-selector>
    </div>

    <!--help text-->
    <a
      *ngIf="!environment.PWC"
      class="help-container top-margin-30"
      href="https://help.payee.tech/hu/articles/6423716-melyik-modult-valasszam"
      target="_blank"
    >
      <div class="help-icon">
        <payee-icon
          width="19px"
          height="19px"
          [name]="environment.EXTERNAL_LINK_ICON"
        ></payee-icon>
      </div>
      <div class="help-text">Nem tudod, mit válassz? Segítünk!</div>
    </a>

    <!--button-->
    <div class="top-margin-60 next-button">
      <div class="button">
        <button
          payee-button
          [loading]="loading"
          (click)="onNextButtonClick()"
          height="tall"
          width="wide"
        >
          <div class="arrow-button flex vcenter hcenter">
            <span>Tovább az adatmegadásra</span>
            <payee-icon name="arrow-right-white"></payee-icon>
          </div>
        </button>
      </div>
    </div>

    <!--text-->
    <div class="text top-margin-30">
      A feltüntetett árak nettó árakat jelentenek. Ezekre 27% ÁFA-t számítunk fel.
    </div>
  </div>
  <!--<app-footer></app-footer>-->
</div>
