import { Injectable } from '@angular/core';
import { KsziUsefulData } from 'src/types';;
import { ClientsService } from '../clients/clients.service';

@Injectable({
  providedIn: 'root'
})
export class SzamlazzhuService {

  constructor(
    private clientsService: ClientsService,
  ) { }

  async getKsziInfo() {
    throw new Error('Unimplemented');
    // const client = await this.clientsService.getClient();
    // const getKsziInfo = httpsCallable<{ guid: string }, any>(getFunctions(getApp(), 'europe-west1'), 'getKsziInfo');
    // const result = await getKsziInfo({
    //   guid: client.yourOwnReference,
    // });
    // return result.data;
  }

  async getAvailableInvoices(debtorId: string) {
    throw new Error('Unimplemented');
    // const getAvailableInvoices = httpsCallable<unknown, KsziUsefulData[]>(getFunctions(getApp(), 'europe-west1'), 'getAvailableInvoices');
    // const result = await getAvailableInvoices({
    //   clientId: this.clientsService.selectedClientId,
    //   debtorId,
    // });
    // return result.data;
  }

  async getFileOfInvoice(invoice: KsziUsefulData): Promise<string> {
    throw new Error('Unimplemented');
    // const client = await this.clientsService.getClient();

    // const getFileOfInvoice = httpsCallable<{
		// 	guid: string,
		// 	invoiceId: string
		// }, string>(getFunctions(getApp(), 'europe-west1'), 'getFileOfInvoice');

    // const result = await getFileOfInvoice({
    //   guid: client.yourOwnReference,
    //   invoiceId: invoice.szlhuId,
    // });
    // return result.data;
  }
}
