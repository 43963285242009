<div class="wrapper flex column">
  <!-- <app-header title="Profil"></app-header> -->
  <div class="content flex column grow1" *ngIf="!loading">
    <!-- <div class="auto-save-box flex vcenter" [class.show]="saving">
      <img src="assets/shared/global-images/icons/dot-green.svg" />
      <span>AUTOMATIKUSAN MENTVE</span>
    </div> -->

    <!-- <div class="user-management-box flex">
      <button mat-button class="avatar flex hcenter vcenter">
        {{ userInitials }}
      </button>

      <form class="user-form flex column grow1" [formGroup]="userForm">
        <payee-form-field>
          <div payee-label>Vezetékneved</div>
          <input payee-input formControlName="lastName" />
          <mat-error
            payee-error
            *ngIf="lastName.hasError('required') && lastName.dirty"
          >
            A mezőt kötelező kitölteni!
          </mat-error>
        </payee-form-field>

        <payee-form-field>
          <div payee-label>Utóneved</div>
          <input payee-input formControlName="firstName" />
          <mat-error
            payee-error
            *ngIf="firstName.hasError('required') && firstName.dirty"
          >
            A mezőt kötelező kitölteni!
          </mat-error>
        </payee-form-field>

        <payee-form-field>
          <div payee-label class="payee-label-top">Email címed</div>
          <input readonly payee-input formControlName="email" />
        </payee-form-field>
      </form>

      <div class="user-management-buttons-box flex column">
        <button
          [disabled]="!canChangePassword"
          mat-button
          class="change-pwd-button"
          (click)="changePassword()"
        >
          <div class="pwd-button-content">
            <div class="icon-wrapper">
              <payee-icon [name]="'pwc-lock'"></payee-icon>
            </div>
            <span class="button-text">Jelszó változtatása</span>
          </div>
        </button>
        <button
          *ngIf="!has2FA"
          mat-button
          class="enable-2fa-button"
          (click)="enable2FA()"
        >
          <span>2FA bekapcsolása</span>
        </button>
      </div>
    </div> -->

    <div class="clients-title">Hitelezői fiókok</div>

    <div class="clients-list">
      <ng-container *ngFor="let client of clients; let i = index">
        <payee-horizontal-line *ngIf="i > 0"></payee-horizontal-line>
        <div class="client-name flex vcenter">{{ client.name }}</div>
        <div class="client-type flex vcenter"></div>
        <button
          mat-button
          class="client-settings"
          (click)="navigateToClientProfile(client)"
        >
          <div class="client-settings-content flex vcenter hcenter">
            <span>Fiók beállításai</span>
            <img src="assets/shared/global-images/icons/gear.svg" />
          </div>
        </button>
      </ng-container>
    </div>

    <!-- <button
      mat-button
      class="new-client-button"
      (click)="onAddNewClientButtonClick()"
    >
      <div class="new-client-button-content flex hcenter vcenter">
        <img
          src="assets/shared/global-images/icons/plus-white.svg"
          width="14px"
          height="14px"
        />
        <span>Új Hitelezői fiók létrehozása</span>
      </div>
    </button> -->
    <!-- <div class="margin-top"></div> -->
  </div>
  <!-- <div class="content grow1 flex vcenter hcenter" *ngIf="loading">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="100">
    </mat-progress-spinner>
  </div> -->
</div>
