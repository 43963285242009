<div class="dialog-container">
  <!--close icon-->
  <div class="close">
    <payee-icon
      class="pointer"
      [name]="'cross-black'"
      (click)="onClose()"
    ></payee-icon>
  </div>

  <!--title-->
  <div class="new-big-title top-margin-10">Kommunikáció</div>

  <!--description-->
  <div class="new-big-description top-margin-20">
    Adja meg, hogy milyen elérhetőségeken és milyen módon kommunikáljunk
    {{ debtorFormService.debtorStepData?.debtor_data?.debtor?.name ?? "" }}
    adósával
  </div>

  <!--communication data box-->
  <div class="top-margin-10">
    <app-communication-data-box></app-communication-data-box>
  </div>

  <!--save button-->
  <div class="close top-margin-30">
    <button
      payee-button
      [loading]="communicationFormService.saveLoading"
      (click)="onSave()"
      width="shorter"
      height="tall"
    >
      Mentés
    </button>
  </div>
</div>
