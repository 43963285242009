import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DebtorFormService } from "../../services/form-services/debtor-form-service";
import { LanguageFormService } from '../../services/form-services/language-form-service';

@Component({
  selector: 'app-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: ['./language-dialog.component.scss']
})
export class LanguageDialogComponent implements OnInit {
  constructor(
    public languageFormService: LanguageFormService,
    public debtorFormService: DebtorFormService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Kommunikáció - Real-time Debt Management Tool');
  }

  onClose() {
    this.languageFormService.openExitDialog();
  }

  async onSave() {
    await this.languageFormService.saveData();
  }
}
