<div class="dialog-container">
  <!--close icon-->
  <div class="close">
    <payee-icon
      class="pointer"
      [name]="'cross-black'"
      (click)="onClose()"
    ></payee-icon>
  </div>

  <!--title-->
  <div class="new-big-title top-margin-10">Számlák kiválasztása</div>

  <!--description-->
  <div class="new-big-description top-margin-20">
    {{ description }}
  </div>

  <table
    mat-table
    matSort
    (matSortChange)="sortData($event)"
    [dataSource]="dataSource"
    class="mat-elevation-z1 top-margin-20"
    multiTemplateDataRows
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="select-checkbox">
          <mat-checkbox
            [disabled]="element.can_create_payee_case !== true"
            color="primary"
            [checked]="isInvoiceSelected(element)"
            (change)="toggleInvoice(element)"
          ></mat-checkbox>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="invoice_number">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Számlaszám</th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoice_number }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Státusz</th>
      <td mat-cell *matCellDef="let element">
        <div
          class="flex vcenter invoice-status"
          [attr.color]="statusMap[element.status]?.color"
        >
          <span
            [class]="'Appkit4-icon ' + statusMap[element.status]?.icon"
          ></span>
          <span>{{ statusMap[element.status]?.label }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="due_date_at">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>
        Fizetési határidő
      </th>
      <td mat-cell *matCellDef="let element">
        <span>{{ element.due_date_at | date : "yyyy.MM.dd." }}</span>
        <span
          *ngIf="showDemandLetterWarning(element)"
          class="Appkit4-icon icon-alert-outline"
          ap-tooltip
          tooltipStyle="white-space: pre"
          direction="top"
          [tooltipContent]="getDemandLetterWarningContent(element)"
        ></span>
      </td>
    </ng-container>

    <ng-container matColumnDef="remaining_amount">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Bruttó összeg</th>
      <td mat-cell *matCellDef="let element">
        {{ element.remaining_amount | number : "1.0" : "hu" }}
        {{ element.currency_iso }}
      </td>
    </ng-container>

    <ng-container matColumnDef="can_create_payee_case">
      <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <span
          *ngIf="element.can_create_payee_case !== true"
          class="Appkit4-icon icon-alert-outline ap-font-24"
          ap-tooltip
          [direction]="'top'"
          [tooltipContent]="getCanCreatePayeeCaseMessage(element)"
        ></span>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"
      class="main-header"
    ></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row mat-elevation-z2"
    ></tr>
  </table>

  <mat-paginator
    [pageSize]="pageSize"
    [pageIndex]="page"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [length]="length"
    (page)="paginateData($event)"
    disableUnderline
    class="mat-elevation-z1"
  ></mat-paginator>

  <!--save button-->
  <div class="close top-margin-30">
    <button
      payee-button
      [loading]="pwcInvoiceFormService.saveLoading"
      [disabled]="isSaveDisabled"
      (click)="saveData()"
      width="shorter"
      height="tall"
    >
      Mentés
    </button>
  </div>
</div>
