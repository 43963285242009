<div class="wrapper">
  <!-- <app-header title="Számlák"></app-header> -->
  <div class="content flex column">
    <div class="flex vcenter filter-box">
      <payee-form-field>
        <input
          payee-input
          placeholder="Keressen rá a számlára"
          [formControl]="filter"
        />
      </payee-form-field>
      <mat-checkbox [formControl]="showPaid" color="primary">
        Kifizetett számlák mutatása
      </mat-checkbox>
      <div class="excel-import flex vcenter">
        <button
          payee-button
          width="short"
          [matMenuTriggerFor]="paymentExcelMenu"
        >
          Befizetések rögzítése
        </button>
        <mat-menu #paymentExcelMenu="matMenu">
          <button
            mat-menu-item
            (click)="getPaymentExcel()"
            [disabled]="data.length === 0"
          >
            <span>Minta excel letöltése</span>
          </button>
          <button mat-menu-item (click)="uploadPaymentExcel()">
            <span>Kitöltött excel feltöltése</span>
          </button>
        </mat-menu>
        <button
          payee-button
          width="short"
          [matMenuTriggerFor]="contactExcelMenu"
        >
          Kontakt lista
        </button>
        <mat-menu #contactExcelMenu="matMenu">
          <button
            mat-menu-item
            (click)="getContactExcel()"
            [disabled]="data.length === 0"
          >
            <span>Lista excel letöltése</span>
          </button>
          <button mat-menu-item (click)="uploadContactExcel()">
            <span>Kitöltött excel feltöltése</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <app-pwc-invoices-table
      *ngIf="showTable"
      [data]="data"
      [pageIndex]="page"
      [pageSize]="page_size"
      [length]="length"
      (page)="pageChange($event)"
      (sort)="sortData($event)"
    ></app-pwc-invoices-table>
    <div class="loading-spinner alt-theme" *ngIf="showTableSpinner">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="100"
      ></mat-progress-spinner>
    </div>
    <div class="no-invoices flex grow1 column vcenter" *ngIf="showNoInvoices">
      <!-- <img
        src="assets/shared/global-images/big-svgs/welcome.svg"
        style="width: 400px; height: 300px"
      /> -->
      <span class="no-invoices-text" color="grey-dark"
        >Nem található számla</span
      >
    </div>
  </div>
</div>
