<footer class="flex column">
  <div class="flex vcenter links">
    <a href="/user-guide" target="_blank" color="black" class="user-guide">Felhasználói Kézikönyv</a>
    <div color="black" class="user-guide">|</div>
    <div color="black" class="user-guide">v{{ versionNumber }}</div>
  </div>
  <span class="copyright-notice">
    © 2024 PwC. Réti, Várszegi és Társai Ügyvédi Iroda. Minden jog fenntartva. A
    Réti, Várszegi és Társai Ügyvédi Iroda a PricewaterhouseCoopers
    International Limited tagja, melyet önálló és független jogi személyiségű
    könyvvizsgáló, adó- és üzleti tanácsadó, valamint ügyvédi irodák alkotnak.
  </span>
</footer>
