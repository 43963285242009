import {Component} from '@angular/core';
import {ClaimFormService} from '../../../services/form-services/claim-form-service';
import { environment } from '../../../../../../../environments/environment';
import { ErrorSnackbarComponent } from '../../../../../../snackbars/error-snackbar/error-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-legal-base-data-box',
    templateUrl: './legal-base-data-box.component.html',
    styleUrls: ['./legal-base-data-box.component.scss']
})
export class LegalBaseDataBoxComponent {
    constructor(
        public claimFormService: ClaimFormService,
        private snackBar: MatSnackBar,
    ) {
    }

    toggleCheckbox() {
        this.claimFormService.isCommonCostBalanceFormControl.setValue(!this.claimFormService.isCommonCostBalanceFormControl.value);
        this.claimFormService.legalRelationshipExplanationFormControl.updateValueAndValidity();
        this.claimFormService.legalRelationshipExplanationFileFormArray.updateValueAndValidity();
    }

    filesSelected(fileList: FileList) {
        for (let i = 0; i < fileList.length; ++i) {
            if (fileList.item(i).size <= 10 * 1024 * 1024) { // 10 MB
                this.claimFormService.addLegalRelationshipFile({
                    uuid: null,
                    file_name: fileList.item(i).name,
                    file: fileList.item(i)
                })
                } else {
                    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
                        data: {
                          message: `A ${fileList.item(i).name} fájl meghaladta a 10 MB-os mérethatárt, ezért nem került feltöltésre!`,
                          action: 'Ok',
                        },
                    });
            }
        }
    }

    deleteFile(index: number) {
        this.claimFormService.deleteLegalRelationshipFile(index);
    }

    readonly environment = environment;
}
