<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <payee-icon
      name="house"
      class="title-icon"
      height="73px"
      width="auto"
    ></payee-icon>
    <h1>Befizetés rögzítése</h1>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      Itt tudja rögzíteni az ügyben történt befizetéseket.
    </div>
  </ng-template>

  <div
    class="flex hcenter init-spinner"
    *ngIf="initLoading"
  >
    <mat-progress-spinner
      diameter="100"
      color="accent"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>

  <form
    *ngIf="!initLoading"
    [formGroup]="manualPaymentForm"
    (submit)="submit()"
  >
    <payee-form-field
      width="max"
      height="taller"
    >
      <input
        class="full-payment"
        payee-input
        formControlName="paymentAmount"
      />
      <span payee-suffix>
        <img
          class="flag"
          src="assets/shared/global-images/flags/HU-rectangle.svg"
        />
      </span>
      <span
        class="currency"
        payee-suffix
        >HUF</span
      >

      <ng-container
        payee-error
        *ngIf="paymentAmount.dirty"
      >
        <mat-error *ngIf="paymentAmount.hasError('min')"
          >Az összegnek legalább 1 HUF-nak kell lennie</mat-error
        >
        <mat-error
          *ngIf="selectedClaims.valid && paymentAmount.hasError('max')"
        >
          Az összeg legfeljebb
          {{ paymentAmount.errors?.max?.expected | number : '1.0' : 'hu' }}
          {{ dialogData?.payee_case?.currency }} lehet
        </mat-error>
        <mat-error *ngIf="paymentAmount.hasError('required')"
          >A mezőt kötelező kitölteni</mat-error
        >
      </ng-container>
    </payee-form-field>

    <div class="claim-details-container">
      <ng-container
        *ngFor="let claim of dialogData?.claims; let claimIndex = index"
      >
        <div class="claim-details">
          <mat-checkbox
            class="claim-checkbox"
            [disabled]="claim.force_checked || claim.is_paid"
            [checked]="claim.force_checked && !claim.is_paid"
            [value]="claim.uuid"
            (change)="onCheckChange($event)"
          >
            <div class="flex vcenter">
              <div class="claim-number">{{ claimIndex + 1 }}. követelés:</div>
              <div class="claim-name">{{ claim.claim_type_label }}</div>
              <div class="claim-value">
                {{ claim.remaining_amount | number : '1.0' : 'hu' }}
                {{ dialogData?.payee_case?.currency }}
              </div>
            </div>
          </mat-checkbox>
        </div>
        <div
          class="claim-payment"
          *ngIf="paymentAmounts[claimIndex]?.amount"
        >
          -{{ paymentAmounts[claimIndex].amount | number : '1.0' : 'hu' }}
          {{ dialogData?.payee_case?.currency }}
        </div>

        <ng-container
          *ngFor="
            let optionalClaim of claim.optional_claims;
            let optionalClaimIndex = index
          "
        >
          <div
            class="optional-claim-details flex vcenter"
            [class.is-paid]="claim.is_paid"
          >
            <div class="optional-claim-name">
              {{ optionalClaim.claim_type_label }}
            </div>
            <div class="optional-claim-value">
              {{ optionalClaim.remaining_amount | number : '1.0' : 'hu' }}
              {{ dialogData?.payee_case?.currency }}
            </div>
          </div>
          <div
            class="claim-payment"
            *ngIf="
              paymentAmounts[claimIndex]?.optional_claims[optionalClaimIndex]
                ?.amount
            "
          >
            -{{
              paymentAmounts[claimIndex].optional_claims[optionalClaimIndex]
                .amount | number : '1.0' : 'hu'
            }}
            {{ dialogData?.payee_case?.currency }}
          </div>
        </ng-container>
      </ng-container>
    </div>

    <mat-error *ngIf="selectedClaims.hasError('required')">
      Válasszon ki legalább 1 követelést!
    </mat-error>
  </form>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="max"
      height="normal"
      [disabled]="manualPaymentForm.invalid"
      (click)="submit()"
    >
      <ng-container>Rögzítem a befizetést</ng-container>
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate>
    <button
      class="payee-button"
      payee-button
      [isBorderButton]="true"
      mat-dialog-close
      color="white"
      width="max"
      height="normal"
    >
      <ng-container>Mégsem</ng-container>
    </button>
  </ng-template>
</payee-popup>
