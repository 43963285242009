<div class="title-wrapper">
    <div class="stroke-wrapper">
        <div class="stroke"></div>
    </div>
    <div class="title">{{text}}</div>
    <div class="stroke-wrapper">
        <div class="stroke"></div>
    </div>
</div>

