import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { InitStepService } from 'src/app/aa-new-form/form-steps/init-step/services/init-step.service';
import { PwcInvoicesOfDebtorTableData, PwcService } from 'src/app/services/pwc/pwc.service';
import { PwcInvoiceFormService } from '../pwc-invoice-form.service';
import { PageEvent } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import moment from 'moment';

@Component({
  selector: 'app-pwc-invoice-dialog',
  templateUrl: './pwc-invoice-dialog.component.html',
  styleUrls: ['./pwc-invoice-dialog.component.scss']
})
export class PwcInvoiceDialogComponent implements OnInit {
  page: number = 0;
  pageSize: number = 10;
  length: number = Infinity;
  sort_by?: string = 'due_date_at';
  sort_direction?: string = 'asc';

  dataSource = new MatTableDataSource<PwcInvoicesOfDebtorTableData>();

  displayedColumns = [
    'select',
    'invoice_number',
    ...(environment.pwcInvoiceShowStatus ? ['status'] : []),
    'due_date_at',
    'remaining_amount',
    'can_create_payee_case',
  ] as const;

  get description(): string {
    return 'Kérjük, adja meg, hogy milyen követelései vannak felé: '
      + this.initStepService.debtorStepData.debtor_data.debtor.name;
  }

  get statusMap() { return this.pwcService.statusMap; }

  get isSaveDisabled(): boolean {
    return this.pwcInvoiceFormService.form.invalid || this.pwcInvoiceFormService.saveLoading;
  }

  constructor(
    private initStepService: InitStepService,
    public pwcInvoiceFormService: PwcInvoiceFormService,
    private pwcService: PwcService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getInvoices();
  }

  getCanCreatePayeeCaseMessage(invoice: PwcInvoicesOfDebtorTableData): string {
    return this.pwcService.getCanCreatePayeeCaseMessage(invoice.can_create_payee_case);
  }

  async saveData(): Promise<void> {
    await this.pwcInvoiceFormService.persistData();
  }

  isInvoiceSelected(invoice: PwcInvoicesOfDebtorTableData): boolean {
    return this.pwcInvoiceFormService.isInvoiceSelected(invoice);
  }

  toggleInvoice(invoice: PwcInvoicesOfDebtorTableData): void {
    this.pwcInvoiceFormService.toggleInvoice(invoice);
  }

  async paginateData(event: PageEvent): Promise<void> {
    this.page = event.pageIndex;
    this.pageSize = event.pageSize;

    await this.getInvoices();
  }

  async sortData(event: Sort): Promise<void> {
    if (!event.active || !event.direction) {
      this.sort_by = null;
      this.sort_direction = null;
    } else {
      this.sort_by = event.active;
      this.sort_direction = event.direction;
    }

    await this.getInvoices();
  }

  async getInvoices(): Promise<void> {
    try {
      const result = await this.pwcInvoiceFormService.getInvoices(
        this.page + 1,
        this.pageSize,
        this.sort_by,
        this.sort_direction,
      );

      this.length = result.pagination.total;
      this.dataSource.data = result.data;
    } catch (error) {
      console.error('Error while initializing dialog', error);
    }
  }

  onClose() {
    this.pwcInvoiceFormService.openExitDialog();
  }

  showDemandLetterWarning(invoice: PwcInvoicesOfDebtorTableData): boolean {
    return this.pwcService.showDemandLetterWarning(invoice.due_date_at);
  }

  getDemandLetterWarningContent(invoice: PwcInvoicesOfDebtorTableData): string {
    const demandLetterWarningDate = this.pwcService.getDemandLetterWarningDate(invoice.due_date_at).format('YYYY.MM.DD.');
    return `
      A Csődtv. 27. § (2) a) pont alapján ahhoz, hogy a Csődtv. szerinti joghatás kiváltására alkalmas legyen a fizetési felszólítás, minimum 20 napnak kell eltelnie a fizetési határidő lejártától.
      A 20 nap a következő napon jár le: ${demandLetterWarningDate} (A figyelmeztetéstől függetlenül az ügy megindítható.)
    `;
  }
}
