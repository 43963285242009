import {Component, Input, OnInit} from '@angular/core';
import {HelpersService} from '../../../../../../services/helpers/helpers.service';
import {ClaimFormService} from '../../../services/form-services/claim-form-service';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-claim-data-box',
  templateUrl: './claim-data-box.component.html',
  styleUrls: ['./claim-data-box.component.scss']
})
export class ClaimDataBoxComponent implements OnInit {
  @Input() selectedClaimIndex: number = 0;

  readonly date = new Date();

  get selectedClaimForm() { return this.claimFormService.getSelectedClaimFormGroup(this.selectedClaimIndex); }

  get isDeletable() {
    return /*this.formHelperService.isSzamlazzhu && */ this.claimFormService.claimFormArray.length !== 1;
  }

  constructor(
    public claimFormService: ClaimFormService,
    private helpersService: HelpersService,
  ) {
  }

  ngOnInit(): void {
    // To make the border of the input field red when opening the dialog
    if (this.claimFormService.areErrorMessagesShown) {
      this.claimFormService.setIsDirty(true);
    }
  }

  formatClaimAmount() {
    this.helpersService.formatNumbers(this.claimFormService.getClaimAmountFormControl(this.selectedClaimIndex));
  }

  onDelete() {
    this.claimFormService.deleteClaim(this.selectedClaimIndex);
  }

  readonly environment = environment;
}
