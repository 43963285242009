import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PwcService, UploadContactExcelResponse } from 'src/app/services/pwc/pwc.service';

@Component({
  selector: 'app-upload-contact-excel-dialog',
  templateUrl: './upload-contact-excel-dialog.component.html',
  styleUrls: ['./upload-contact-excel-dialog.component.scss']
})
export class UploadContactExcelDialogComponent {
  file?: File = null;
  loading: boolean = false;
  uploadResult?: UploadContactExcelResponse;

  constructor(
    private pwcService: PwcService,
    private dialogRef: MatDialogRef<UploadContactExcelDialogComponent>,
  ) { }

  dropped(event: FileList): void {
    if (event.length === 0) {
      return;
    }
    this.file = event.item(0);
  }

  async submit(): Promise<void> {
    if (!this.file || this.loading) {
      return;
    }

    try {
      this.loading = true;
      this.uploadResult = await this.pwcService.uploadContactExcel(this.file);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
