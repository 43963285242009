<payee-popup title="Válassza ki, mit szeretne indítani" [onCancel]="onCancel">
  <div class="container">
    <div
      class="box"
      [class.selected]="type === 'notice'"
      (click)="onSelectionChange('notice')"
    >
      <div class="soft-icon icon">
        <div class="icon-text">Soft</div>
      </div>
      <div class="top-margin-20">
        <payee-icon
          [name]="'pwc-soft'"
          width="auto"
          height="80px"
        ></payee-icon>
      </div>
      <div
        class="title top-margin-20"
        [ngClass]="{ 'selected-title': type === 'notice' }"
      >
        Fizetési felszólítás
      </div>
      <div
        class="description top-margin-15"
        [ngClass]="{ 'selected-description': type === 'notice' }"
      >
        Digitális felszólító üzenetek sorozata a hitelező nevében
      </div>
      <div
        class="label top-margin-15"
        [ngClass]="{ 'selected-label': type === 'notice' }"
      >
        <div
          class="label-text"
          [ngClass]="{ 'selected-label-text': type === 'notice' }"
        >
          Magas megnyitási arány
        </div>
      </div>
    </div>

    <!--medium-->
    <div
      class="box"
      [ngClass]="{ selected: type === 'demand_letter' }"
      (click)="onSelectionChange('demand_letter')"
    >
      <div class="medium-icon icon">
        <div class="icon-text">Medium</div>
      </div>
      <div class="top-margin-20">
        <payee-icon
          [name]="'pwc-demand-letter'"
          width="auto"
          height="80px"
        ></payee-icon>
      </div>
      <div
        class="title top-margin-20"
        [ngClass]="{ 'selected-title': type === 'demand_letter' }"
      >
        Ügyvédi levél
      </div>
      <div
        class="description top-margin-15"
        [ngClass]="{ 'selected-description': type === 'demand_letter' }"
      >
        Felszámolásban is használható tértivevényes ügyvédi felszólító levél
      </div>
      <div
        class="label top-margin-15"
        [ngClass]="{ 'selected-label': type === 'demand_letter' }"
      >
        <div
          class="label-text"
          [ngClass]="{ 'selected-label-text': type === 'demand_letter' }"
        >
          Ügyvéd által aláírva és postázva
        </div>
      </div>
    </div>

    <!--hard-->
    <div
      class="box"
      [class.selected]="type === 'hard_1'"
      (click)="onSelectionChange('hard_1')"
    >
      <div class="hard-icon icon">
        <div class="icon-text">Hard</div>
      </div>
      <div class="top-margin-20">
        <payee-icon
          [name]="'pwc-hard'"
          width="auto"
          height="80px"
        ></payee-icon>
      </div>
      <div
        class="title top-margin-20"
        [ngClass]="{ 'selected-title': type === 'hard_1' }"
      >
        Fizetési meghagyás
      </div>
      <div
        class="description top-margin-15"
        [ngClass]="{ 'selected-description': type === 'hard_1' }"
      >
        Fizetési meghagyás kibocsátása közjegyző által és annak végrehajtása
      </div>
      <div
        class="label top-margin-15"
        [ngClass]="{ 'selected-label': type === 'hard_1' }"
      >
        <div
          class="label-text"
          [ngClass]="{ 'selected-label-text': type === 'hard_1' }"
        >
          Az egyedüli hivatalos jogi eszköz
        </div>
      </div>
    </div>
  </div>
  <payee-info
    *ngIf="showDemandLetterWarning && type === 'demand_letter'"
    class="demand-letter-warning top-margin-15"
    color="warning-primary"
  >
    <div>
      A Csődtv. 27. § (2) a) pont alapján ahhoz, hogy a Csődtv. szerinti
      joghatás kiváltására alkalmas legyen a fizetési felszólítás, minimum 20
      napnak kell eltelnie a fizetési határidő lejártától.
    </div>
    <div>
      A 20 nap a következő napon jár le: {{ demandLetterWarningDate }}
      (A figyelmeztetéstől függetlenül az ügy megindítható.)
    </div>
  </payee-info>

  <ng-template #payeePopupSubmitTemplate>
    <button
      id="createCase"
      [disabled]="loading"
      width="wide"
      payee-button
      (click)="createCase()"
    >
      Ügy létrehozása
    </button>
  </ng-template>
</payee-popup>
