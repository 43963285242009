import { Component, OnInit } from '@angular/core';
import { PaymentStepService } from "./services/payment-step.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormErrorSnackbarComponent } from "../../../snackbars/form-error-snackbar/form-error-snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-step-step',
  templateUrl: './payment-step.component.html',
  styleUrls: ['./payment-step.component.scss']
})
export class PaymentStepComponent implements OnInit {
  loading = true;

  private caseUuid = '';

  constructor(
    public paymentStepService: PaymentStepService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackbar: MatSnackBar,
    private titleService: Title,
  ) {
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.caseUuid = params['uuid'];
      this.paymentStepService.setCaseUuid(this.caseUuid);

      try {
        await this.paymentStepService.setPaymentStepData();
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.snackbar.openFromComponent(FormErrorSnackbarComponent);
        await this.router.navigate([`/case/${this.caseUuid}/data-entry`]);
      }
    });

    this.titleService.setTitle('Fizetés – Real-time Debt Management Tool');
  }

  async onBackButtonClick() {
    await this.router.navigate([`/case/${this.caseUuid}/summary`]);
  }
}
